import React, {
  FC,
  JSX,
  useEffect,
  useState
} from "react";
import { Form, FormInstance, Select } from "antd";
import { ButtonCustom } from "../../../../../ui-kit/ButtonCustom/ButtonCustom";
import ZeroSearch from "../../../../../ClientsList/ZeroSearch/ZeroSearch";
import { ReactComponent as SearchIcon } from "../../../../../../assets/icons/search_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../../../reducers/mainReducer";
import {
  UserListingType,
  TypicalTaskDecisionType,
  DocumentTaskType,
  UsersListingFilterType,
} from "app/types";
import { getUsersListingData } from "../../../../../../actions/account.actions";
import { performersFilter } from "../../../../../../utils/performersFilter";
import { AppDispatch } from "../../../../../../store/store";
import css from "../TaskCardActionDrawer.module.css";
import dayjs from "dayjs";
import qs from "qs";

interface IChangeTaskImplementerProps {
  form: FormInstance,
  taskUuid: string;
  onFinish: (actionBody: TypicalTaskDecisionType) => void;
  onDrawerClose: () => void;
  isAutomaticProcessingActive: boolean;
}

const { Option } = Select;

const ChangeTaskImplementer: FC<IChangeTaskImplementerProps> = ({
  form,
  taskUuid,
  onFinish,
  onDrawerClose,
  isAutomaticProcessingActive,
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  
  const usersListing: UserListingType[] = useSelector((state: AppStateType) => state.account.usersListing);
  const documentTask: DocumentTaskType | null = useSelector((state: AppStateType) => state.tasks.documentTask);

  const [searchEmployeeValue, setSearchEmployeeValue] = useState<string>("");
  const [selectedResponsible, setSelectedResponsible] = useState<UserListingType | null>(null);
  const [selectedResponsibleRule, setSelectedResponsibleRule] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
  
  const { last_name, first_name, second_name } = selectedResponsible?.attributes ?? {};
  
  const userRoles = (): string[] => {
    const taskTypeLabel: string = documentTask?.fields?.task_type?.[0]?.label ?? "";
    
    switch (taskTypeLabel) {
      case "Обращение" :
        return ["accountant-role", "assistant-role", "contact-center-role"];
      case "Повышение тарифа" :
      case "Прекращение обслуживания" :
        return ["accountant-role", "supervisor-role"];
      default:
        return ["accountant-role", "assistant-role"];
    }
  };
  
  useEffect(() => {
    const params: UsersListingFilterType = {
      name: searchEmployeeValue?.length ? searchEmployeeValue : null,
      available_from: dayjs().format("YYYY-MM-DD"),
      available_to: dayjs().format("YYYY-MM-DD"),
      roles: userRoles(),
      page_size: 100,
    };
    
    const queryString: string = qs.stringify(params, { arrayFormat: "repeat" });
    
    dispatch(getUsersListingData(queryString));
  }, [searchEmployeeValue]);
  
  const placeholderInput = (text: string): JSX.Element => {
    return (
      <div className="flex items-center">
        <div>{text}</div>
        <SearchIcon className="ml-auto search-color" />
      </div>
    );
  };

  const excludedUserId: string | null | undefined = documentTask?.fields?.responsible_reference?.[0]?.uuid;
  const filteredAccountants: UserListingType[] = performersFilter(usersListing, documentTask, excludedUserId);

  const handleSelectChange = (value: string): void => {
    const selectUser: UserListingType[] = usersListing.filter((user: UserListingType): boolean => user.id === value);

    setSelectedResponsible(selectUser[0]);
    setSelectedResponsibleRule("one");
  };

  const clearImplementer = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setSelectedResponsible(null);
    setSelectedResponsibleRule(null);
    form.resetFields(["isApproved", "selectImplementer"] as any);
  };

  const clearSearchValue = (): void => {
    setSearchEmployeeValue("");
  };

  const changeImplementer = (): void => {
    const changeImplementerBody: TypicalTaskDecisionType = {
      task: taskUuid,
      task_decision_type: "change_responsible",
      new_responsible_user_uuid: selectedResponsible?.id,
      new_responsible_rule: selectedResponsibleRule,
    };
    
    if (isSubmitting) {
      onFinish(changeImplementerBody);
      setIsSubmitting(false);
    }
    
  };
  
  const renderResponsible = (option: UserListingType): JSX.Element => {
    const { last_name, first_name, second_name } = option?.attributes || {};
    
    return (
      <Option key={option.id} value={option?.id}>
        <div>
          {[last_name, first_name, second_name].filter(Boolean).join(" ")}
        </div>
      </Option>
    );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={changeImplementer}
    >
      <div>
        {/*TODO временно скрываем данный функционал*/}
        {/*<Form.Item name="isApproved" className="text-base font-normal leading-5 text-blue-800">*/}
        {/*  <Radio.Group onChange={handleChangeResponsible}>*/}
        {/*    <Space direction="vertical">*/}
        {/*      <Radio value="one" className={css.radioVariant}>Только для этой задачи</Radio>*/}
        {/*      <Radio value="all" className={css.radioVariant}>Для всех задач данного исполнителя</Radio>*/}
        {/*      <Radio value="task_type" className={css.radioVariant}>По конкретному типу задач</Radio>*/}
        {/*    </Space>*/}
        {/*  </Radio.Group>*/}
        {/*</Form.Item>*/}
        {selectedResponsible ? (
          <div>
            <div className="text-base font-medium leading-5 text-black-800">
              Задача будет назначена на сотрудника:
            </div>
            <div className="flex items-center mt-4 mb-4">
              <div className="mr-3">
                <div className="text-base font-normal leading-5 text-[#101928]">
                  {[last_name, first_name, second_name].filter(Boolean).join(" ")}
                </div>
              </div>
              <ButtonCustom
                className="px-1 py-0"
                type="link"
                text="Изменить"
                onClick={clearImplementer}
              />
            </div>
          </div>
        ) : (
          <>
            <div className={css.actionDrawerDescr}>
              Вы можете изменить исполнителя, закрепленного за задачей.
              Для этого воспользуйтесь поиском ниже, чтобы выбрать нужного сотрудника.
            </div>
            <Form.Item
              name="selectImplementer"
              label="Сотрудник"
              required
            >
              <Select
                id="selectImplementer"
                className="w-full items-center self-stretch"
                placeholder={placeholderInput("Найти сотрудника по ФИО")}
                showSearch
                allowClear
                size="large"
                suffixIcon={null}
                filterOption={false}
                onSelect={handleSelectChange}
                onSearch={setSearchEmployeeValue}
                onClear={clearSearchValue}
                searchValue={searchEmployeeValue}
                notFoundContent={<ZeroSearch dataFilter />}
              >
                {filteredAccountants?.map((option: UserListingType) => (
                  renderResponsible(option)
                ))}
              </Select>
            </Form.Item>
          </>
        )}
      </div>
      <Form.Item noStyle>
        <div className="mt-4 text-right">
          <ButtonCustom
            className="mr-2"
            size="large"
            type="default"
            ghost
            text="Отменить"
            onClick={onDrawerClose}
          />
          <ButtonCustom
            size="large"
            type="primary"
            text="Подтвердить"
            htmlType="submit"
            loading={isAutomaticProcessingActive}
            disabled={!selectedResponsible || isAutomaticProcessingActive}
          />
        </div>
      </Form.Item>
    </Form>
  );
};

export default ChangeTaskImplementer;
