import React, {
  Dispatch,
  FC,
  JSX,
  SetStateAction,
} from "react";
import { FormInstance } from "antd";
import { taskUpdateType } from "../../../../utils/catalog";
import FiltersComponent from "../FiltersComponent";

interface IHasUpdatesFiltersProps {
  form: FormInstance,
  isModalOpen: boolean,
  selectedUpdateTask: string[],
  setSelectedUpdateTask: Dispatch<SetStateAction<string[]>>,
}

const HasUpdatesFilters: FC<IHasUpdatesFiltersProps> = ({
  form,
  isModalOpen,
  selectedUpdateTask,
  setSelectedUpdateTask
}): JSX.Element => {
  return (
    <FiltersComponent
      key="hasUpdate"
      form={form}
      isModalOpen={isModalOpen}
      selectedItems={selectedUpdateTask}
      setSelectedItems={setSelectedUpdateTask}
      taskTypes={taskUpdateType}
      formFieldName="hasUpdate"
      headerText="По наличию обновлений"
      isChooseOne
    />
  );
};

export default HasUpdatesFilters;