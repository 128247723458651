import React, {
  FC,
  JSX,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import {
  Form,
  Radio,
  Select,
  Tooltip,
  Divider,
  FormInstance,
  RadioChangeEvent,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { getEmployeeDocumentData, getDocumentSchema } from "../../../../actions/document.actions";
import { AppStateType } from "../../../../reducers/mainReducer";
import { DocumentClientProfileType, UpdateDocumentFilesType } from "app/types";
import { getUnAgreedDocumentType } from "../../../../actions/clientProfile.actions";
import { isDocRequiredValuesFilled } from "../../../../utils/isDocRequriedValuesFiiled";
import { validateTriggersArr } from "../ClientProfileCardImageViewer";
import css from "./../ClientProfileCardImageViewer.module.css";
import { JWTPayload } from "jose";
import { roleResolver } from "../../../../utils/roleResolver";
import { onlyOneDirector } from "../../../../utils/onlyOneDirector";

interface IClientProfileCardImageViewerFormSolutionProps {
  decisionForm: FormInstance;
  closeForm: () => void;
  documentUuid: string;
  setCanSaveDoc: React.Dispatch<SetStateAction<boolean>>;
  onApproveChange: (e: RadioChangeEvent | string) => void;
  docApproveStatus: RadioChangeEvent | string;
  onFormValuesChange: () => void;
  setDocFilesLinks: React.Dispatch<SetStateAction<string[]>>;
  setTotalDocFiles: React.Dispatch<SetStateAction<number>>;
  isClientEmployee?: boolean;
  isValidationCompleted?: boolean;
  isNotAgreedDocs?: boolean;
}

const ClientProfileCardImageViewerFormDecision: FC<IClientProfileCardImageViewerFormSolutionProps> = ({
  decisionForm,
  closeForm,
  documentUuid,
  setCanSaveDoc,
  onApproveChange,
  docApproveStatus,
  onFormValuesChange,
  setDocFilesLinks,
  setTotalDocFiles,
  isClientEmployee,
  isValidationCompleted,
  isNotAgreedDocs
}) => {
  const [isDocApprovalDisabled, setDocApprovalDisabled] = useState<boolean>(false);
  const [isDocVerified, setDocVerified] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const documentClientProfile: DocumentClientProfileType | null = useSelector(
    (state: AppStateType) => state.clientProfile.documentClientProfile
  );

  const {
    decisionDoc,
    decisionDocSchema,
    passportCompanyDoc,
    passportDoc
  } = useSelector((state: AppStateType) => state.document);
  const {
    document_status,
    files,
    decline_reason,
  } = decisionDoc?.fields || {};
  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  useEffect(() => {
    isDocRequiredValuesFilled(decisionDocSchema, decisionForm, setDocApprovalDisabled);
  }, [decisionForm, decisionDocSchema, onFormValuesChange]);

  useEffect(() => {
    setDocVerified((!!docApproveStatus && isDocApprovalDisabled) || isContactCenter);
  }, [docApproveStatus, isDocApprovalDisabled]);

  useEffect(() => {
    const documentStatus: string = document_status ?? "";
    const documentFiles: string[] = files?.map(({ uuid }) => uuid) || [];

    onApproveChange(documentStatus);
    setDocFilesLinks(documentFiles);
    setTotalDocFiles(documentFiles?.length);
  }, [documentClientProfile, decisionDoc]);

  useEffect(() => {
    if (!isNotAgreedDocs && documentUuid) {
      dispatch(getDocumentSchema("founders-decision-schema"));
      dispatch(getEmployeeDocumentData("founders-decision-schema", documentUuid));
    }
  }, [isNotAgreedDocs, documentUuid]);

  useEffect(() => {
    if (docApproveStatus === "DECLINED") {
      const docMigrationDeclineReason: string =
        decline_reason || "Необходимо приложить верный документ (приложен не тот документ)";
      
      decisionForm.setFieldValue("declineReason", docMigrationDeclineReason);
    }

    onFormValuesChange();
  }, [docApproveStatus, decisionDoc, decisionForm]);

  useEffect(() => {
    const docUsnStatus: string = document_status ?? "";
    const docUsnDeclineReason: string = decline_reason ?? "";

    if (docUsnStatus) {
      decisionForm.setFieldValue("isApproved", docUsnStatus);
    }

    if (docUsnDeclineReason) {
      decisionForm.setFieldValue("declineReason", docUsnDeclineReason);
    }
  }, [decisionDoc, decisionForm]);

  const isContactCenter: boolean = roleResolver(decodedToken).isContactCenter;
  const isDecisionFormDisabled: boolean | undefined = isContactCenter || isClientEmployee || isValidationCompleted;

  const renderDirectorName = (label: string): JSX.Element | null => {
    const variantName: { [key: string]: "last_name" | "first_name" | "second_name" } = {
      "Фамилия": "last_name",
      "Имя": "first_name",
      "Отчество": "second_name"
    };

    const name: "last_name" | "first_name" | "second_name" = variantName[label];
    const passportData: string | undefined = passportCompanyDoc?.fields?.[name] ?? passportDoc?.fields?.[name];
    const fieldValue: string | undefined = onlyOneDirector(documentClientProfile)
      ? documentClientProfile?.fields?.passport_document?.[0]?.[name]
      : passportData;

    if (!fieldValue) {
      return null;
    }

    return (
      <li className="flex mb-2">
        <span className="document-field-viewer">{label}</span>
        <span className="text-sm">{fieldValue}</span>
      </li>
    );
  };
  
  const handleSaveForm = (): void => {
    setCanSaveDoc(true);
    dispatch(getUnAgreedDocumentType(true));
  };

  return (
    <div>
      <div className={`${css.formStyle} w-[340px]`}>
        <h2 className={`${css.modalText} mb-2`}>
          Решение учредителя по формированию
          <br />
          уставного капитала и назначения
          <br />
          руководителя
        </h2>
        {!isDecisionFormDisabled && (
          <p className="mb-4 gray-color w-full">
            После проверки правильности документа
            <br />
            подтвердите его согласованность.
          </p>
        )}
        <Divider className="mt-0 mb-4" />
        <h3 className={`${css.modalText} mb-4`}>Данные руководителя</h3>
        <div className={css.informationCEO}>
          <ul>
            {renderDirectorName("Фамилия")}
            {renderDirectorName("Имя")}
            {renderDirectorName("Отчество")}
          </ul>
        </div>
        <Form
          form={decisionForm}
          name="decision-form"
          onChange={onFormValuesChange}
          onClick={onFormValuesChange}
          validateTrigger={validateTriggersArr}
          layout="vertical"
        >
          {!isDecisionFormDisabled && (
            <>
              <Divider className="mt-3" />
              <div className={`mb-2 ${css.modalText}`}>Согласование</div>
              <p className="mb-5 gray-color w-fit">
                Подтвердите согласование документа. При
                <br />
                наличии ошибок или проблем с файлом
                <br />
                отклоните согласование, указав причину.
              </p>
              <div className="flex justify-between">
                <Tooltip
                  title={
                    isDocApprovalDisabled ? "Для согласования документа необходимо заполнить обязательные поля" : ""
                  }
                >
                  <Form.Item name="isApproved">
                    <Radio.Group
                      onChange={onApproveChange}
                      value={docApproveStatus}
                      disabled={isDocApprovalDisabled}
                      className="flex flex-col gap-2"
                    >
                      <Radio value="APPROVED">Согласовать</Radio>
                      <Radio value="DECLINED">Отклонить</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Tooltip>
              </div>
              <div>
                {docApproveStatus === "DECLINED" && (
                  <Form.Item
                    name="declineReason"
                    label="Причина"
                    className="w-fit"
                    rules={[
                      {
                        required: true,
                        message: ""
                      }
                    ]}
                  >
                    <Select
                      placeholder="Причина"
                      size="large"
                      style={{ width: "316px" }}
                      options={[
                        {
                          value: "Необходимо приложить верный документ (приложен не тот документ)",
                          label: "Необходимо приложить верный документ (приложен не тот документ)"
                        },
                        {
                          value: "Необходимо приложить полный документ (не все страницы)",
                          label: "Необходимо приложить полный документ (не все страницы)"
                        },
                        {
                          value: "Истек срок действия документа",
                          label: "Истек срок действия документа"
                        },
                        {
                          value: "Плохое качество документа, нечитаемый скан",
                          label: "Плохое качество документа, нечитаемый скан"
                        }
                      ]}
                    />
                  </Form.Item>
                )}
              </div>
            </>
          )}
        </Form>
      </div>
      <Divider className="mt-0 mb-5" />
      <div className="flex mx-6 my-4 gap-2">
        <ButtonCustom
          id="close"
          className={css.buttonBack}
          type="default"
          onClick={closeForm}
          text={isDecisionFormDisabled ? "Закрыть" : "Отменить"}
          size="large"
        />
        {!isDecisionFormDisabled && (
          <ButtonCustom
            id="save"
            key="submit"
            className={css.buttonOk}
            type="primary"
            onClick={handleSaveForm}
            text="Сохранить"
            size="large"
            disabled={isDocVerified}
          />
        )}
      </div>
    </div>
  );
};

export default ClientProfileCardImageViewerFormDecision;
