import React, {
  FC,
  JSX,
  Dispatch,
  useState,
  SetStateAction,
} from "react";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import css from "../ClientProfileCardNotAgreed.module.css";
import ClientProfileCardImageViewer from "../../ClientProfileCardImageViewer/ClientProfileCardImageViewer";
import {
  AbstractDocType,
  DocumentClientProfileType,
  StatusDataType,
  UpdateDocumentFieldType, 
  UpdateDocumentType,
} from "app/types";
import ClientProfileCardTransactionCheckModal
  from "../../ClientProfileCardTransactionCheckModal/ClientProfileCardTransactionCheckModal";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import {
  getCompanyDocumentData,
  getEmployeeDocumentData,
  updateCompanyDocumentData
} from "../../../../actions/document.actions";
import { AppDispatch } from "../../../../store/store";
import TagCustom from "../../../ui-kit/TagCustom/TagCustom";

interface INotAgreedItemProps {
  label: string;
  index: number;
  closeForm: () => void;
  saveFormData?: (docUuid: string, data: any) => void;
  saveCompanyFormData?: (docUuid: string, data: any) => void;
  documentType: string;
  documentUuid: string;
  showImageViewer: (docType: string, uuid: string) => void;
  isImageViewerOpen: boolean;
  unAgreedClause: StatusDataType;
  isClient: boolean;
  isValidationCompleted: boolean;
  isCompany?: boolean;
  isNotAgreedDocs: boolean;
  setDocumentUuid?: Dispatch<SetStateAction<string>>;
  setDocumentType?: Dispatch<SetStateAction<string>>;
  setCanUpdateNotNotAgreedDocs: Dispatch<SetStateAction<boolean>>;
}

const NotAgreedItem: FC<INotAgreedItemProps> = ({
  label,
  index,
  closeForm,
  saveFormData,
  saveCompanyFormData,
  documentType,
  documentUuid,
  showImageViewer,
  isImageViewerOpen,
  unAgreedClause,
  isClient,
  isValidationCompleted,
  isCompany,
  isNotAgreedDocs,
  setDocumentUuid,
  setDocumentType,
  setCanUpdateNotNotAgreedDocs,
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const [isStatementOpened, setStatementOpened] = useState<boolean>(false);

  const bankCompanyDoc: UpdateDocumentType | null = useSelector((state: AppStateType) => state.document.bankCompanyDoc);

  const documentClientProfile: DocumentClientProfileType | null =
    useSelector((state: AppStateType) => state.clientProfile.documentClientProfile);

  const confirmBankDoc = (docStatus: string): void => {
    documentClientProfile?.fields?.bank_document?.forEach((bankDocument: AbstractDocType): void => {
      if (bankCompanyDoc?.fields?.uuid) {
        const updatedBankDoc: UpdateDocumentFieldType = {
          ...bankCompanyDoc,
          uuid: bankCompanyDoc?.fields?.uuid,
          files: bankCompanyDoc?.fields?.files,
          decline_reason: bankCompanyDoc?.fields?.decline_reason,
          document_status: docStatus,
        };

        dispatch(updateCompanyDocumentData(
          "bank-schema",
          bankDocument.uuid ?? "",
          updatedBankDoc
        ));
      }
    });
    setCanUpdateNotNotAgreedDocs(true);
  };

  const renderDocumentsItems = (text: string, isCompany: boolean = false): string => {
    switch (text) {
      case "usn-schema":
        return "Подтверждение перехода на УСН";
      case "snils-schema":
        return isCompany ? "СНИЛС генерального директора" : "СНИЛС";
      case "passport-schema":
        return isCompany ? "Паспортные данные руководителя" : "Паспорт";
      case "ndfl-schema":
        return "Документ для вычета НДФЛ";
      case "migration-card-schema":
        return "Миграционная карта/Патент";
      case "inn-schema":
        return "ИНН";
      case "bank-schema":
        return isCompany ? "Выписка" : "Банковские реквизиты";
      case "contract-labor-schema":
        return "Договор";
      case "patent-schema":
        return "Патент";
      case "mdm_founders-decision-schema":
      case "founders-decision-schema":
        return "Решение учредителя по формированию уставного капитала и назначения руководителя";
      default:
        return "";
    }
  };

  const renderNotAgreed = (): JSX.Element => {
    return (
      <TagCustom color="warning" text="Не согласовано"/>
    );
  };

  const renderCause = (): JSX.Element => {
    return (
      <div className={`${css.cause} mb-3`}>
        <div className="inline-block mb-1 gray-color text-sm">
          Причина
        </div>
        <div className="text-base font-normal leading-5">
          {unAgreedClause?.status?.decline_reason}
        </div>
      </div>
    );
  };

  const openImageViewer = () => {
    if (isClient) {
      if (label === "bank-schema") {
        setStatementOpened(true);
      } else {
        dispatch(getEmployeeDocumentData(label, unAgreedClause?.status?.document_uuid));
        showImageViewer(label, unAgreedClause?.status?.document_uuid);
      }
    } else {
      dispatch(getCompanyDocumentData(label, unAgreedClause?.status?.document_uuid));
      showImageViewer(label, unAgreedClause?.status?.document_uuid);
    }
  };

  return (
    <>
      {unAgreedClause && (
        <div>
          <div className={`${css.organisationInfo} mb-2`}>
            <div className={isClient ? css.clientStyle : css.employeeStyle}>
              {renderDocumentsItems(label, isCompany)}
            </div>
            <div className={`${css.informationFile} mt-3`}>
              <span className="mb-2">
                <div className="flex items-center mb-4">
                  <ButtonCustom
                    id={`${label}-${isClient ? "client" : `employee-${index}`}`}
                    className="w-40 mr-2"
                    type="primary"
                    ghost
                    text="Проверить файлы"
                    onClick={openImageViewer}
                  />
                    {renderNotAgreed()}
                </div>
                  {unAgreedClause?.status?.decline_reason ? renderCause() : null}
              </span>
            </div>
          </div>
        </div>
      )}
      {isImageViewerOpen && (
        <ClientProfileCardImageViewer
          closeForm={closeForm}
          saveFormData={saveFormData}
          saveCompanyFormData={saveCompanyFormData}
          documentType={documentType}
          documentUuid={documentUuid}
          isImageViewerOpen={isImageViewerOpen}
          isCompany={isCompany}
          isNotAgreedDocs={isNotAgreedDocs}
          setDocumentUuid={setDocumentUuid}
          setDocumentType={setDocumentType}
          isValidationCompleted={isValidationCompleted}
        />
      )}
      {isStatementOpened && (
        <ClientProfileCardTransactionCheckModal
          docStatus={bankCompanyDoc?.fields?.document_status ?? ""}
          confirmBankDoc={confirmBankDoc}
          isStatementOpened={isStatementOpened}
          setStatementOpened={setStatementOpened}
          isValidationCompleted={isValidationCompleted}
        />
      )}
    </>
  );
};

export default NotAgreedItem;
