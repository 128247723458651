import React, { FC, JSX } from "react";
import {
  Form,
  FormInstance,
  Input,
  Select,
  Tooltip
} from "antd";
import { deviationReason } from "../../ClientCardItem/Tasks/contactingToClient";
import ZeroSearch from "../../../ZeroSearch/ZeroSearch";
import css from "../ClientCardActionDrawer.module.css";
import { DocumentClientType, TypicalRequestToClientType } from "app/types";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import { sendTerminateServiceRequestToClient } from "../../../../../actions/client.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { AppStateType } from "../../../../../reducers/mainReducer";
import * as jose from "jose";

interface ITerminateServiceProps {
  form: FormInstance;
  onDrawerClose: () => void;
}

const TerminateService: FC<ITerminateServiceProps> = ({
  form,
  onDrawerClose,
}): JSX.Element => {
  const { TextArea } = Input;
  const values = Form.useWatch([], form);
  
  const dispatch = useDispatch<AppDispatch>();

  const {
    terminateReason,
    textTerminate
  } = values || {};
  
  const documentClient: DocumentClientType | null = useSelector((state: AppStateType) => state.client.documentClient);
  const decodedToken: jose.JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const commentCount: number = textTerminate?.length || 0;

  const formFieldRender = (): JSX.Element => {
    return (
      <Form.Item name="terminateReason" className="mb-4">
        <Select
          id="terminateReason"
          placeholder="Выберите причину"
          options={deviationReason}
          listHeight={196}
          showSearch
          allowClear
          size="large"
          notFoundContent={<ZeroSearch dataFilter />}
        />
      </Form.Item>
    );
  };

  const textAreaRender = (): JSX.Element => {
    return (
      <div>
        <Form.Item name="textTerminate" noStyle>
          <TextArea
            id="textTerminate"
            rows={7}
            maxLength={2000}
            className="text-base font-normal leading-5 text-gray-900"
            placeholder="Ваше сообщение"
          />
        </Form.Item>
        <div className="mb-4 text-xs font-normal leading-100 text-gray-400 text-right">
          {commentCount}/2000
        </div>
      </div>
    );
  };
  
  const renderDefaultButton = (isDisabled: boolean): JSX.Element => (
    <ButtonCustom
      id="buttonOk"
      key="ok"
      size="large"
      text="Подтвердить"
      type="primary"
      htmlType="submit"
      className={css.buttonOk}
      disabled={isDisabled}
    />
  );
  
  const renderConfirmButton: JSX.Element = (
    !terminateReason ? (
      <Tooltip
        title="Для формирования заявки необходимо выбрать причину"
        placement="topRight"
        trigger="hover"
        overlayStyle={{width: "270px"}}
        overlayInnerStyle={{textAlign: "center"}}
      >
        <span>
          {renderDefaultButton(!terminateReason)}
        </span>
      </Tooltip>
    ) : (
      renderDefaultButton(!terminateReason)
    )
  );
  
  const onClientActionFinish = (): void => {
    const clientData: string | undefined = documentClient?.fields?.uuid;
    const authorTask: string | null = decodedToken?.sub ? decodedToken?.sub : null;
    
    //TODO поменять данные, как только будет точный код категории
    const terminateServiceRequest: TypicalRequestToClientType = {
      client: clientData,
      category_code: 999,
      task_reason: terminateReason ?? "",
      task_content: textTerminate ?? "",
      task_author: authorTask,
      files: [],
    };
    
    dispatch(sendTerminateServiceRequestToClient(terminateServiceRequest));
    onDrawerClose();
  };

  //TODO как будет АПИ логику дописать (список причин моковый)
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onClientActionFinish}
    >
      <div className={css.terminateText}>
        Для заявки на прекращение обслуживания необходимо указать причину, ссылаясь на оферту.
      </div>
      {formFieldRender()}
      <div className={css.terminateText}>
        Вы также можете добавить служебный комментарий. Этот комментарий не будет виден клиенту.
      </div>
      {textAreaRender()}
      <Form.Item className="mt-2 text-right">
        <ButtonCustom
          className="mr-2"
          size="large"
          type="default"
          ghost
          text="Отменить"
          onClick={onDrawerClose}
        />
        {renderConfirmButton}
      </Form.Item>
    </Form>
  );
};

export default TerminateService;