import React, {
  FC,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import {
  Form,
  Radio,
  Select,
  Tooltip,
  Divider,
  FormInstance,
  RadioChangeEvent,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { getEmployeeDocumentData, getDocumentSchema } from "../../../../actions/document.actions";
import { AppStateType } from "../../../../reducers/mainReducer";
import { DocumentClientProfileType, UpdateDocumentFilesType } from "app/types";
import { getUnAgreedDocumentType } from "../../../../actions/clientProfile.actions";
import { isDocRequiredValuesFilled } from "../../../../utils/isDocRequriedValuesFiiled";
import { validateTriggersArr } from "../ClientProfileCardImageViewer";
import css from "./../ClientProfileCardImageViewer.module.css";
import { JWTPayload } from "jose";
import { roleResolver } from "../../../../utils/roleResolver";

interface IClientProfileCardImageViewerFormPatentProps {
  docType: string;
  migrationForm: FormInstance;
  closeForm: () => void;
  documentUuid: string;
  setCanSaveDoc: React.Dispatch<SetStateAction<boolean>>;
  onApproveChange: (e: RadioChangeEvent | string) => void;
  docApproveStatus: RadioChangeEvent | string;
  onFormValuesChange: () => void;
  setDocFilesLinks: React.Dispatch<SetStateAction<string[]>>;
  setTotalDocFiles: React.Dispatch<SetStateAction<number>>;
  isClientEmployee?: boolean;
  isValidationCompleted?: boolean;
  isNotAgreedDocs?: boolean;
}

const ClientProfileCardImageViewerFormPatent: FC<IClientProfileCardImageViewerFormPatentProps> = ({
  docType,
  migrationForm,
  closeForm,
  documentUuid,
  setCanSaveDoc,
  onApproveChange,
  docApproveStatus,
  onFormValuesChange,
  setDocFilesLinks,
  setTotalDocFiles,
  isClientEmployee,
  isValidationCompleted,
  isNotAgreedDocs,
}) => {
  const [isDocApprovalDisabled, setDocApprovalDisabled] = useState<boolean>(false);
  const [isDocVerified, setDocVerified] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const documentClientProfile: DocumentClientProfileType | null = useSelector(
    (state: AppStateType) => state.clientProfile.documentClientProfile
  );

  const {
    migrationDoc,
    migrationDocSchema
  } = useSelector((state: AppStateType) => state.document);

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  useEffect(() => {
    setDocVerified((!!docApproveStatus && isDocApprovalDisabled) || isContactCenter);
  }, [docApproveStatus, isDocApprovalDisabled]);

  useEffect(() => {
    isDocRequiredValuesFilled(migrationDocSchema, migrationForm, setDocApprovalDisabled);

    onFormValuesChange();
  }, [migrationForm, migrationDocSchema, onFormValuesChange]);

  useEffect(() => {
    const documentStatus: string = migrationDoc?.fields?.document_status ?? "";
    const documentFiles: string[] =
      migrationDoc?.fields?.files?.map(({ uuid }) => uuid) || [];

    onApproveChange(documentStatus);
    setDocFilesLinks(documentFiles);
    setTotalDocFiles(documentFiles?.length);
  }, [documentClientProfile, migrationDoc]);

  useEffect(() => {
    if (!isNotAgreedDocs && documentUuid) {
      dispatch(getDocumentSchema(docType));
      dispatch(getEmployeeDocumentData(docType, documentUuid));
    }
  }, [dispatch, documentUuid, docType, isNotAgreedDocs]);

  useEffect(() => {
    if (docApproveStatus === "DECLINED") {
      const docMigrationDeclineReason: string = migrationDoc?.fields?.decline_reason ?? "";

      migrationForm.setFieldValue(
        "declineReason" as any,
        docMigrationDeclineReason || "Необходимо приложить верный документ (приложен не тот документ)"
      );

      onFormValuesChange();
    }
  }, [docApproveStatus, migrationDoc, migrationForm]);

  useEffect(() => {
    const docMigrationStatus: string = migrationDoc?.fields?.document_status ?? "";
    const docMigrationDeclineReason: string = migrationDoc?.fields?.decline_reason ?? "";

    if (docMigrationStatus) {
      migrationForm.setFieldValue("isApproved", docMigrationStatus);
    }

    if (docMigrationDeclineReason) {
      migrationForm.setFieldValue("declineReason", docMigrationDeclineReason);
    }
  }, [migrationDoc, migrationForm]);

  const isContactCenter: boolean = roleResolver(decodedToken).isContactCenter;
  const isMigrationFormDisabled: boolean | undefined = isContactCenter || isClientEmployee || isValidationCompleted;

  const handleSave = (): void => {
    setCanSaveDoc(true);
    dispatch(getUnAgreedDocumentType(true));
  };

  return (
    <div>
      <div className={`${css.formStyle} w-[340px]`}>
        <h2 className={`${css.modalText} mb-2`}>{docType === "patent-schema" ? "Патент" : "Миграционная карта"}</h2>
        {!isMigrationFormDisabled && (
          <p className="mb-4 gray-color w-full">
            После проверки правильности документа
            <br />
            подтвердите его согласованность.
          </p>
        )}
        <Form
          form={migrationForm}
          name="migration-form"
          onChange={onFormValuesChange}
          onClick={onFormValuesChange}
          validateTrigger={validateTriggersArr}
          layout="vertical"
        >
          {!isMigrationFormDisabled && (
            <>
              <Divider className="mt-3" />
              <div className={`mb-2 ${css.modalText}`}>Согласование</div>
              <p className="mb-5 gray-color w-fit">
                Подтвердите согласование документа. При
                <br />
                наличии ошибок или проблем с файлом
                <br />
                отклоните согласование, указав причину.
              </p>
              <div className="flex justify-between">
                <Tooltip
                  title={
                    isDocApprovalDisabled ? "Для согласования документа необходимо заполнить обязательные поля" : ""
                  }
                >
                  <Form.Item name="isApproved">
                    <Radio.Group
                      onChange={onApproveChange}
                      value={docApproveStatus}
                      disabled={isDocApprovalDisabled}
                      className="flex flex-col gap-2"
                    >
                      <Radio value="APPROVED">Согласовать</Radio>
                      <Radio value="DECLINED">Отклонить</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Tooltip>
              </div>
              <div>
                {docApproveStatus === "DECLINED" && (
                  <Form.Item
                    name="declineReason"
                    label="Причина"
                    rules={[
                      {
                        required: true,
                        message: ""
                      }
                    ]}
                  >
                    <Select
                      placeholder="Причина"
                      size="large"
                      options={[
                        {
                          value: "Необходимо приложить верный документ (приложен не тот документ)",
                          label: "Необходимо приложить верный документ (приложен не тот документ)"
                        },
                        {
                          value: "Необходимо приложить полный документ (не все страницы)",
                          label: "Необходимо приложить полный документ (не все страницы)"
                        },
                        {
                          value: "Истек срок действия документа",
                          label: "Истек срок действия документа"
                        },
                        {
                          value: "Плохое качество документа, нечитаемый скан",
                          label: "Плохое качество документа, нечитаемый скан"
                        }
                      ]}
                    />
                  </Form.Item>
                )}
              </div>
            </>
          )}
        </Form>
      </div>
      <Divider className="mt-0 mb-5" />
      <div className="flex mx-6 my-4 gap-2">
        <ButtonCustom
          id="close"
          className={css.buttonBack}
          type="default"
          onClick={closeForm}
          text={isMigrationFormDisabled ? "Закрыть" : "Отменить"}
          size="large"
        />
        {!isMigrationFormDisabled && (
          <ButtonCustom
            id="save"
            key="submit"
            className={css.buttonOk}
            type="primary"
            onClick={handleSave}
            text="Сохранить"
            size="large"
            disabled={isDocVerified}
          />
        )}
      </div>
    </div>
  );
};

export default ClientProfileCardImageViewerFormPatent;
