import React, { FC, JSX, SetStateAction, Dispatch } from "react";
import css from "../AboutClient.module.css";
import ListItem from "../../../../../ui-kit/RenderListItem/ListItem";
import { ButtonCustom } from "../../../../../ui-kit/ButtonCustom/ButtonCustom";
import { Card } from "antd";
import { reformatName } from "../../../../../../utils/reformatName";
import { roleResolver } from "../../../../../../utils/roleResolver";
import { JWTPayload } from "jose";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../../reducers/mainReducer";
import CardLabel from "../../../../../../utils/CardLabel/CardLabel";

interface IResponsibleInfoProps {
  setChangeResponsibleModalOpen: Dispatch<SetStateAction<boolean>>;
  setTemporaryResponsible: Dispatch<SetStateAction<boolean>>;
}

const ResponsibleInfo: FC<IResponsibleInfoProps> = ({
  setChangeResponsibleModalOpen,
  setTemporaryResponsible
}): JSX.Element => {
  const {
    documentClient,
    schemaClient
  } = useSelector((state: AppStateType) => state.client);

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const isAdmin: boolean = roleResolver(decodedToken).isAdmin;
  const isTechSupport: boolean = roleResolver(decodedToken).isTechSupport;
  const hasAccess: boolean = isAdmin || isTechSupport;
  const hasTemporaryResponsible: boolean = !!documentClient?.fields?.temporary_responsible_reference?.length;
  
  const clientSchema = {
    client_tariff: schemaClient?.fields?.find(i => i.name === "client_tariff"),
    secondary_okved: schemaClient?.fields?.find(i => i.name === "secondary_okved"),
    responsible_reference: schemaClient?.fields?.find(i => i.name === "responsible_reference"),
  };

  const responsibleValue: string = reformatName(
    documentClient?.fields?.responsible_reference?.[0]?.label
    ?? ""
  );
  const temporaryResponsibleValue: string = reformatName(
    documentClient?.fields?.temporary_responsible_reference?.[0]?.label
    ?? ""
  );

  const openModal = ():void => {
    setChangeResponsibleModalOpen(true);
  };
  
  const openTemporaryResponsibleModal = ():void => {
    setChangeResponsibleModalOpen(true);
    setTemporaryResponsible(true);
  };

  return (
    <Card className={css.card}>
      <CardLabel title="Ответственные" />
      <ul>
        <ListItem
          value={responsibleValue}
          label="Основной"
        />
        {temporaryResponsibleValue && (
          <ListItem
            value={temporaryResponsibleValue}
            label="Замещающий"
          />
        )}
        {hasAccess && (
          <div className="flex mt-3">
            <li className="flex items-center mr-2">
              <ButtonCustom
                type="primary"
                text="Изменить ответственного"
                ghost
                onClick={openModal}
              />
            </li>
            {hasTemporaryResponsible && (
              <li className="flex items-center">
                <ButtonCustom
                  type="primary"
                  text="Изменить замещающего"
                  ghost
                  onClick={openTemporaryResponsibleModal}
                />
              </li>
            )}
          </div>
        )}
      </ul>
    </Card>
  );
};

export default ResponsibleInfo;