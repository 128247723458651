import React, { FC, JSX } from "react";
import {
  GetFile1CType,
  UploadedFilesPropsType,
} from "app/types";
import LoadingCustom from "../../../../../../../ui-kit/LoadingCustom/LoadingCustom";
import { ButtonCustom } from "../../../../../../../ui-kit/ButtonCustom/ButtonCustom";
import { ReactComponent as ErrorIcon } from "../../../../../../../../assets/icons/warning_circle_icon.svg";
import { ReactComponent as SuccessIcon } from "../../../../../../../../assets/icons/warning_blue_icon.svg";
import css from "./AutomaticProcessingBlocks.module.css";

interface IDownloadOrActionBlockProps {
  isMarkupTask: boolean;
  isFileFetched: boolean;
  taskFile1CData: GetFile1CType | null;
  isFilesUploaded: boolean;
  isDownloadError: boolean;
  isAllFieldsFilled: boolean;
  isParsingSuccessful: boolean;
  parsingResultStatus: number;
  isDownloadSuccessful: boolean;
  file1CDownloadStatus: number;
  markupTaskFileFrom1C: UploadedFilesPropsType[] | null;
  markupTaskStatusForm1C: string | null;
  downloadFileAutomatically: () => void;
  isActionDisabled: boolean;
}

const DownloadOrActionBlock: FC<IDownloadOrActionBlockProps> = ({
  isMarkupTask,
  isFileFetched,
  taskFile1CData,
  isFilesUploaded,
  isDownloadError,
  isAllFieldsFilled,
  parsingResultStatus,
  isParsingSuccessful,
  isDownloadSuccessful,
  file1CDownloadStatus,
  markupTaskFileFrom1C,
  markupTaskStatusForm1C,
  downloadFileAutomatically,
  isActionDisabled,
}): JSX.Element | null => {
  const isParsingResultStatusValid: boolean = Boolean(parsingResultStatus) && !isParsingSuccessful;

  const isRepeatedFetch: boolean = isMarkupTask && (
    !!markupTaskStatusForm1C
    || !!file1CDownloadStatus
    || isParsingResultStatusValid
  );

  const processingButtonText: string = isRepeatedFetch ? "Обработать повторно" : "Обработать";

  const renderDownloadFilesBlock = (): JSX.Element | null => {
    const isAppealTaskSuccess: boolean = isDownloadSuccessful && !!taskFile1CData?.files?.length;
    const isMarkupTaskSuccess: boolean = isParsingSuccessful && markupTaskFileFrom1C === null;

    return isMarkupTaskSuccess || isAppealTaskSuccess ? renderSuccessfulRequestBlock() : renderErrorRequestBlock();
  };

  const renderSuccessfulRequestBlock = (): JSX.Element => (
    <>
      <div className={css.uploadingResult}>
        <SuccessIcon className="mr-2" />
        <div>Запрос обработан успешно. Пожалуйста, подтвердите обработку задачи</div>
      </div>
      {isMarkupTask && <div className="mt-5">{renderProcessingButton()}</div>}
    </>
  );

  const renderErrorRequestBlock = (): JSX.Element => (
    <>
      <div className={css.uploadingResult}>
        <div className="mr-2"><ErrorIcon /></div>
        <div>Ошибка загрузки документов из 1С. Пожалуйста, обработайте задачу вручную</div>
      </div>
      {isMarkupTask && <div className="mt-5">{renderProcessingButton()}</div>}
    </>
  );

  const renderActionBlock = (): JSX.Element | null => (isFileFetched ? renderProcessingButton() : renderFilesLoading());

  const renderProcessingButton = (): JSX.Element => (
    <ButtonCustom
      className={css.processingButton}
      type="primary"
      text={processingButtonText}
      htmlType="button"
      disabled={(!isMarkupTask && !isAllFieldsFilled) || isActionDisabled}
      onClick={downloadFileAutomatically}
    />
  );

  const renderFilesLoading = (): JSX.Element | null =>
    !isFilesUploaded ? (
      <div className={css.uploadingResult}>
        <div className="mr-2"><LoadingCustom fontSize={18} /></div>
        <div>Пожалуйста, подождите, мы загружаем документы из 1С</div>
      </div>
    ) : null;

  const renderDownloadOrActionBlock = (): JSX.Element | null => isDownloadError
    ? renderDownloadFilesBlock()
    : renderActionBlock();

  return renderDownloadOrActionBlock();
};

export default DownloadOrActionBlock;
