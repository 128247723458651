import React, {
  FC,
  JSX,
  useState,
} from "react";
import { TypicalTaskDecisionType } from "app/types";
import { topicOfAppeal } from "../../../taskActions";
import { ButtonCustom } from "../../../../../ui-kit/ButtonCustom/ButtonCustom";
import { Form, Select, FormInstance } from "antd";

interface IChooseAppealTopicProps {
  form: FormInstance,
  values: any,
  taskUuid: string;
  onFinish: (actionBody: TypicalTaskDecisionType, isReassignTask?: boolean) => void;
  onDrawerClose: () => void;
  isFormHasValue: boolean,
  isAutomaticProcessingActive: boolean,
}

const ChooseAppealTopic: FC<IChooseAppealTopicProps> = ({
  form,
  values,
  taskUuid,
  onFinish,
  onDrawerClose,
  isFormHasValue,
  isAutomaticProcessingActive,
}): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
  
  const appealTopicBody: TypicalTaskDecisionType = {
    task: taskUuid,
    task_decision_type: "change_task_topic",
    topic: values?.appealTopicName,
  };

  const onAppealTopicFinish = (): void => {
    if (isSubmitting) {
      onFinish(appealTopicBody);
      setIsSubmitting(false);
    }
  };

  return (
    <Form
      className="m-0"
      name="appealTopic"
      layout="vertical"
      form={form}
      onFinish={onAppealTopicFinish}
    >
      <Form.Item
        className="mb-4"
        name="appealTopicName"
        label="Тема обращения"
        required
      >
        <Select
          id="appealTopicName"
          size="large"
          allowClear
          options={topicOfAppeal}
          placeholder="Выберите тему обращения"
        />
      </Form.Item>
      <Form.Item className="text-right">
        <ButtonCustom
          className="mr-2"
          size="large"
          type="default"
          ghost
          text="Отменить"
          onClick={onDrawerClose}
        />
        <ButtonCustom
          size="large"
          type="primary"
          text="Подтвердить"
          htmlType="submit"
          loading={isAutomaticProcessingActive}
          disabled={!isFormHasValue || isAutomaticProcessingActive}
        />
      </Form.Item>
    </Form>
  );
};

export default ChooseAppealTopic;
