import React, { JSX, FC, Dispatch, SetStateAction } from "react";
import css from "./ConfirmModal.module.css";
import { Modal } from "antd";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";

interface IConfirmModalProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  closeForm: () => void;
}

const ConfirmModal: FC<IConfirmModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  closeForm
}): JSX.Element => {
  const modalTitle: JSX.Element = (
    <div className={css.modalTitle}>
      Подтверждение действия
    </div>
  );
  
  const handleCancel = (): void => {
    setIsModalOpen(false);
  };
  
  const handleConfirm = (): void => {
    setIsModalOpen(false);
    closeForm();
  };
  
  const modalFooter: JSX.Element = (
    <div key="modalBotton" className="mt-5">
      <ButtonCustom
        className="mr-[8px]"
        ghost
        key="cancel"
        size="large"
        type="default"
        text="Отменить"
        onClick={handleCancel}
      />
      <ButtonCustom
        key="ok"
        size="large"
        text="Подтвердить"
        type="primary"
        onClick={handleConfirm}
      />
    </div>
  );
  
  return (
    <Modal
      title={modalTitle}
      open={isModalOpen}
      onCancel={handleCancel}
      centered
      footer={modalFooter}
    >
      <div className={css.modalText}>
        Вы уверены, что хотите отменить проверку документа?
        Все введенные вами данные будут утеряны.
      </div>
    </Modal>
  );
};

export default ConfirmModal;