import React, {
  FC,
  JSX,
  Dispatch,
  SetStateAction,
} from "react";
import { ITaskActionsProps } from "../taskActions";
import { ConfigProvider, Select } from "antd";
import { UpOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import * as jose from "jose";
import { filterTaskAction } from "./utils/filterTaskAction";
import { AbstractDocType } from "app/types";
import { roleResolver } from "../../../../utils/roleResolver";

interface ITaskCardActionBtnProps {
  isSelectActive: boolean;
  isActionBtnDisabled: boolean;
  setDrawerTaskAction: Dispatch<SetStateAction<string>>;
  setConfirmTaskAction: Dispatch<SetStateAction<string>>;
  isConfirmationActions: (taskAction: string) => boolean;
  isCompleteTaskActionDisabled: boolean;
}

interface IJWTPayloadProps extends jose.JWTPayload {
  resource_access?: {
    bc: {
      roles: string[];
    }
  }
}

const TaskCardActionBtn: FC<ITaskCardActionBtnProps> = ({
  isSelectActive,
  isActionBtnDisabled,
  setDrawerTaskAction,
  setConfirmTaskAction,
  isConfirmationActions,
  isCompleteTaskActionDisabled,
}): JSX.Element => {
  const decodedToken: IJWTPayloadProps | null = useSelector((state: AppStateType) => state.account.decodedToken);
  const { documentTask, actionHistory } = useSelector((state: AppStateType) => state.tasks);
  
  const isAdmin: boolean = roleResolver(decodedToken).isAdmin;
  const taskAuthor: AbstractDocType[] | undefined = documentTask?.fields?.task_author;
  const isAppealTask: boolean = documentTask?.fields?.task_type?.[0]?.label === "Обращение";
  const hasAuthorAndAppealTask: boolean = !!taskAuthor && isAppealTask;
  
  const customSelectStyle = {
    components: {
      Select: {
        fontSizeLG: 15,
        selectorBg: "#3438E0",
        colorIcon: "white",
        controlOutlineWidth: 0,
        colorTextPlaceholder: isActionBtnDisabled ? "rgba(0, 0, 0, 0.25)" : "white",
        colorBgContainerDisabled: "#67768C26",
      },
    }
  };

  const selectActionName: JSX.Element = <span className="text-white">Действия по задаче</span>;
  const selectActionIconColor: string = isActionBtnDisabled ? "rgba(0, 0, 0, 0.25)" : "text-white";

  const updatedTaskActions: ITaskActionsProps[] | undefined =
    filterTaskAction(decodedToken, documentTask, actionHistory)
      ?.filter((action: ITaskActionsProps): boolean => {
        const isReassignTaskAction: boolean = action.value === "Переназначить задачу";
        const isChangePerformerAction: boolean = action.value === "Смена исполнителя";
        const hasReassignTask: boolean = hasAuthorAndAppealTask && isReassignTaskAction;
        const hasChangePerformer: boolean = hasAuthorAndAppealTask && !isAdmin && isChangePerformerAction;
        
        return !(hasReassignTask || hasChangePerformer);
      })
      ?.map((action: ITaskActionsProps) => {
      if (action.value === "closeTask") {
        action.disabled = isCompleteTaskActionDisabled;
      }
  
      return action;
    });

  const onSelectAction = (action: JSX.Element): void => {
    if (!isConfirmationActions(String(action))) {
      setDrawerTaskAction(String(action));
      setConfirmTaskAction("");
    } else {
      setDrawerTaskAction("");
      setConfirmTaskAction(String(action));
    }
  };

  return (
    <div className="flex items-center">
      <ConfigProvider theme={customSelectStyle}>
        <Select
          value={selectActionName}
          size="large"
          options={updatedTaskActions}
          listHeight={300}
          placeholder="Действия по задаче"
          suffixIcon={
            isSelectActive
             ? <UpOutlined className={selectActionIconColor} />
             : <DownOutlined className={selectActionIconColor} />
          }
          dropdownStyle={{ width: "265px" }}
          onSelect={onSelectAction}
          disabled={isActionBtnDisabled}
        />
      </ConfigProvider>
    </div>
  );
};

export default TaskCardActionBtn;
