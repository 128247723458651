import React, {
  Dispatch,
  FC,
  JSX,
  useEffect,
  SetStateAction
} from "react";
import { Checkbox, Form, FormInstance } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import TaskFilterForm from "../../../utils/TaskFiltersForm/TaskFilterForm";
import { CatalogTaskStatusType } from "../../../utils/catalog";

interface IFiltersComponentProps {
  form: FormInstance;
  isModalOpen: boolean;
  selectedItems: string[];
  setSelectedItems: Dispatch<SetStateAction<string[]>>;
  taskTypes: CatalogTaskStatusType[];
  formFieldName: string;
  headerText: string;
  isChooseOne?: boolean;
}

const FiltersComponent: FC<IFiltersComponentProps> = ({
  form,
  isModalOpen,
  selectedItems,
  setSelectedItems,
  taskTypes,
  formFieldName,
  headerText,
  isChooseOne = false
}): JSX.Element => {
  useEffect(() => {
    form.setFieldValue(formFieldName as any, selectedItems);
  }, [selectedItems]);
  
  const onFilterChange = (e: CheckboxChangeEvent, item: string): void => {
    const updatedItems: string[] = e.target.checked
      ? [...selectedItems, item]
      : selectedItems.filter((filter: string): boolean => filter !== item);
    const updatedOneItem: string[] = e.target.checked ? [item] : [];
    
    setSelectedItems(isChooseOne ? updatedOneItem : updatedItems);
  };
  
  const isFilterChecked = (item: string): boolean => {
    return selectedItems.includes(item);
  };
  
  const renderFilters = (): JSX.Element => (
    <div className="table-filters mt-2">
      {taskTypes.map(({ status, label }) => (
        <Checkbox
          key={status}
          className="table-checkbox"
          onChange={(e: CheckboxChangeEvent) => onFilterChange(e, status)}
          checked={isFilterChecked(status)}
        >
          {label}
        </Checkbox>
      ))}
    </div>
  );
  
  return (
    <Form form={form}>
      <Form.Item name={formFieldName} noStyle>
        <TaskFilterForm
          headerText={headerText}
          renderFilter={renderFilters()}
          isModalOpen={isModalOpen}
        />
      </Form.Item>
    </Form>
  );
};

export default FiltersComponent;