import React, { FC, JSX } from "react";
import css from "../AboutClient.module.css";
import ListItem from "../../../../../ui-kit/RenderListItem/ListItem";
import { getConsultationsNumber } from "../../../../../../utils/clientConsultations";
import { Card } from "antd";
import dayjs from "dayjs";
import { DocumentSchemaLabelMapType } from "app/types";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../../reducers/mainReducer";
import { documentSchemaLabelsMapper } from "../../../../../../utils/documentSchemaLabelsMapper";
import CardLabel from "../../../../../../utils/CardLabel/CardLabel";

interface ITariffInfoProps {
}

const TariffInfo: FC<ITariffInfoProps> = (): JSX.Element => {
  const {
    clientTariffData,
    schemaClient
  } = useSelector((state: AppStateType) => state.client);
  const clientLabel: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(schemaClient);

  const serviceLifeMonths: string = clientTariffData?.service_life_months
    ? `${clientTariffData?.service_life_months} месяцев`
    : "-";

  const startedAt: string = clientTariffData?.started_at
    ? `c ${dayjs(clientTariffData?.started_at)?.format("DD.MM.YYYY")}`
    : "-";

  const accountRecoveryMonths: string = clientTariffData?.account_recovery_months
    ? `${clientTariffData?.account_recovery_months} месяцев`
    : "-";

  const numberOfConsultations: string | number = clientTariffData?.number_of_consultations
    ? getConsultationsNumber(clientTariffData)
    : "-";

  return (
    <Card className={css.card}>
      <CardLabel title={clientLabel?.["client_tariff"]?.label ?? "Тариф"} />
      <div className={css.cardInfo}>
        <ul>
          <ListItem
            value={serviceLifeMonths}
            label={clientLabel?.["service_expired"]?.label}
          />
          <ListItem
            value={clientTariffData?.finished_at ?? "-"}
            label="Дата окончания обслуживания"
            duration={clientTariffData?.finished_at}
            type="expiration"
          />
          <ListItem
            value={clientTariffData?.online_desk_qty ?? "-"}
            label={clientLabel?.["online_desk_qty"]?.label}
          />
          <ListItem
            value={clientTariffData?.agency_contracts_qty ?? "-"}
            label={clientLabel?.["agency_agreements_qty"]?.label}
          />
          <ListItem
            value={numberOfConsultations}
            label={clientLabel?.["number_of_consultations"]?.label}
          />
          <ListItem
            value={`${startedAt}, ${accountRecoveryMonths}`}
            label={clientLabel?.["accounting_restore_months"]?.label}
          />
          <ListItem
            value={clientTariffData?.number_of_employees ?? "-"}
            label={clientLabel?.["number_of_employees"]?.label}
          />
        </ul>
      </div>
    </Card>
  );
};

export default TariffInfo;
