import { ITaskActionsProps, taskActions } from "../../taskActions";
import { DocumentTaskType, ActionHistoryType } from "app/types";
import { roleResolver } from "../../../../../utils/roleResolver";
import * as jose from "jose";

interface IJWTPayloadProps extends jose.JWTPayload {
  resource_access?: {
    bc: {
      roles: string[];
    }
  }
}

enum TaskCode {
  writeOff = 403,
  salaryCalculation = 204,
  advanceCalculation = 203,
}

const filterActionsByValues = (actions: ITaskActionsProps[], valuesToExclude: string[]):ITaskActionsProps[] =>
  actions.filter((action: ITaskActionsProps) => !valuesToExclude.includes(action.value));

export const filterTaskAction = (
  decodedToken: IJWTPayloadProps | null,
  documentTask: DocumentTaskType | null,
  actionHistory: ActionHistoryType | null,
): ITaskActionsProps[] => {
  const { task_type, client, category_code } = documentTask?.fields ?? {};
  const currentTaskType: string | undefined = task_type?.[0]?.label;
  const isTopicChanged: boolean = actionHistory?.results?.[0]?.fields?.task_decision_type === "change_task_topic";
  const isDraftClient: boolean = !!client?.[0]?.status_client
    && client?.[0]?.status_client === "DRAFT";
  
  const isKNAPTask: boolean = [
    TaskCode.writeOff,
    TaskCode.salaryCalculation,
    TaskCode.advanceCalculation
  ].includes(category_code as number);
  
  const excludedValues: string[] = [
    "Обработать задачу автоматически",
    "Обработать задачу вручную",
    "Переназначить задачу"
  ];
  
  const filterAction: ITaskActionsProps[] =
    documentTask?.fields?.task_type[0]?.label === "Исходящая задача"
      ? taskActions.filter((action: ITaskActionsProps): boolean =>
        !excludedValues.includes(action.value))
      : taskActions;
  
  let filteredTaskActions: ITaskActionsProps[] =
    isTopicChanged ? filterAction.filter((action: ITaskActionsProps): boolean =>
      action.value !== "Выбрать тему обращения") : filterAction;
  
  if (currentTaskType !== "Обращение") {
    filteredTaskActions =
      filterActionsByValues(filteredTaskActions, ["Переназначить задачу", "Выбрать тему обращения"]);
  }
  
  if (currentTaskType === "Наличные операции") {
    filteredTaskActions = filterActionsByValues(
      filteredTaskActions,
      ["Отправить на уточнение", "Обработать задачу вручную", "Обработать задачу автоматически"]
    );
  }
  
  if (isKNAPTask) {
    filteredTaskActions = filterActionsByValues(filteredTaskActions, ["Распознать документы в КНАП"]);
  }
  
  if (isDraftClient) {
    filteredTaskActions = filterActionsByValues(filteredTaskActions, ["Переназначить задачу"]);
  }

  //Завершить задачу можно только в Наличных операциях и Исходящих задачах
  if (currentTaskType !== "Наличные операции" && currentTaskType !== "Исходящая задача") {
    filteredTaskActions = filteredTaskActions.filter(({ value }): boolean => value !== "closeTask");
  }

  // Действие "Переназначить задачу" доступно только в "Обращениях"
  if (currentTaskType !== "Обращение") {
    filteredTaskActions = filterActionsByValues(filteredTaskActions, ["Переназначить задачу"]);
  }

  // Скрываем действия для задачи типа "Разметка операций"
  if (currentTaskType === "Разметка операций") {
    filteredTaskActions = filterActionsByValues(
      filteredTaskActions,
      ["Выбрать тему обращения", "Обработать задачу вручную"]
    );
  }
  
  if (decodedToken) {
    const roles: { [key: string]: boolean } = roleResolver(decodedToken);
    const {
      isAdmin,
      isAccountant,
      isAssistant,
      isContactCenter
    } = roles || {};

    if (currentTaskType == "Декларация") {
      filteredTaskActions = filterActionsByValues(
        filteredTaskActions,
        ["Распознать документы в КНАП",
          "Выбрать тему обращения",
          "Отправить на уточнение",
          "Обработать задачу вручную",
          "Переназначить задачу",
          isAdmin ? "" : "Сменить исполнителя"]
      );
    }
    
    if (isAccountant && !isAdmin) {
      filteredTaskActions =
        filterActionsByValues(filteredTaskActions, ["Сменить исполнителя", "Выбрать тему обращения"]);
    } else if (isAssistant && !isAdmin) {
        filteredTaskActions = filterActionsByValues(
          filteredTaskActions,
          ["Сменить исполнителя", "Выбрать тему обращения", "Распознать документы в КНАП"]
        );
    } else if (isContactCenter && !isAdmin) {
        filteredTaskActions = filterActionsByValues(
          filteredTaskActions,
          ["Сменить исполнителя", "Обработать задачу автоматически", "Распознать документы в КНАП"]
        );
    } else if (isDraftClient && isAdmin) {
      filteredTaskActions = filterActionsByValues(
        filteredTaskActions,
        ["Обработать задачу автоматически", "Распознать документы в КНАП"]
      );
    }
    
    return filteredTaskActions;
  } else {
      return filteredTaskActions;
  }
};
