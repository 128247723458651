import React, { FC, JSX } from "react";
import { JWTPayload } from "jose";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../../reducers/mainReducer";
import { roleResolver } from "../../../../../../utils/roleResolver";
import css from "../AboutClient.module.css";
import { ButtonCustom } from "../../../../../ui-kit/ButtonCustom/ButtonCustom";
import { Card } from "antd";
import CardLabel from "../../../../../../utils/CardLabel/CardLabel";
import { documentSchemaLabelsMapper } from "../../../../../../utils/documentSchemaLabelsMapper";
import { DocumentSchemaLabelMapType } from "app/types";

interface IWageRegulationsInfoProps {
  showModal: (text: string) => void;
  showAction: boolean;
}

const WageRegulationsInfo: FC<IWageRegulationsInfoProps> = ({
  showModal,
  showAction
}): JSX.Element => {

  const {
    documentClient,
    schemaLabour,
    clientLabourRegulation
  } = useSelector((state: AppStateType) => state.client);

  const labourRegulationLabel: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(schemaLabour);
  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);
  const isAdmin: boolean = roleResolver(decodedToken).isAdmin;
  const isTechSupport: boolean = roleResolver(decodedToken).isTechSupport;
  const isResponsibleAccountant: boolean =
    decodedToken?.sub === documentClient?.fields?.responsible_reference?.[0]?.uuid ||
    decodedToken?.sub === documentClient?.fields?.temporary_responsible_reference?.[0]?.uuid;
  const hasAccess: boolean = isResponsibleAccountant || isAdmin || isTechSupport;

  return (
    <Card className={`${css.card} ${!showAction ? "mr-3" : ""}`}>
      <CardLabel title="Положение об оплате труда" />
      <ul className="flex flex-col">
        <li className="flex items-center mb-[15px]">
          <span className={css.cardItemLabel}>
            {labourRegulationLabel?.["salary_accrual_date"]?.label}
          </span>
          <span className={css.cardItemValue}>
            {clientLabourRegulation
              ? `${clientLabourRegulation?.fields?.salary_accrual_date} числа каждого месяца`
              : "-"
            }
          </span>
        </li>
        <li className="flex items-center mb-5">
          <span className={css.cardItemLabel}>
            {labourRegulationLabel?.["advance_accrual_date"]?.label}
          </span>
          <span className={css.cardItemValue}>
            {clientLabourRegulation
              ? `${clientLabourRegulation?.fields?.advance_accrual_date} числа каждого месяца`
              : "-"
            }
          </span>
        </li>
        {hasAccess && (
          <li className="flex items-center">
            <ButtonCustom
              type="primary"
              text="Редактировать"
              ghost
              onClick={() => showModal("Положение об оплате труда")}
            />
          </li>
        )}
      </ul>
    </Card>
  );
};

export default WageRegulationsInfo;
