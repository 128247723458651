import React, { 
  FC, 
  SetStateAction, 
  useEffect, 
  useState 
} from "react";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import {
  Form,
  Radio,
  Input,
  Select,
  Tooltip,
  Divider,
  FormInstance,
  RadioChangeEvent,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { getEmployeeDocumentData, getDocumentSchema } from "../../../../actions/document.actions";
import { AppStateType } from "../../../../reducers/mainReducer";
import { DocumentClientProfileType } from "app/types";
import { getUnAgreedDocumentType } from "../../../../actions/clientProfile.actions";
import { isDocRequiredValuesFilled } from "../../../../utils/isDocRequriedValuesFiiled";
import { isFormItemRequiredRule } from "../../../../utils/isFormItemRequiredRule";
import { validateTriggersArr } from "../ClientProfileCardImageViewer";
import css from "./../ClientProfileCardImageViewer.module.css";
import { JWTPayload } from "jose";
import { roleResolver } from "../../../../utils/roleResolver";

interface IClientProfileCardImageViewerFormInnProps {
  innForm: FormInstance;
  closeForm: () => void;
  documentUuid: string;
  setCanSaveDoc: React.Dispatch<SetStateAction<boolean>>;
  onApproveChange: (e: RadioChangeEvent | string) => void;
  docApproveStatus: RadioChangeEvent | string;
  onFormValuesChange: () => void;
  setDocFilesLinks: React.Dispatch<SetStateAction<string[]>>;
  setTotalDocFiles: React.Dispatch<SetStateAction<number>>;
  isClientEmployee?: boolean;
  isValidationCompleted?: boolean;
  isNotAgreedDocs?: boolean;
}

const ClientProfileCardImageViewerFormInn: FC<IClientProfileCardImageViewerFormInnProps> = ({
  innForm,
  closeForm,
  documentUuid,
  setCanSaveDoc,
  onApproveChange,
  docApproveStatus,
  onFormValuesChange,
  setDocFilesLinks,
  setTotalDocFiles,
  isClientEmployee,
  isValidationCompleted,
  isNotAgreedDocs
}) => {
  const [isSubmitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(false);
  const [isDocApprovalDisabled, setDocApprovalDisabled] = useState<boolean>(false);
  const [isDocVerified, setDocVerified] = useState<boolean>(false);
  const [innBlur, setInnBlur] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const documentClientProfile: DocumentClientProfileType | null = useSelector(
    (state: AppStateType) => state.clientProfile.documentClientProfile
  );

  const { 
    innDoc, 
    innDocSchema 
  } = useSelector((state: AppStateType) => state.document);

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  useEffect(() => {
    setDocVerified((!!docApproveStatus && isDocApprovalDisabled) || isContactCenter);
  }, [docApproveStatus, isDocApprovalDisabled]);

  useEffect(() => {
    isDocRequiredValuesFilled(innDocSchema, innForm, setDocApprovalDisabled);
  }, [innForm, innDocSchema, onFormValuesChange]);

  useEffect(() => {
    const documentStatus: string = innDoc?.fields?.document_status ?? "";
    const documentFiles: string[] = innDoc?.fields?.files?.map(({ uuid }) => uuid) || [];

    onApproveChange(documentStatus);
    setDocFilesLinks(documentFiles);
    setTotalDocFiles(documentFiles?.length);
  }, [documentClientProfile, innDoc]);

  useEffect(() => {
    if (docApproveStatus === "DECLINED") {
      const docMigrationDeclineReason: string = innDoc?.fields?.decline_reason ?? "";

      innForm.setFieldValue(
        "declineReason" as any,
        docMigrationDeclineReason || "Необходимо приложить верный документ (приложен не тот документ)"
      );
    }

    onFormValuesChange();
  }, [docApproveStatus, innDoc, innForm]);

  useEffect(() => {
    if (!isNotAgreedDocs && documentUuid) {
      dispatch(getDocumentSchema("inn-schema"));
      dispatch(getEmployeeDocumentData("inn-schema", documentUuid));
    }
  }, [documentUuid, isNotAgreedDocs]);

  useEffect(() => {
    const docInnNumber: string = innDoc?.fields?.inn ?? "";
    const docInnStatus: string = innDoc?.fields?.document_status ?? "";
    const docInnDeclineReason: string = innDoc?.fields?.decline_reason ?? "";

    if (docInnStatus) {
      handleInnChange(docInnNumber);
      onApproveChange(docInnStatus);

      innForm.setFieldValue("isApproved", docInnStatus);
    }

    if (docInnDeclineReason) {
      innForm.setFieldValue("declineReason", docInnDeclineReason);
    }
  }, [innDoc, innForm]);

  const handleInnChange = (event: React.ChangeEvent<HTMLInputElement> | string): void => {
    const inputValue: string = typeof event !== "string" ? event.target.value.replace(/\D/g, "") : event;
    const matches: RegExpMatchArray | null = inputValue.match(/(\d{1,3})(\d{0,3})(\d{0,3})(\d{0,3})/);
    const formattedInn: string = matches ? matches?.slice(1).filter(Boolean).join(" ") : "";

    setSubmitBtnDisabled(!(formattedInn?.length === 15 || formattedInn?.length === 0));

    innForm.setFieldValue("inn", formattedInn);
  };

  const isContactCenter: boolean = roleResolver(decodedToken).isContactCenter;
  const isInnFormDisabled: boolean | undefined = isContactCenter || isClientEmployee || isValidationCompleted;

  const handleValidateStatus: "error" | "" =
    innForm.getFieldValue("inn" as any)?.replace(/\s+/g, "")?.length === 12 ? "" : "error";

  const handleHelp: null | string = innForm.getFieldValue("inn" as any) ? null : "Только цифры";

  const correctStrategy = (value: string): number => value?.replace(/\s+/g, "")?.length ?? 0;

  const handleBlur = (): void => {
    setInnBlur(true);
  };
  
  const handleSave = (): void => {
    setCanSaveDoc(true);
    dispatch(getUnAgreedDocumentType(true));
  };

  return (
    <div>
      <div className={`${css.formStyle} w-[340px]`}>
        <h2 className={`${css.modalText} mb-2`}>ИНН</h2>
        {!isInnFormDisabled && (
          <p className="mb-4 gray-color w-full">
            После проверки правильности документа
            <br />
            подтвердите его согласованность
            <br />и заполните соответствующие данные.
          </p>
        )}
        <Form
          form={innForm}
          name="inn-form"
          onChange={onFormValuesChange}
          onClick={onFormValuesChange}
          validateTrigger={validateTriggersArr}
          layout="vertical"
        >
          <Form.Item
            name="inn"
            label="ИНН"
            rules={[
              {
                min: 15,
                message: "Значение должно быть 12 цифр."
              },
              {
                max: 16,
                message: "Значение должно быть 12 цифр."
              },
              {
                required: isFormItemRequiredRule("inn", innDocSchema),
                message: ""
              }
            ]}
            validateTrigger={validateTriggersArr}
            validateStatus={innBlur ? handleValidateStatus : ""}
            help={innBlur ? handleHelp : null}
          >
            <Input
              size="large"
              showCount
              placeholder="000 000 000 000"
              onChange={handleInnChange}
              readOnly={isInnFormDisabled}
              count={{
                max: 12,
                strategy: (value: string): number => correctStrategy(value)
              }}
              onBlur={handleBlur}
            />
          </Form.Item>
          {!isInnFormDisabled && (
            <>
              <Divider className="mt-3" />
              <div className={`mb-2 ${css.modalText}`}>Согласование</div>
              <p className="mb-5 gray-color w-fit">
                Подтвердите согласование документа. При
                <br />
                наличии ошибок или проблем с файлом
                <br />
                отклоните согласование, указав причину.
              </p>
              <div className="flex justify-between">
                <Tooltip
                  title={
                    isDocApprovalDisabled ? "Для согласования документа необходимо заполнить обязательные поля" : ""
                  }
                >
                  <Form.Item name="isApproved">
                    <Radio.Group
                      onChange={onApproveChange}
                      value={docApproveStatus}
                      disabled={isDocApprovalDisabled}
                      className="flex flex-col gap-2"
                    >
                      <Radio value="APPROVED">Согласовать</Radio>
                      <Radio value="DECLINED">Отклонить</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Tooltip>
              </div>
              <div>
                {docApproveStatus === "DECLINED" && (
                  <Form.Item
                    name="declineReason"
                    label="Причина"
                    rules={[
                      {
                        required: true,
                        message: ""
                      }
                    ]}
                  >
                    <Select
                      placeholder="Причина"
                      size="large"
                      options={[
                        {
                          value: "Необходимо приложить верный документ (приложен не тот документ)",
                          label: "Необходимо приложить верный документ (приложен не тот документ)"
                        },
                        {
                          value: "Плохое качество документа, нечитаемый скан",
                          label: "Плохое качество документа, нечитаемый скан"
                        }
                      ]}
                    />
                  </Form.Item>
                )}
              </div>
            </>
          )}
        </Form>
      </div>
      <Divider className="mt-0 mb-5" />
      <div className="flex mx-6 my-4 gap-2">
        <ButtonCustom
          id="close"
          className={css.buttonBack}
          type="default"
          onClick={closeForm}
          text={isInnFormDisabled ? "Закрыть" : "Отменить"}
          size="large"
        />
        {!isInnFormDisabled && (
          <ButtonCustom
            id="save"
            key="submit"
            className={css.buttonOk}
            type="primary"
            onClick={handleSave}
            text="Сохранить"
            size="large"
            disabled={isDocVerified || isSubmitBtnDisabled}
          />
        )}
      </div>
    </div>
  );
};

export default ClientProfileCardImageViewerFormInn;
