import React, {
  FC,
  JSX,
  Dispatch,
  useEffect,
  SetStateAction,
  useState,
} from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import ContractCard from "./Contract/ContractCard";
import DocumentsCard from "./Documents/DocumentsCard";
import BankDetailsCard from "./BankDetails/BankDetailsCard";
import { ArrowLeftOutlined } from "@ant-design/icons";
import css from "./EmployeeCard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import {
  fetchDocumentEmployeeProfile,
  fetchSchemaEmployeeProfile
} from "../../../../actions/employeeProfile.actions";
import { AppStateType } from "../../../../reducers/mainReducer";
import { DocumentEmployeeProfileType, DocumentTaskType, UpdateDocumentFieldType } from "app/types";
import ClientProfileCardImageViewer from "../../ClientProfileCardImageViewer/ClientProfileCardImageViewer";
import LoadingCustom from "../../../ui-kit/LoadingCustom/LoadingCustom";
import {
  getDocumentSchema,
  getEmployeeDocumentData,
  updateEmployeeDocumentData
} from "../../../../actions/document.actions";

export interface IEmployeeCardProps {
  setEmployeeCard: Dispatch<SetStateAction<boolean>>;
  setCanUpdateNotNotAgreedDocs?: Dispatch<SetStateAction<boolean>>;
  variant: string;
}

export const renderEmployeeItem = (label: string | undefined, text: string | undefined | number): JSX.Element => (
  <li className="textBlock">
    <span className="flex-none gray-color field-label">
      {label}
    </span>
    <span className="flex-grow text-sm">
      {text}
    </span>
  </li>
);

const EmployeeCard: FC<IEmployeeCardProps> = ({
  setEmployeeCard,
  setCanUpdateNotNotAgreedDocs,
  variant
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate: NavigateFunction = useNavigate();
  const { state } = useLocation();

  const employee = state?.employee;

  useEffect((): void => {
    dispatch(fetchSchemaEmployeeProfile());
    dispatch(fetchDocumentEmployeeProfile(employee?.uuid));
  }, []);

  const documentTask: DocumentTaskType | null = useSelector((state: AppStateType) => state.tasks.documentTask);

  const [documentType, setDocumentType] = useState<string>("");
  const [documentUuid, setDocumentUuid] = useState<string>("");
  const [isImageViewerOpen, setImageViewerOpen] = useState<boolean>(false);

  const closeForm = (): void => {
    setImageViewerOpen(false);
    setCanUpdateNotNotAgreedDocs?.(true);
  };

  const showImageViewer = (docType: string, uuid: string): void => {
    setDocumentUuid(uuid);
    setDocumentType(docType);
    setImageViewerOpen(true);
  };

  const saveFormData = (docUuid: string, data: UpdateDocumentFieldType | null): void => {
    dispatch(updateEmployeeDocumentData(documentType, docUuid, data));

    setImageViewerOpen(false);
    setCanUpdateNotNotAgreedDocs?.(true);
  };

  const isValidationCompleted: boolean = documentTask?.fields?.status_task === "FIN_TRUE"
    || documentTask?.fields?.status_task === "1C_SYNC"
    || documentTask?.fields?.status_task === "FIN_FALSE"
    || documentTask?.fields?.status_task === "1C_SUCCESS"
    || documentTask?.fields?.status_task === "PAUSE";

  const documentEmployeeProfile: DocumentEmployeeProfileType | null =
    useSelector((state: AppStateType) => state.employeeProfile.documentEmployeeProfile);

  const navigateToTaskQuestionnaire = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    navigate(-1);
    setEmployeeCard(false);
  };

  //логика персональных данных сотрудника
  const [passportUuid, setPassportUuid] = useState<string>("");
  const [snilsUuid, setSnilsUuid] = useState<string>("");
  const [innUuid, setInnUuid] = useState<string>("");

  useEffect(() => {
    dispatch(getDocumentSchema("inn-schema"));
    dispatch(getDocumentSchema("snils-schema"));
    dispatch(getDocumentSchema("passport-schema"));
  }, []);

  useEffect(() => {
    const innDocUuid: string = documentEmployeeProfile?.fields?.inn_document?.length
      ? documentEmployeeProfile?.fields?.inn_document?.[0]?.uuid
      : "";
    const snilsDocUuid: string = documentEmployeeProfile?.fields?.snils_document?.length
      ? documentEmployeeProfile?.fields?.snils_document?.[0]?.uuid
      : "";
    const passportDocUuid: string = documentEmployeeProfile?.fields?.passport_document?.length
      ? documentEmployeeProfile?.fields?.passport_document?.[0]?.uuid
      : "";

    if (innDocUuid) {
      dispatch(getEmployeeDocumentData("inn-schema", innDocUuid));
      setInnUuid(innDocUuid);
    }

    if (snilsDocUuid) {
      dispatch(getEmployeeDocumentData("snils-schema", snilsDocUuid));
      setSnilsUuid(snilsDocUuid);
    }

    if (passportDocUuid) {
      dispatch(getEmployeeDocumentData("passport-schema", passportDocUuid));
      setPassportUuid(passportDocUuid);
    }
  }, [documentEmployeeProfile?.fields?.inn_document, documentEmployeeProfile?.fields?.passport_document,
    documentEmployeeProfile?.fields?.snils_document]);

  //логика контракта сотрудника
  const [contractUuid, setContractUuid] = useState<string>("");
  const [contractDocType, setContractDocType] = useState<string>("");
  const [hasContractDoc, setHasContractDoc] = useState<boolean>(false);

  useEffect(() => {
    const contractCivilDocUuid: string = documentEmployeeProfile?.fields?.contract_civil?.[0]?.uuid ?? "";
    const contractLaborDocUuid: string = documentEmployeeProfile?.fields?.contract_labor?.[0]?.uuid ?? "";

    if (contractLaborDocUuid) {
      dispatch(getEmployeeDocumentData("contract-labor-schema", contractLaborDocUuid));
      dispatch(getDocumentSchema("contract-labor-schema"));

      setHasContractDoc(true);
      setContractUuid(contractLaborDocUuid);
      setContractDocType("contract-labor-schema");
    }

    if (contractCivilDocUuid) {
      dispatch(getEmployeeDocumentData("contract-civil-schema", contractCivilDocUuid));
      dispatch(getDocumentSchema("contract-civil-schema"));

      setHasContractDoc(true);
      setContractUuid(contractCivilDocUuid);
      setContractDocType("contract-civil-schema");
    }
  }, [documentEmployeeProfile?.fields?.contract_civil, documentEmployeeProfile?.fields?.contract_labor]);

  //логика документов сотрудника
  const [docUuid, setDocUuid] = useState<string>("");
  const [docType, setDocType] = useState<string>("");
  const [hasMigrationOrPatentDoc, setHasMigrationOrPatentDoc] = useState<boolean>(false);

  useEffect(() => {
    const migrationDocUuid: string = documentEmployeeProfile?.fields?.migration_card?.length
      ? documentEmployeeProfile?.fields?.migration_card?.[0]?.uuid
      : "";

    const patentDocUuid: string = documentEmployeeProfile?.fields?.patent?.length
      ? documentEmployeeProfile?.fields?.patent?.[0]?.uuid
      : "";

    if (migrationDocUuid) {
      dispatch(getEmployeeDocumentData("migration-card-schema", migrationDocUuid));

      setDocUuid(migrationDocUuid);
      setDocType("migration-card-schema");
      setHasMigrationOrPatentDoc(true);
    }

    if (patentDocUuid) {
      dispatch(getEmployeeDocumentData("patent-schema", patentDocUuid));

      setDocUuid(patentDocUuid);
      setDocType("patent-schema");
      setHasMigrationOrPatentDoc(true);
    }
  }, [documentEmployeeProfile?.fields?.migration_card, documentEmployeeProfile?.fields?.patent]);

  const [incomeDocUuid, setIncomeDocUuid] = useState<string>("");

  useEffect(() => {
    const incomeUuid: string = documentEmployeeProfile?.fields?.income_tax_deduction?.length
      ? documentEmployeeProfile?.fields?.income_tax_deduction?.[0]?.uuid
      : "";

    if (incomeUuid) {
      dispatch(getEmployeeDocumentData("ndfl-schema", incomeUuid));
      setIncomeDocUuid(incomeUuid);
    }
  }, [documentEmployeeProfile?.fields?.income_tax_deduction]);

  //логика данных банка сотрудника
  const [bankDocUuid, setBankDocUuid] = useState<string>("");

  useEffect(() => {
    const bankDocUuid: string = documentEmployeeProfile?.fields?.bank_document?.length
      ? documentEmployeeProfile?.fields?.bank_document?.[0]?.uuid
      : "";

    if (bankDocUuid) {
      dispatch(getEmployeeDocumentData("bank-schema", bankDocUuid));
      dispatch(getDocumentSchema("bank-schema"));
      setBankDocUuid(bankDocUuid);
    }
  }, [documentEmployeeProfile?.fields?.bank_document]);

  //логика прокрутки лоудера
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    {/*TODO отключать лоудер только после того как получили ответы от всех запросов на получение документов*/}

    const timer: NodeJS.Timeout = setTimeout(() => setLoader(true), 500);
    return () => clearTimeout(timer);
  }, []);

  const validationVariant: boolean = variant === "validation";

  return (
    <div className={validationVariant ? css.validationContainer : ""}>
      {loader ? (
        <>
          <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
            <div className="mb-2">
              <ButtonCustom
                id="toEmployeeListing"
                className={css.buttonReturn}
                type="link"
                text="К списку сотрудников"
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => navigateToTaskQuestionnaire(e)}
              >
                <ArrowLeftOutlined />
              </ButtonCustom>
            </div>
            <h1 className={`${css.employeeName} mb-2`}>
              Сотрудник {documentEmployeeProfile?.fields?.full_name}
            </h1>
            <span className="flex-none gray-color text-sm">
              Проверьте данные и документы сотрудника
            </span>
          </div>
          <PersonalInformation
            documentUuid={documentUuid}
            showImageViewer={showImageViewer}
            passportUuid={passportUuid}
            snilsUuid={snilsUuid}
            innUuid={innUuid}
          />
          <ContractCard
            documentUuid={documentUuid}
            showImageViewer={showImageViewer}
            contractUuid={contractUuid}
            contractDocType={contractDocType}
            hasContractDoc={hasContractDoc}
          />
          <DocumentsCard
            documentUuid={documentUuid}
            showImageViewer={showImageViewer}
            docUuid={docUuid}
            docType={docType}
            hasMigrationOrPatentDoc={hasMigrationOrPatentDoc}
            incomeDocUuid={incomeDocUuid}
          />
          <BankDetailsCard
            documentUuid={documentUuid}
            showImageViewer={showImageViewer}
            bankDocUuid={bankDocUuid}
          />
          {!validationVariant ? (
            <div className={`${css.container} p-7 mb-3 bg-white border-bottom-gray text-end`}>
              <ButtonCustom
                id="toEmployeeListing"
                className={`${css.buttonBack} mr-4`}
                key="cancel"
                size="large"
                type="default"
                text="К списку сотрудников"
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => navigateToTaskQuestionnaire(e)}
              />
              <ButtonCustom
                id="endVerification"
                type="primary"
                text="Завершить проверку сотрудника"
                size="large"
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => navigateToTaskQuestionnaire(e)}
              />
            </div>
          ) : (
            <div className={`${css.container} p-5 bg-white border-bottom-gray text-end`}>
              <ButtonCustom
                id="toEmployeeListing"
                className={css.buttonBack}
                type="default"
                size="large"
                text="К списку сотрудников"
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => navigateToTaskQuestionnaire(e)}
              />
            </div>
          )}
        </>
      ) : (
        <div className={`flex items-center justify-center ${css.container} h-[70vh] bg-white border-bottom-gray`}>
          <LoadingCustom className="w-full" />
        </div>
      )}
      {isImageViewerOpen && (
        <ClientProfileCardImageViewer
          closeForm={closeForm}
          saveFormData={saveFormData}
          documentType={documentType}
          documentUuid={documentUuid}
          isImageViewerOpen={isImageViewerOpen}
          isEmployee
          isValidationCompleted={isValidationCompleted}
          setDocumentUuid={setDocumentUuid}
          setDocumentType={setDocumentType}
          isNotAgreedDocs
        />
      )}
    </div>
  );
};

export default EmployeeCard;
