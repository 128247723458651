import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../reducers/mainReducer";
import {
  GET_SCHEMA_DOCUMENT_SUCCESS,
  GET_SCHEMA_DOCUMENT_FAILURE,
  FINALIZE_TASK_SUCCESS,
  FINALIZE_TASK_FAILURE,
  GET_DOCUMENT_FAILURE,
  GET_DOCUMENT_FILE_FAILURE,
  GET_DOCUMENT_FILE_SUCCESS,
  GET_INN_DOCUMENT_FAILURE,
  GET_INN_DOCUMENT_SUCCESS,
  GET_USN_DOCUMENT_FAILURE,
  GET_USN_DOCUMENT_SUCCESS,
  GET_BANK_DOCUMENT_FAILURE,
  GET_BANK_DOCUMENT_SUCCESS,
  GET_SNILS_DOCUMENT_FAILURE,
  GET_SNILS_DOCUMENT_SUCCESS,
  GET_BANK_STATEMENT_SUCCESS,
  GET_BANK_STATEMENT_FAILURE,
  GET_INCOME_DOCUMENT_FAILURE,
  GET_INCOME_DOCUMENT_SUCCESS,
  GET_CONTRACT_DOCUMENT_FAILURE,
  GET_CONTRACT_DOCUMENT_SUCCESS,
  GET_PASSPORT_DOCUMENT_FAILURES,
  GET_PASSPORT_DOCUMENT_SUCCESS,
  GET_DECISION_DOCUMENT_FAILURE,
  GET_DECISION_DOCUMENT_SUCCESS,
  GET_MIGRATION_DOCUMENT_FAILURE,
  GET_MIGRATION_DOCUMENT_SUCCESS,
  UPDATE_INN_DOCUMENT_FAILURE,
  UPDATE_INN_DOCUMENT_SUCCESS,
  UPDATE_USN_DOCUMENT_FAILURE,
  UPDATE_USN_DOCUMENT_SUCCESS,
  UPDATE_BANK_DOCUMENT_FAILURE,
  UPDATE_BANK_DOCUMENT_SUCCESS,
  UPDATE_SNILS_DOCUMENT_FAILURE,
  UPDATE_SNILS_DOCUMENT_SUCCESS,
  UPDATE_INCOME_DOCUMENT_FAILURE,
  UPDATE_INCOME_DOCUMENT_SUCCESS,
  UPDATE_CONTRACT_DOCUMENT_FAILURE,
  UPDATE_CONTRACT_DOCUMENT_SUCCESS,
  UPDATE_DECISION_DOCUMENT_FAILURE,
  UPDATE_DECISION_DOCUMENT_SUCCESS,
  UPDATE_PASSPORT_DOCUMENT_FAILURE,
  UPDATE_PASSPORT_DOCUMENT_SUCCESS,
  UPDATE_MIGRATION_DOCUMENT_FAILURE,
  UPDATE_MIGRATION_DOCUMENT_SUCCESS,
  GET_SCHEMA_INN_DOCUMENT_SUCCESS,
  GET_SCHEMA_USN_DOCUMENT_SUCCESS,
  GET_SCHEMA_BANK_DOCUMENT_SUCCESS,
  GET_SCHEMA_SNILS_DOCUMENT_SUCCESS,
  GET_SCHEMA_INCOME_DOCUMENT_SUCCESS,
  GET_SCHEMA_CONTRACT_DOCUMENT_SUCCESS,
  GET_SCHEMA_DECISION_DOCUMENT_SUCCESS,
  GET_SCHEMA_PASSPORT_DOCUMENT_SUCCESS,
  GET_SCHEMA_MIGRATION_DOCUMENT_SUCCESS,
  GET_USN_COMPANY_DOCUMENT_SUCCESS,
  GET_USN_COMPANY_DOCUMENT_FAILURE,
  GET_BANK_COMPANY_DOCUMENT_SUCCESS,
  GET_BANK_COMPANY_DOCUMENT_FAILURE,
  GET_SNILS_COMPANY_DOCUMENT_SUCCESS,
  GET_SNILS_COMPANY_DOCUMENT_FAILURE,
  GET_PASSPORT_COMPANY_DOCUMENT_SUCCESS,
  GET_PASSPORT_COMPANY_DOCUMENT_FAILURE,
  GET_DECISION_COMPANY_DOCUMENT_SUCCESS,
  GET_DECISION_COMPANY_DOCUMENT_FAILURE,
  GET_MIGRATION_COMPANY_DOCUMENT_SUCCESS,
  GET_MIGRATION_COMPANY_DOCUMENT_FAILURE,
  UPDATE_USN_COMPANY_DOCUMENT_SUCCESS,
  UPDATE_USN_DOCUMENT_COMPANY_FAILURE,
  UPDATE_BANK_COMPANY_DOCUMENT_SUCCESS,
  UPDATE_BANK_COMPANY_DOCUMENT_FAILURE,
  UPDATE_SNILS_COMPANY_DOCUMENT_SUCCESS,
  UPDATE_SNILS_COMPANY_DOCUMENT_FAILURE,
  UPDATE_PASSPORT_COMPANY_DOCUMENT_SUCCESS,
  UPDATE_PASSPORT_COMPANY_DOCUMENT_FAILURE,
  UPDATE_DECISION_COMPANY_DOCUMENT_SUCCESS,
  UPDATE_DECISION_COMPANY_DOCUMENT_FAILURE,
  UPDATE_MIGRATION_COMPANY_DOCUMENT_SUCCESS,
  UPDATE_MIGRATION_COMPANY_DOCUMENT_FAILURE,
  CREATE_NEW_DOCUMENT_SUCCESS,
  CREATE_NEW_DOCUMENT_FAILURE,
  GET_BANK_PACKAGES_STATEMENT_SUCCESS,
  GET_BANK_PACKAGES_STATEMENT_FAILURE,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAILURE,
  GET_DOCUMENT_LIST_SUCCESS,
  GET_DOCUMENT_LIST_FAILURE,
  CLEAR_DOCUMENT_COMPANY
} from "../constants/actionTypes";
import * as DocumentAPI from "../api/document.api";
import { BankStatementDataType, SchemaType, UpdateDocumentFieldType, UpdateDocumentType } from "app/types";

export type DocumentActionsTypes =
  | GetDocumentFailureType
  | GetInnDocumentSuccessType
  | GetInnDocumentFailureType
  | GetUsnDocumentSuccessType
  | GetUsnDocumentFailureType
  | GetBankDocumentSuccessType
  | GetBankDocumentFailureType
  | GetSnilsDocumentSuccessType
  | GetSnilsDocumentFailureType
  | GetBankStatementSuccessType
  | GetBankStatementFailureType
  | GetIncomeDocumentSuccessType
  | GetIncomeDocumentFailureType
  | GetPassportDocumentSuccessType
  | GetPassportDocumentFailureType
  | GetContractDocumentSuccessType
  | GetContractDocumentFailureType
  | GetDecisionDocumentSuccessType
  | GetDecisionDocumentFailureType
  | GetMigrationDocumentSuccessType
  | GetMigrationDocumentFailureType
  | GetDocumentFileSuccessType
  | GetDocumentFileFailureType
  | GetSchemaDocumentSuccessType
  | GetSchemaDocumentFailureType
  | FinalizeTaskFailureType
  | FinalizeTaskSuccessType
  | UpdateInnDocumentSuccessType
  | UpdateInnDocumentFailureType
  | UpdateUsnDocumentSuccessType
  | UpdateUsnDocumentFailureType
  | UpdateBankDocumentSuccessType
  | UpdateBankDocumentFailureType
  | UpdateSnilsDocumentSuccessType
  | UpdateSnilsDocumentFailureType
  | UpdateIncomeDocumentSuccessType
  | UpdateIncomeDocumentFailureType
  | UpdatePassportDocumentSuccessType
  | UpdatePassportDocumentFailureType
  | UpdateDecisionDocumentSuccessType
  | UpdateDecisionDocumentFailureType
  | UpdateContractDocumentSuccessType
  | UpdateContractDocumentFailureType
  | UpdateMigrationDocumentSuccessType
  | UpdateMigrationDocumentFailureType
  | GetSchemaInnDocumentSuccessType
  | GetSchemaUsnDocumentSuccessType
  | GetSchemaBankDocumentSuccessType
  | GetSchemaSnilsDocumentSuccessType
  | GetSchemaIncomeDocumentSuccessType
  | GetSchemaDecisionDocumentSuccessType
  | GetSchemaPassportDocumentSuccessType
  | GetSchemaContractDocumentSuccessType
  | GetSchemaMigrationDocumentSuccessType
  | GetUsnCompanyDocumentSuccessType
  | GetUsnCompanyDocumentFailureType
  | GetBankCompanyDocumentSuccessType
  | GetBankCompanyDocumentFailureType
  | GetSnilsCompanyDocumentSuccessType
  | GetSnilsCompanyDocumentFailureType
  | GetPassportCompanyDocumentSuccessType
  | GetPassportCompanyDocumentFailureType
  | GetDecisionCompanyDocumentSuccessType
  | GetDecisionCompanyDocumentFailureType
  | GetMigrationCompanyDocumentSuccessType
  | GetMigrationCompanyDocumentFailureType
  | UpdateUsnCompanyDocumentSuccessType
  | UpdateUsnCompanyDocumentFailureType
  | UpdateBankCompanyDocumentSuccessType
  | UpdateBankCompanyDocumentFailureType
  | UpdateSnilsCompanyDocumentSuccessType
  | UpdateSnilsCompanyDocumentFailureType
  | UpdatePassportCompanyDocumentSuccessType
  | UpdatePassportCompanyDocumentFailureType
  | UpdateDecisionCompanyDocumentSuccessType
  | UpdateDecisionCompanyDocumentFailureType
  | UpdateMigrationCompanyDocumentSuccessType
  | UpdateMigrationCompanyDocumentFailureType
  | CreateNewDocumentSuccessType
  | CreateNewDocumentFailureType
  | GetBankPackagesStatementSuccessType
  | GetBankPackagesStatementFailureType
  | UpdateDocumentSuccessType
  | UpdateDocumentFailureType
  | GetDocumentListSuccessType
  | GetDocumentListFailureType
  | ClearDocumentCompanyType
  ;

// Логика получения файла конкретного документа
export type GetDocumentFileSuccessType = {
  type: typeof GET_DOCUMENT_FILE_SUCCESS;
  payload: {
    documentCurrentFileData: string;
  };
};

const getDocumentFileSuccess = (documentCurrentFileData: string): GetDocumentFileSuccessType => ({
  type: GET_DOCUMENT_FILE_SUCCESS,
  payload: {
    documentCurrentFileData
  }
});

export type GetDocumentFileFailureType = {
  type: typeof GET_DOCUMENT_FILE_FAILURE;
  payload: {
    error: any;
  };
};

const getDocumentFileFailure = (error: any): GetDocumentFileFailureType => ({
  type: GET_DOCUMENT_FILE_FAILURE,
  payload: {
    error
  }
});

export const getDocumentFileData = (file_uuid: string):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    DocumentActionsTypes
  > => async (dispatch) => {
  try {
    const response = await DocumentAPI.getDocumentFile(file_uuid);

    dispatch(getDocumentFileSuccess(response.data));
  } catch (error: any) {
    dispatch(getDocumentFileFailure(error.message));
  }
};

// Логика получения документов сотрудника компании
export type GetDocumentFailureType = {
  type: typeof GET_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

export const getDocumentFailure = (error?: any): GetDocumentFailureType => ({
  type: GET_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type ClearDocumentCompanyType = {
  type: typeof CLEAR_DOCUMENT_COMPANY;
};

export const clearDocumentCompany = (): ClearDocumentCompanyType => ({
  type: CLEAR_DOCUMENT_COMPANY,
});

export type GetInnDocumentSuccessType = {
  type: typeof GET_INN_DOCUMENT_SUCCESS;
  payload: {
    innDoc: UpdateDocumentType;
    innDocStatus: number;
  };
};

const getInnDocumentSuccess = (innDoc: UpdateDocumentType, innDocStatus: number): GetInnDocumentSuccessType => ({
  type: GET_INN_DOCUMENT_SUCCESS,
  payload: {
    innDoc,
    innDocStatus
  }
});

export type GetInnDocumentFailureType = {
  type: typeof GET_INN_DOCUMENT_FAILURE;
  payload: {
    error: any;
    innDocStatus: number;
  };
};

const getInnDocumentFailure = (error: any, innDocStatus: number): GetInnDocumentFailureType => ({
  type: GET_INN_DOCUMENT_FAILURE,
  payload: {
    error,
    innDocStatus
  }
});

export type GetUsnDocumentSuccessType = {
  type: typeof GET_USN_DOCUMENT_SUCCESS
  payload: {
    usnDoc: UpdateDocumentType;
    usnDocStatus: number;
  };
};

const getUsnDocumentSuccess = (usnDoc: UpdateDocumentType, usnDocStatus: number): GetUsnDocumentSuccessType => ({
  type: GET_USN_DOCUMENT_SUCCESS,
  payload: {
    usnDoc,
    usnDocStatus
  }
});

export type GetUsnDocumentFailureType = {
  type: typeof GET_USN_DOCUMENT_FAILURE;
  payload: {
    error: any;
    usnDocStatus: number
  };
};

const getUsnDocumentFailure = (error: any, usnDocStatus: number): GetUsnDocumentFailureType => ({
  type: GET_USN_DOCUMENT_FAILURE,
  payload: {
    error,
    usnDocStatus
  }
});

export type GetBankDocumentSuccessType = {
  type: typeof GET_BANK_DOCUMENT_SUCCESS
  payload: {
    bankDoc: UpdateDocumentType;
    bankDocStatus: number;
  };
};

const getBankDocumentSuccess = (bankDoc: UpdateDocumentType, bankDocStatus: number): GetBankDocumentSuccessType => ({
  type: GET_BANK_DOCUMENT_SUCCESS,
  payload: {
    bankDoc,
    bankDocStatus
  }
});

export type GetBankDocumentFailureType = {
  type: typeof GET_BANK_DOCUMENT_FAILURE
  payload: {
    error: any;
    bankDocStatus: number;
  };
};

const getBankDocumentFailure = (error: any, bankDocStatus: number): GetBankDocumentFailureType => ({
  type: GET_BANK_DOCUMENT_FAILURE,
  payload: {
    error,
    bankDocStatus,
  }
});

export type GetSnilsDocumentSuccessType = {
  type: typeof GET_SNILS_DOCUMENT_SUCCESS;
  payload: {
    snilsDoc: UpdateDocumentType;
    snilsDocStatus: number;
  };
};

const getSnilsDocumentSuccess = (
  snilsDoc: UpdateDocumentType,
  snilsDocStatus: number
): GetSnilsDocumentSuccessType => ({
  type: GET_SNILS_DOCUMENT_SUCCESS,
  payload: {
    snilsDoc,
    snilsDocStatus
  }
});

export type GetSnilsDocumentFailureType = {
  type: typeof GET_SNILS_DOCUMENT_FAILURE
  payload: {
    error: any;
    snilsDocStatus: number;
  };
};

const getSnilsDocumentFailure = (error: any, snilsDocStatus: number): GetSnilsDocumentFailureType => ({
  type: GET_SNILS_DOCUMENT_FAILURE,
  payload: {
    error,
    snilsDocStatus
  }
});

export type GetIncomeDocumentSuccessType = {
  type: typeof GET_INCOME_DOCUMENT_SUCCESS;
  payload: {
    incomeDoc: UpdateDocumentType;
    incomeDocStatus: number
  };
};

const getIncomeDocumentSuccess = (
  incomeDoc: UpdateDocumentType,
  incomeDocStatus: number
): GetIncomeDocumentSuccessType => ({
  type: GET_INCOME_DOCUMENT_SUCCESS,
  payload: {
    incomeDoc,
    incomeDocStatus
  }
});

export type GetIncomeDocumentFailureType = {
  type: typeof GET_INCOME_DOCUMENT_FAILURE
  payload: {
    error: any;
    incomeDocStatus: number
  };
};

const getIncomeDocumentFailure = (error: any, incomeDocStatus: number): GetIncomeDocumentFailureType => ({
  type: GET_INCOME_DOCUMENT_FAILURE,
  payload: {
    error,
    incomeDocStatus
  }
});

export type GetPassportDocumentSuccessType = {
  type: typeof GET_PASSPORT_DOCUMENT_SUCCESS;
  payload: {
    passportDoc: UpdateDocumentType;
    passportDocStatus: number;
  };
};

const getPassportDocumentSuccess = (
  passportDoc: UpdateDocumentType,
  passportDocStatus: number
): GetPassportDocumentSuccessType => ({
  type: GET_PASSPORT_DOCUMENT_SUCCESS,
  payload: {
    passportDoc,
    passportDocStatus
  }
});

export type GetPassportDocumentFailureType = {
  type: typeof GET_PASSPORT_DOCUMENT_FAILURES
  payload: {
    error: any;
    passportDocStatus: number;
  };
};

const getPassportDocumentFailure = (
  error: any,
  passportDocStatus: number
): GetPassportDocumentFailureType => ({
  type: GET_PASSPORT_DOCUMENT_FAILURES,
  payload: {
    error,
    passportDocStatus
  }
});

export type GetDecisionDocumentSuccessType = {
  type: typeof GET_DECISION_DOCUMENT_SUCCESS
  payload: {
    decisionDoc: UpdateDocumentType;
    decisionDocStatus: number;
  };
};

const getDecisionDocumentSuccess = (
  decisionDoc: UpdateDocumentType,
  decisionDocStatus: number
): GetDecisionDocumentSuccessType => ({
  type: GET_DECISION_DOCUMENT_SUCCESS,
  payload: {
    decisionDoc,
    decisionDocStatus
  }
});

export type GetDecisionDocumentFailureType = {
  type: typeof GET_DECISION_DOCUMENT_FAILURE
  payload: {
    error: any;
    decisionDocStatus: number;
  };
};

const getDecisionDocumentFailure = (error: any, decisionDocStatus: number): GetDecisionDocumentFailureType => ({
  type: GET_DECISION_DOCUMENT_FAILURE,
  payload: {
    error,
    decisionDocStatus
  }
});

export type GetContractDocumentSuccessType = {
  type: typeof GET_CONTRACT_DOCUMENT_SUCCESS
  payload: {
    contractDoc: UpdateDocumentType;
    contractDocStatus: number;
  };
};

const getContractDocumentSuccess = (
  contractDoc: UpdateDocumentType,
  contractDocStatus: number
): GetContractDocumentSuccessType => ({
  type: GET_CONTRACT_DOCUMENT_SUCCESS,
  payload: {
    contractDoc,
    contractDocStatus
  }
});

export type GetContractDocumentFailureType = {
  type: typeof GET_CONTRACT_DOCUMENT_FAILURE
  payload: {
    error: any;
    contractDocStatus: number
  };
};

const getContractDocumentFailure = (error: any, contractDocStatus: number): GetContractDocumentFailureType => ({
  type: GET_CONTRACT_DOCUMENT_FAILURE,
  payload: {
    error,
    contractDocStatus
  }
});

export type GetMigrationDocumentSuccessType = {
  type: typeof GET_MIGRATION_DOCUMENT_SUCCESS
  payload: {
    migrationDoc: UpdateDocumentType;
    migrationDocStatus: number
  };
};

const getMigrationDocumentSuccess = (
  migrationDoc: UpdateDocumentType,
  migrationDocStatus: number
): GetMigrationDocumentSuccessType => ({
  type: GET_MIGRATION_DOCUMENT_SUCCESS,
  payload: {
    migrationDoc,
    migrationDocStatus
  }
});

export type GetMigrationDocumentFailureType = {
  type: typeof GET_MIGRATION_DOCUMENT_FAILURE
  payload: {
    error: any;
    migrationDocStatus: number
  };
};

const getMigrationDocumentFailure = (error: any, migrationDocStatus: number): GetMigrationDocumentFailureType => ({
  type: GET_MIGRATION_DOCUMENT_FAILURE,
  payload: {
    error,
    migrationDocStatus
  }
});

export const getEmployeeDocumentData = (docSchemaName: string, uuid: string):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    DocumentActionsTypes
  > => async (dispatch) => {
  try {
    const correctSchemaName: string = docSchemaName?.split("_")[1] || docSchemaName;

    const response = await DocumentAPI.getEmployeeDocument(correctSchemaName, uuid);

    switch (docSchemaName) {
      case "inn-schema":
        dispatch(getInnDocumentSuccess(response.data, response.status));
        break;
      case "snils-schema":
        dispatch(getSnilsDocumentSuccess(response.data, response.status));
        break;
       case "bank-schema":
        dispatch(getBankDocumentSuccess(response.data, response.status));
        break;
      case "passport-schema":
        dispatch(getPassportDocumentSuccess(response.data, response.status));
        break;
      case "contract-labor-schema":
      case "contract-civil-schema":
        dispatch(getContractDocumentSuccess(response.data, response.status));
        break;
      case "usn-schema":
        dispatch(getUsnDocumentSuccess(response.data, response.status));
        break;
      case "ndfl-schema":
        dispatch(getIncomeDocumentSuccess(response.data, response.status));
        break;
      case "mdm_founders-decision-schema":
      case "founders-decision-schema":
        dispatch(getDecisionDocumentSuccess(response.data, response.status));
        break;
      case "migration-card-schema":
      case "patent-schema":
        dispatch(getMigrationDocumentSuccess(response.data, response.status));
        break;
    }
  } catch (error: any) {
    switch (docSchemaName) {
      case "inn-schema":
        dispatch(getInnDocumentFailure(error.message, error.response.status));
        break;
      case "snils-schema":
        dispatch(getSnilsDocumentFailure(error.message, error.response.status));
        break;
      case "bank-schema":
        dispatch(getBankDocumentFailure(error.message, error.response.status));
        break;
      case "passport-schema":
        dispatch(getPassportDocumentFailure(error.message, error.response.status));
        break;
      case "contract-labor-schema":
      case "contract-civil-schema":
        dispatch(getContractDocumentFailure(error.message, error.response.status));
        break;
      case "usn-schema":
        dispatch(getUsnDocumentFailure(error.message, error.response.status));
        break;
      case "ndfl-schema":
        dispatch(getIncomeDocumentFailure(error.message, error.response.status));
        break;
      case "mdm_founders-decision-schema":
      case "founders-decision-schema":
        dispatch(getDecisionDocumentFailure(error.message, error.response.status));
        break;
      case "migration-card-schema":
      case "patent-schema":
        dispatch(getMigrationDocumentFailure(error.message, error.response.status));
        break;
    }
  }
};

// Логика получения документов компании
export type GetUsnCompanyDocumentSuccessType = {
  type: typeof GET_USN_COMPANY_DOCUMENT_SUCCESS;
  payload: {
    usnCompanyDoc: UpdateDocumentType;
  };
};

const getUsnCompanyDocumentSuccess = (usnCompanyDoc: UpdateDocumentType): GetUsnCompanyDocumentSuccessType => ({
  type: GET_USN_COMPANY_DOCUMENT_SUCCESS,
  payload: {
    usnCompanyDoc
  }
});

export type GetUsnCompanyDocumentFailureType = {
  type: typeof GET_USN_COMPANY_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const getUsnCompanyDocumentFailure = (error: any): GetUsnCompanyDocumentFailureType => ({
  type: GET_USN_COMPANY_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type GetBankCompanyDocumentSuccessType = {
  type: typeof GET_BANK_COMPANY_DOCUMENT_SUCCESS;
  payload: {
    bankCompanyDoc: UpdateDocumentType;
  };
};

const getBankCompanyDocumentSuccess = (bankCompanyDoc: UpdateDocumentType): GetBankCompanyDocumentSuccessType => ({
  type: GET_BANK_COMPANY_DOCUMENT_SUCCESS,
  payload: {
    bankCompanyDoc
  }
});

export type GetBankCompanyDocumentFailureType = {
  type: typeof GET_BANK_COMPANY_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const getBankCompanyDocumentFailure = (error: any): GetBankCompanyDocumentFailureType => ({
  type: GET_BANK_COMPANY_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type GetSnilsCompanyDocumentSuccessType = {
  type: typeof GET_SNILS_COMPANY_DOCUMENT_SUCCESS;
  payload: {
    snilsCompanyDoc: UpdateDocumentType;
  };
};

const getSnilsCompanyDocumentSuccess = (snilsCompanyDoc: UpdateDocumentType): GetSnilsCompanyDocumentSuccessType => ({
  type: GET_SNILS_COMPANY_DOCUMENT_SUCCESS,
  payload: {
    snilsCompanyDoc
  }
});

export type GetSnilsCompanyDocumentFailureType = {
  type: typeof GET_SNILS_COMPANY_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const getSnilsCompanyDocumentFailure = (error: any): GetSnilsCompanyDocumentFailureType => ({
  type: GET_SNILS_COMPANY_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type GetPassportCompanyDocumentSuccessType = {
  type: typeof GET_PASSPORT_COMPANY_DOCUMENT_SUCCESS;
  payload: {
    passportCompanyDoc: UpdateDocumentType;
  };
};

const getPassportCompanyDocumentSuccess = (passportCompanyDoc: UpdateDocumentType)
  : GetPassportCompanyDocumentSuccessType => ({
  type: GET_PASSPORT_COMPANY_DOCUMENT_SUCCESS,
  payload: {
    passportCompanyDoc
  }
});

export type GetPassportCompanyDocumentFailureType = {
  type: typeof GET_PASSPORT_COMPANY_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const getPassportCompanyDocumentFailure = (error: any): GetPassportCompanyDocumentFailureType => ({
  type: GET_PASSPORT_COMPANY_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type GetDecisionCompanyDocumentSuccessType = {
  type: typeof GET_DECISION_COMPANY_DOCUMENT_SUCCESS;
  payload: {
    decisionCompanyDoc: UpdateDocumentType;
  };
};

const getDecisionCompanyDocumentSuccess = (decisionCompanyDoc: UpdateDocumentType)
  : GetDecisionCompanyDocumentSuccessType => ({
  type: GET_DECISION_COMPANY_DOCUMENT_SUCCESS,
  payload: {
    decisionCompanyDoc
  }
});

export type GetDecisionCompanyDocumentFailureType = {
  type: typeof GET_DECISION_COMPANY_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const getDecisionCompanyDocumentFailure = (error: any): GetDecisionCompanyDocumentFailureType => ({
  type: GET_DECISION_COMPANY_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type GetMigrationCompanyDocumentSuccessType = {
  type: typeof GET_MIGRATION_COMPANY_DOCUMENT_SUCCESS;
  payload: {
    migrationCompanyDoc: UpdateDocumentType;
  };
};

const getMigrationCompanyDocumentSuccess = (migrationCompanyDoc: UpdateDocumentType)
  : GetMigrationCompanyDocumentSuccessType => ({
  type: GET_MIGRATION_COMPANY_DOCUMENT_SUCCESS,
  payload: {
    migrationCompanyDoc
  }
});

export type GetMigrationCompanyDocumentFailureType = {
  type: typeof GET_MIGRATION_COMPANY_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const getMigrationCompanyDocumentFailure = (error: any): GetMigrationCompanyDocumentFailureType => ({
  type: GET_MIGRATION_COMPANY_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export const getCompanyDocumentData = (docSchemaName: string, uuid: string):
  ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  DocumentActionsTypes
> => async (dispatch) => {
  try {
    const correctSchemaName: string = docSchemaName?.split("_")[1] || docSchemaName;

    const response = await DocumentAPI.getCompanyDocument(correctSchemaName, uuid);

    switch (docSchemaName) {
      case "snils-schema":
        dispatch(getSnilsCompanyDocumentSuccess(response.data));
        break;
       case "bank-schema":
        dispatch(getBankCompanyDocumentSuccess(response.data));
        break;
      case "passport-schema":
        dispatch(getPassportCompanyDocumentSuccess(response.data));
        break;
      case "usn-schema":
        dispatch(getUsnCompanyDocumentSuccess(response.data));
        break;
      case "mdm_founders-decision-schema":
      case "founders-decision-schema":
        dispatch(getDecisionCompanyDocumentSuccess(response.data));
        break;
      case "migration-card-schema":
      case "patent-schema":
        dispatch(getMigrationCompanyDocumentSuccess(response.data));
        break;
    }
  } catch (error: any) {
    switch (docSchemaName) {
      case "snils-schema":
        dispatch(getSnilsCompanyDocumentFailure(error.message));
        break;
      case "bank-schema":
        dispatch(getBankCompanyDocumentFailure(error.message));
        break;
      case "passport-schema":
        dispatch(getPassportCompanyDocumentFailure(error.message));
        break;
      case "usn-schema":
        dispatch(getUsnCompanyDocumentFailure(error.message));
        break;
      case "mdm_founders-decision-schema":
      case "founders-decision-schema":
        dispatch(getDecisionCompanyDocumentFailure(error.message));
        break;
      case "migration-card-schema":
      case "patent-schema":
        dispatch(getMigrationCompanyDocumentFailure(error.message));
        break;
    }
  }
};

// Логика обновления данных документа
export type UpdateInnDocumentSuccessType = {
  type: typeof UPDATE_INN_DOCUMENT_SUCCESS;
  payload: {
    innDoc: UpdateDocumentType;
  };
};

const updateInnDocumentSuccess = (innDoc: UpdateDocumentType): UpdateInnDocumentSuccessType => ({
  type: UPDATE_INN_DOCUMENT_SUCCESS,
  payload: {
    innDoc
  }
});

export type UpdateInnDocumentFailureType = {
  type: typeof UPDATE_INN_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const updateInnDocumentFailure = (error: any): UpdateInnDocumentFailureType => ({
  type: UPDATE_INN_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type UpdateUsnDocumentSuccessType = {
  type: typeof UPDATE_USN_DOCUMENT_SUCCESS;
  payload: {
    usnDoc: UpdateDocumentType;
  };
};

const updateUsnDocumentSuccess = (usnDoc: UpdateDocumentType): UpdateUsnDocumentSuccessType => ({
  type: UPDATE_USN_DOCUMENT_SUCCESS,
  payload: {
    usnDoc
  }
});

export type UpdateUsnDocumentFailureType = {
  type: typeof UPDATE_USN_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const updateUsnDocumentFailure = (error: any): UpdateUsnDocumentFailureType => ({
  type: UPDATE_USN_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type UpdateBankDocumentSuccessType = {
  type: typeof UPDATE_BANK_DOCUMENT_SUCCESS;
  payload: {
    bankDoc: UpdateDocumentType;
  };
};

const updateBankDocumentSuccess = (bankDoc: UpdateDocumentType): UpdateBankDocumentSuccessType => ({
  type: UPDATE_BANK_DOCUMENT_SUCCESS,
  payload: {
    bankDoc
  }
});

export type UpdateBankDocumentFailureType = {
  type: typeof UPDATE_BANK_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const updateBankDocumentFailure = (error: any): UpdateBankDocumentFailureType => ({
  type: UPDATE_BANK_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type UpdateSnilsDocumentSuccessType = {
  type: typeof UPDATE_SNILS_DOCUMENT_SUCCESS;
  payload: {
    snilsDoc: UpdateDocumentType;
  };
};

const updateSnilsDocumentSuccess = (snilsDoc: UpdateDocumentType): UpdateSnilsDocumentSuccessType => ({
  type: UPDATE_SNILS_DOCUMENT_SUCCESS,
  payload: {
    snilsDoc
  }
});

export type UpdateSnilsDocumentFailureType = {
  type: typeof UPDATE_SNILS_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const updateSnilsDocumentFailure = (error: any): UpdateSnilsDocumentFailureType => ({
  type: UPDATE_SNILS_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type UpdateIncomeDocumentSuccessType = {
  type: typeof UPDATE_INCOME_DOCUMENT_SUCCESS;
  payload: {
    incomeDoc: UpdateDocumentType;
  };
};

const updateIncomeDocumentSuccess = (incomeDoc: UpdateDocumentType): UpdateIncomeDocumentSuccessType => ({
  type: UPDATE_INCOME_DOCUMENT_SUCCESS,
  payload: {
    incomeDoc
  }
});

export type UpdateIncomeDocumentFailureType = {
  type: typeof UPDATE_INCOME_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const updateIncomeDocumentFailure = (error: any): UpdateIncomeDocumentFailureType => ({
  type: UPDATE_INCOME_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type UpdatePassportDocumentSuccessType = {
  type: typeof UPDATE_PASSPORT_DOCUMENT_SUCCESS;
  payload: {
    passportDoc: UpdateDocumentType;
  };
};

const updatePassportDocumentSuccess = (passportDoc: UpdateDocumentType): UpdatePassportDocumentSuccessType => ({
  type: UPDATE_PASSPORT_DOCUMENT_SUCCESS,
  payload: {
    passportDoc
  }
});

export type UpdatePassportDocumentFailureType = {
  type: typeof UPDATE_PASSPORT_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const updatePassportDocumentFailure = (error: any): UpdatePassportDocumentFailureType => ({
  type: UPDATE_PASSPORT_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type UpdateDecisionDocumentSuccessType = {
  type: typeof UPDATE_DECISION_DOCUMENT_SUCCESS;
  payload: {
    decisionDoc: UpdateDocumentType;
  };
};

const updateDecisionDocumentSuccess = (decisionDoc: UpdateDocumentType): UpdateDecisionDocumentSuccessType => ({
  type: UPDATE_DECISION_DOCUMENT_SUCCESS,
  payload: {
    decisionDoc
  }
});

export type UpdateDecisionDocumentFailureType = {
  type: typeof UPDATE_DECISION_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const updateDecisionDocumentFailure = (error: any): UpdateDecisionDocumentFailureType => ({
  type: UPDATE_DECISION_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type UpdateContractDocumentSuccessType = {
  type: typeof UPDATE_CONTRACT_DOCUMENT_SUCCESS;
  payload: {
    contractDoc: UpdateDocumentType;
  };
};

const updateContractDocumentSuccess = (contractDoc: UpdateDocumentType): UpdateContractDocumentSuccessType => ({
  type: UPDATE_CONTRACT_DOCUMENT_SUCCESS,
  payload: {
    contractDoc
  }
});

export type UpdateContractDocumentFailureType = {
  type: typeof UPDATE_CONTRACT_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const updateContractDocumentFailure = (error: any): UpdateContractDocumentFailureType => ({
  type: UPDATE_CONTRACT_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type UpdateMigrationDocumentSuccessType = {
  type: typeof UPDATE_MIGRATION_DOCUMENT_SUCCESS;
  payload: {
    migrationDoc: UpdateDocumentType;
  };
};

const updateMigrationDocumentSuccess = (migrationDoc: UpdateDocumentType): UpdateMigrationDocumentSuccessType => ({
  type: UPDATE_MIGRATION_DOCUMENT_SUCCESS,
  payload: {
    migrationDoc
  }
});

export type UpdateMigrationDocumentFailureType = {
  type: typeof UPDATE_MIGRATION_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const updateMigrationDocumentFailure = (error: any): UpdateMigrationDocumentFailureType => ({
  type: UPDATE_MIGRATION_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export const updateEmployeeDocumentData = (docSchemaName: string, uuid: string, data: UpdateDocumentFieldType | null):
    ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    DocumentActionsTypes
  > => async (dispatch) => {
  try {
    const correctSchemaName: string = docSchemaName?.split("_")[1] || docSchemaName;

    const response = await DocumentAPI.updateEmployeeDocument(correctSchemaName, uuid, data);

    switch (docSchemaName) {
      case "inn-schema":
        dispatch(updateInnDocumentSuccess(response.data));
        break;
      case "snils-schema":
        dispatch(updateSnilsDocumentSuccess(response.data));
        break;
      case "bank-schema":
        dispatch(updateBankDocumentSuccess(response.data));
        break;
      case "passport-schema":
        dispatch(updatePassportDocumentSuccess(response.data));
        break;
      case "contract-labor-schema":
      case "contract-civil-schema":
        dispatch(updateContractDocumentSuccess(response.data));
        break;
      case "usn-schema":
        dispatch(updateUsnDocumentSuccess(response.data));
        break;
      case "ndfl-schema":
        dispatch(updateIncomeDocumentSuccess(response.data));
        break;
      case "mdm_founders-decision-schema":
        dispatch(updateDecisionDocumentSuccess(response.data));
        break;
      case "migration-card-schema":
      case "patent-schema":
        dispatch(updateMigrationDocumentSuccess(response.data));
        break;
    }
  } catch (error: any) {
    switch (docSchemaName) {
      case "inn-schema":
        dispatch(updateInnDocumentFailure(error.message));
        break;
      case "snils-schema":
        dispatch(updateSnilsDocumentFailure(error.message));
        break;
      case "bank-schema":
        dispatch(updateBankDocumentFailure(error.message));
        break;
      case "passport-schema":
        dispatch(updatePassportDocumentFailure(error.message));
        break;
      case "contract-labor-schema":
      case "contract-civil-schema":
        dispatch(updateContractDocumentFailure(error.message));
        break;
      case "usn-schema":
        dispatch(updateUsnDocumentFailure(error.message));
        break;
      case "ndfl-schema":
        dispatch(updateIncomeDocumentFailure(error.message));
        break;
      case "mdm_founders-decision-schema":
        dispatch(updateDecisionDocumentFailure(error.message));
        break;
      case "migration-card-schema":
      case "patent-schema":
        dispatch(updateMigrationDocumentFailure(error.message));
        break;
    }
  }
};

// Логика обновления данных документа
export type UpdateUsnCompanyDocumentSuccessType = {
  type: typeof UPDATE_USN_COMPANY_DOCUMENT_SUCCESS;
  payload: {
    usnCompanyDoc: UpdateDocumentType;
  };
};

const updateUsnCompanyDocumentSuccess = (usnCompanyDoc: UpdateDocumentType): UpdateUsnCompanyDocumentSuccessType => ({
  type: UPDATE_USN_COMPANY_DOCUMENT_SUCCESS,
  payload: {
    usnCompanyDoc
  }
});

export type UpdateUsnCompanyDocumentFailureType = {
  type: typeof UPDATE_USN_DOCUMENT_COMPANY_FAILURE;
  payload: {
    error: any;
  };
};

const updateUsnCompanyDocumentFailure = (error: any): UpdateUsnCompanyDocumentFailureType => ({
  type: UPDATE_USN_DOCUMENT_COMPANY_FAILURE,
  payload: {
    error
  }
});

export type UpdateBankCompanyDocumentSuccessType = {
  type: typeof UPDATE_BANK_COMPANY_DOCUMENT_SUCCESS;
  payload: {
    bankCompanyDoc: UpdateDocumentType;
  };
};

const updateBankCompanyDocumentSuccess = (bankCompanyDoc: UpdateDocumentType)
  : UpdateBankCompanyDocumentSuccessType => ({
  type: UPDATE_BANK_COMPANY_DOCUMENT_SUCCESS,
  payload: {
    bankCompanyDoc
  }
});

export type UpdateBankCompanyDocumentFailureType = {
  type: typeof UPDATE_BANK_COMPANY_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const updateBankCompanyDocumentFailure = (error: any): UpdateBankCompanyDocumentFailureType => ({
  type: UPDATE_BANK_COMPANY_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type UpdateSnilsCompanyDocumentSuccessType = {
  type: typeof UPDATE_SNILS_COMPANY_DOCUMENT_SUCCESS;
  payload: {
    snilsCompanyDoc: UpdateDocumentType;
  };
};

const updateSnilsCompanyDocumentSuccess = (snilsCompanyDoc: UpdateDocumentType)
  : UpdateSnilsCompanyDocumentSuccessType => ({
  type: UPDATE_SNILS_COMPANY_DOCUMENT_SUCCESS,
  payload: {
    snilsCompanyDoc
  }
});

export type UpdateSnilsCompanyDocumentFailureType = {
  type: typeof UPDATE_SNILS_COMPANY_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const updateSnilsCompanyDocumentFailure = (error: any): UpdateSnilsCompanyDocumentFailureType => ({
  type: UPDATE_SNILS_COMPANY_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type UpdatePassportCompanyDocumentSuccessType = {
  type: typeof UPDATE_PASSPORT_COMPANY_DOCUMENT_SUCCESS;
  payload: {
    passportCompanyDoc: UpdateDocumentType;
  };
};

const updatePassportCompanyDocumentSuccess = (passportCompanyDoc: UpdateDocumentType)
  : UpdatePassportCompanyDocumentSuccessType => ({
  type: UPDATE_PASSPORT_COMPANY_DOCUMENT_SUCCESS,
  payload: {
    passportCompanyDoc
  }
});

export type UpdatePassportCompanyDocumentFailureType = {
  type: typeof UPDATE_PASSPORT_COMPANY_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const updatePassportCompanyDocumentFailure = (error: any): UpdatePassportCompanyDocumentFailureType => ({
  type: UPDATE_PASSPORT_COMPANY_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type UpdateDecisionCompanyDocumentSuccessType = {
  type: typeof UPDATE_DECISION_COMPANY_DOCUMENT_SUCCESS;
  payload: {
    decisionCompanyDoc: UpdateDocumentType;
  };
};

const updateDecisionCompanyDocumentSuccess = (decisionCompanyDoc: UpdateDocumentType)
  : UpdateDecisionCompanyDocumentSuccessType => ({
  type: UPDATE_DECISION_COMPANY_DOCUMENT_SUCCESS,
  payload: {
    decisionCompanyDoc
  }
});

export type UpdateDecisionCompanyDocumentFailureType = {
  type: typeof UPDATE_DECISION_COMPANY_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const updateDecisionCompanyDocumentFailure = (error: any): UpdateDecisionCompanyDocumentFailureType => ({
  type: UPDATE_DECISION_COMPANY_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type UpdateMigrationCompanyDocumentSuccessType = {
  type: typeof UPDATE_MIGRATION_COMPANY_DOCUMENT_SUCCESS;
  payload: {
    migrationCompanyDoc: UpdateDocumentType;
  };
};

const updateMigrationCompanyDocumentSuccess = (migrationCompanyDoc: UpdateDocumentType)
  : UpdateMigrationCompanyDocumentSuccessType => ({
  type: UPDATE_MIGRATION_COMPANY_DOCUMENT_SUCCESS,
  payload: {
    migrationCompanyDoc
  }
});

export type UpdateMigrationCompanyDocumentFailureType = {
  type: typeof UPDATE_MIGRATION_COMPANY_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

const updateMigrationCompanyDocumentFailure = (error: any): UpdateMigrationCompanyDocumentFailureType => ({
  type: UPDATE_MIGRATION_COMPANY_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export const updateCompanyDocumentData = (docSchemaName: string, uuid: string, data: UpdateDocumentFieldType | null):
    ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    DocumentActionsTypes
  > => async (dispatch) => {
  try {
    const correctSchemaName: string = docSchemaName?.split("_")[1] || docSchemaName;

    const response = await DocumentAPI.updateCompanyDocument(correctSchemaName, uuid, data);

    switch (docSchemaName) {
      case "snils-schema":
        dispatch(updateSnilsCompanyDocumentSuccess(response.data));
        break;
      case "bank-schema":
        dispatch(updateBankCompanyDocumentSuccess(response.data));
        break;
      case "passport-schema":
        dispatch(updatePassportCompanyDocumentSuccess(response.data));
        break;
      case "usn-schema":
        dispatch(updateUsnCompanyDocumentSuccess(response.data));
        break;
      case "mdm_founders-decision-schema":
      case "founders-decision-schema":
        dispatch(updateDecisionCompanyDocumentSuccess(response.data));
        break;
      case "migration-card-schema":
      case "patent-schema":
        dispatch(updateMigrationCompanyDocumentSuccess(response.data));
        break;
    }
  } catch (error: any) {
    switch (docSchemaName) {
      case "snils-schema":
        dispatch(updateSnilsCompanyDocumentFailure(error.message));
        break;
      case "bank-schema":
        dispatch(updateBankCompanyDocumentFailure(error.message));
        break;
      case "passport-schema":
        dispatch(updatePassportCompanyDocumentFailure(error.message));
        break;
      case "usn-schema":
        dispatch(updateUsnCompanyDocumentFailure(error.message));
        break;
      case "mdm_founders-decision-schema":
      case "founders-decision-schema":
        dispatch(updateDecisionCompanyDocumentFailure(error.message));
        break;
      case "migration-card-schema":
      case "patent-schema":
        dispatch(updateMigrationCompanyDocumentFailure(error.message));
        break;
    }
  }
};

// Логика получения данных схемы документа по slug
export type GetSchemaDocumentSuccessType = {
  type: typeof GET_SCHEMA_DOCUMENT_SUCCESS;
  payload: {
    documentSchema: SchemaType;
  };
};

const getSchemaDocumentSuccess = (documentSchema: SchemaType)
  : GetSchemaDocumentSuccessType => ({
  type: GET_SCHEMA_DOCUMENT_SUCCESS,
  payload: {
    documentSchema
  }
});

export type GetSchemaDocumentFailureType = {
  type: typeof GET_SCHEMA_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

export const getSchemaDocumentFailure = (error?: any): GetSchemaDocumentFailureType => ({
  type: GET_SCHEMA_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export type GetSchemaInnDocumentSuccessType = {
  type: typeof GET_SCHEMA_INN_DOCUMENT_SUCCESS;
  payload: {
    innDocSchema: SchemaType;
  };
};

const getSchemaInnDocumentSuccess = (innDocSchema: SchemaType)
  : GetSchemaInnDocumentSuccessType => ({
  type: GET_SCHEMA_INN_DOCUMENT_SUCCESS,
  payload: {
    innDocSchema
  }
});

export type GetSchemaUsnDocumentSuccessType = {
  type: typeof GET_SCHEMA_USN_DOCUMENT_SUCCESS;
  payload: {
    usnDocSchema: SchemaType;
  };
};

const getSchemaUsnDocumentSuccess = (usnDocSchema: SchemaType)
  : GetSchemaUsnDocumentSuccessType => ({
  type: GET_SCHEMA_USN_DOCUMENT_SUCCESS,
  payload: {
    usnDocSchema
  }
});

export type GetSchemaSnilsDocumentSuccessType = {
  type: typeof GET_SCHEMA_SNILS_DOCUMENT_SUCCESS;
  payload: {
    snilsDocSchema: SchemaType;
  };
};

const getSchemaSnilsDocumentSuccess = (snilsDocSchema: SchemaType)
  : GetSchemaSnilsDocumentSuccessType => ({
  type: GET_SCHEMA_SNILS_DOCUMENT_SUCCESS,
  payload: {
    snilsDocSchema
  }
});

export type GetSchemaBankDocumentSuccessType = {
  type: typeof GET_SCHEMA_BANK_DOCUMENT_SUCCESS;
  payload: {
    bankDocSchema: SchemaType;
  };
};

const getSchemaBankDocumentSuccess = (bankDocSchema: SchemaType)
  : GetSchemaBankDocumentSuccessType => ({
  type: GET_SCHEMA_BANK_DOCUMENT_SUCCESS,
  payload: {
    bankDocSchema
  }
});

export type GetSchemaIncomeDocumentSuccessType = {
  type: typeof GET_SCHEMA_INCOME_DOCUMENT_SUCCESS;
  payload: {
    incomeDocSchema: SchemaType;
  };
};

const getSchemaIncomeDocumentSuccess = (incomeDocSchema: SchemaType)
  : GetSchemaIncomeDocumentSuccessType => ({
  type: GET_SCHEMA_INCOME_DOCUMENT_SUCCESS,
  payload: {
    incomeDocSchema
  }
});

export type GetSchemaContractDocumentSuccessType = {
  type: typeof GET_SCHEMA_CONTRACT_DOCUMENT_SUCCESS;
  payload: {
    contractDocSchema: SchemaType;
  };
};

const getSchemaContractDocumentSuccess = (contractDocSchema: SchemaType)
  : GetSchemaContractDocumentSuccessType => ({
  type: GET_SCHEMA_CONTRACT_DOCUMENT_SUCCESS,
  payload: {
    contractDocSchema
  }
});

export type GetSchemaDecisionDocumentSuccessType = {
  type: typeof GET_SCHEMA_DECISION_DOCUMENT_SUCCESS;
  payload: {
    decisionDocSchema: SchemaType;
  };
};

const getSchemaDecisionDocumentSuccess = (decisionDocSchema: SchemaType)
  : GetSchemaDecisionDocumentSuccessType => ({
  type: GET_SCHEMA_DECISION_DOCUMENT_SUCCESS,
  payload: {
    decisionDocSchema
  }
});

export type GetSchemaPassportDocumentSuccessType = {
  type: typeof GET_SCHEMA_PASSPORT_DOCUMENT_SUCCESS;
  payload: {
    passportDocSchema: SchemaType;
  };
};

const getSchemaPassportDocumentSuccess = (passportDocSchema: SchemaType)
  : GetSchemaPassportDocumentSuccessType => ({
  type: GET_SCHEMA_PASSPORT_DOCUMENT_SUCCESS,
  payload: {
    passportDocSchema
  }
});

export type GetSchemaMigrationDocumentSuccessType = {
  type: typeof GET_SCHEMA_MIGRATION_DOCUMENT_SUCCESS;
  payload: {
    migrationDocSchema: SchemaType;
  };
};

const getSchemaMigrationDocumentSuccess = (migrationDocSchema: SchemaType)
  : GetSchemaMigrationDocumentSuccessType => ({
  type: GET_SCHEMA_MIGRATION_DOCUMENT_SUCCESS,
  payload: {
    migrationDocSchema
  }
});

export const getDocumentSchema = (docSchemaName: string):
    ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    DocumentActionsTypes
  > => async (dispatch) => {
  try {
    const correctSchemaName: string = docSchemaName?.split("_")[1] || docSchemaName;

    const response = await DocumentAPI.getSchemaDocument(correctSchemaName);

    switch (docSchemaName) {
      case "inn-schema":
        dispatch(getSchemaInnDocumentSuccess(response.data));
        break;
      case "snils-schema":
        dispatch(getSchemaSnilsDocumentSuccess(response.data));
        break;
      case "bank-schema":
        dispatch(getSchemaBankDocumentSuccess(response.data));
        break;
      case "passport-schema":
        dispatch(getSchemaPassportDocumentSuccess(response.data));
        break;
      case "contract-labor-schema":
      case "contract-civil-schema":
        dispatch(getSchemaContractDocumentSuccess(response.data));
        break;
      case "usn-schema":
        dispatch(getSchemaUsnDocumentSuccess(response.data));
        break;
      case "ndfl-schema":
        dispatch(getSchemaIncomeDocumentSuccess(response.data));
        break;
      case "mdm_founders-decision-schema":
      case "founders-decision-schema":
        dispatch(getSchemaDecisionDocumentSuccess(response.data));
        break;
      case "migration-card-schema":
      case "patent-schema":
        dispatch(getSchemaMigrationDocumentSuccess(response.data));
        break;
      default:
        dispatch(getSchemaDocumentSuccess(response.data));
    }
  } catch (error: any) {
    dispatch(getSchemaDocumentFailure(error.message));
  }
};

// Логика финализации задачи
export type FinalizeTaskSuccessType = {
  type: typeof FINALIZE_TASK_SUCCESS;
  payload: {
    finalizeTaskData: any;
    finalizeTaskStatus: any;
  };
};

const finalizeTaskSuccess = (finalizeTaskData: any, finalizeTaskStatus: any)
  : FinalizeTaskSuccessType => ({
  type: FINALIZE_TASK_SUCCESS,
  payload: {
    finalizeTaskData,
    finalizeTaskStatus,
  }
});

export type FinalizeTaskFailureType = {
  type: typeof FINALIZE_TASK_FAILURE;
  payload: {
    error: any;
  };
};

export const finalizeTaskFailure = (error?: any): FinalizeTaskFailureType => ({
  type: FINALIZE_TASK_FAILURE,
  payload: {
    error
  }
});

export const finalizeClientTask = (task_uuid: string):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    DocumentActionsTypes
  > => async (dispatch) => {
  try {
    const response = await DocumentAPI.finalizeTask(task_uuid);

    dispatch(finalizeTaskSuccess(response.data, response.status));
  } catch (error: any) {
    dispatch(finalizeTaskFailure(error.response.data.error));
  }
};

// Логика получения выписки подозрительных операций
export type GetBankStatementSuccessType = {
  type: typeof GET_BANK_STATEMENT_SUCCESS;
  payload: {
    bankStatementData: BankStatementDataType[];
  };
};

const getBankStatementSuccess = (bankStatementData: BankStatementDataType[])
  : GetBankStatementSuccessType => ({
  type: GET_BANK_STATEMENT_SUCCESS,
  payload: {
    bankStatementData
  }
});

export type GetBankStatementFailureType = {
  type: typeof GET_BANK_STATEMENT_FAILURE;
  payload: {
    error: any;
  };
};

export const getBankStatementFailure = (error?: any): GetBankStatementFailureType => ({
  type: GET_BANK_STATEMENT_FAILURE,
  payload: {
    error
  }
});

export const getBankStatementData = (statementUuid: string):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    DocumentActionsTypes
  > => async (dispatch) => {
  try {
    const response = await DocumentAPI.getBankStatement(statementUuid);

    dispatch(getBankStatementSuccess(response.data.result));
  } catch (error: any) {
    dispatch(getBankStatementFailure(error.message));
  }
};

// Логика создания новго динамического документа
export type CreateNewDocumentSuccessType = {
  type: typeof CREATE_NEW_DOCUMENT_SUCCESS;
  payload: {
    newDocument: UpdateDocumentType;
  };
};

const createNewDocumentSuccess = (newDocument: UpdateDocumentType)
  : CreateNewDocumentSuccessType => ({
  type: CREATE_NEW_DOCUMENT_SUCCESS,
  payload: {
    newDocument
  }
});

export type CreateNewDocumentFailureType = {
  type: typeof CREATE_NEW_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

export const createNewDocumentFailure = (error?: any): CreateNewDocumentFailureType => ({
  type: CREATE_NEW_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export const createNewDynamicDocument = (schemaSlug: string, data: any):
  ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  DocumentActionsTypes
> => async (dispatch) => {
  try {
    const response = await DocumentAPI.createNewDocument(schemaSlug, data);

    dispatch(createNewDocumentSuccess(response.data));
  } catch (error: any) {
    dispatch(createNewDocumentFailure(error.message));
  }
};

//логика получения пакета выписок
export type GetBankPackagesStatementSuccessType = {
  type: typeof GET_BANK_PACKAGES_STATEMENT_SUCCESS;
  payload: {
    bankPackagesData: BankStatementDataType[];
  };
};

const getBankPackagesStatementSuccess = (bankPackagesData: BankStatementDataType[])
  : GetBankPackagesStatementSuccessType => ({
  type: GET_BANK_PACKAGES_STATEMENT_SUCCESS,
  payload: {
    bankPackagesData
  }
});

export type GetBankPackagesStatementFailureType = {
  type: typeof GET_BANK_PACKAGES_STATEMENT_FAILURE;
  payload: {
    error: any;
  };
};

export const getBankPackagesStatementFailure = (error?: any): GetBankPackagesStatementFailureType => ({
  type: GET_BANK_PACKAGES_STATEMENT_FAILURE,
  payload: {
    error
  }
});

export const getBankPackagesStatementData = (clientProfileUuid: string):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    DocumentActionsTypes
  > => async (dispatch) => {
  try {
    const response = await DocumentAPI.getBankPackagesStatement(clientProfileUuid);

    dispatch(getBankPackagesStatementSuccess(response.data.result));
  } catch (error: any) {
    dispatch(getBankPackagesStatementFailure(error.message));
  }
};

//логика обновления документа
export type UpdateDocumentSuccessType = {
  type: typeof UPDATE_DOCUMENT_SUCCESS;
  payload: {
    updatedDoc: any;
  };
};

const updateDocumentSuccess = (updatedDoc: any)
  : UpdateDocumentSuccessType => ({
  type: UPDATE_DOCUMENT_SUCCESS,
  payload: {
    updatedDoc
  }
});

export type UpdateDocumentFailureType = {
  type: typeof UPDATE_DOCUMENT_FAILURE;
  payload: {
    error: any;
  };
};

export const updateDocumentFailure = (error?: any): UpdateDocumentFailureType => ({
  type: UPDATE_DOCUMENT_FAILURE,
  payload: {
    error
  }
});

export const updateDocumentData = (
  docSchemaName: string,
  uuid: string,
  data: any
): ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    DocumentActionsTypes
  > => async (dispatch) => {
  try {
    const response = await DocumentAPI.updateDocument(docSchemaName, uuid, data);

    dispatch(updateDocumentSuccess(response.data));
  } catch (error: any) {
    dispatch(updateDocumentFailure(error.message));
  }
};

//логика получения списка документов
export type GetDocumentListSuccessType = {
  type: typeof GET_DOCUMENT_LIST_SUCCESS;
  payload: {
    docList: any;
  };
};

const getDocumentListSuccess = (docList: any): GetDocumentListSuccessType => ({
  type: GET_DOCUMENT_LIST_SUCCESS,
  payload: {
    docList
  }
});

export type GetDocumentListFailureType = {
  type: typeof GET_DOCUMENT_LIST_FAILURE;
  payload: {
    error: any;
  };
};

export const getDocumentListFailure = (error?: any): GetDocumentListFailureType => ({
  type: GET_DOCUMENT_LIST_FAILURE,
  payload: {
    error
  }
});

export const getDocuments = (docSchemaName: string):
  ThunkAction<
  Promise<any>,
  AppStateType,
  undefined,
  DocumentActionsTypes
> => async (dispatch) => {
  try {
    const response = await DocumentAPI.getDocumentList(docSchemaName);

    dispatch(getDocumentListSuccess(response.data));
  } catch (error: any) {
    dispatch(getDocumentListFailure(error.message));
  }
};
