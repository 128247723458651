import React, { FC, JSX, useState } from "react";
import { Button, Dropdown, MenuProps, notification } from "antd";
import { ReactComponent as DropDownMenu } from "../../assets/icons/dropdown_icon_v2.svg";
import { DownloadReportDataType } from "app/types";
import { roleResolver } from "../roleResolver";
import { useSelector } from "react-redux";
import { AppStateType } from "../../reducers/mainReducer";
import { downloadReport } from "../../api/reports.api";
import { downloadFile, downloadUrl } from "../downloadFile";
import { CatalogFilterType } from "../catalog";
import * as jose from "jose";

interface IExportListProps {
  variant: string;
}

const ExportList: FC<IExportListProps> = ({
  variant,
}): JSX.Element | boolean => {
  const decodedToken: jose.JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);
  
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const errorTextVariant: CatalogFilterType = {
    "клиенты": "клиентов",
    "задачи": "задач",
  };
  
  //TODO пока моковые данные
  const listingTypeVariant: CatalogFilterType = {
    "клиенты": "client_listing_report",
    "задачи": "task_listing_report",
  };
  
  const {
    isAdmin,
    isTechSupport
  } = roleResolver(decodedToken);
  const hasAccess: boolean = isAdmin || isTechSupport;
  
  const downloadClientsFailure = (): void => {
    notification.error({
      message: `Ошибка скачивания списка ${errorTextVariant[variant]}`
    });
  };
  
  const downloadReportFile = async (): Promise<void> => {
    setIsLoading(true);
    
    const downloadReportData: DownloadReportDataType = {
      document_type: listingTypeVariant[variant],
    };
    
    //TODO будет новая ручка
    await downloadReport(downloadReportData)
      .then((response) => {
        setIsLoading(false);
        downloadFile(response?.data?.file_name, downloadUrl(response?.data?.file_uuid));
      })
      .catch((error) => {
        setIsLoading(false);
        downloadClientsFailure();
        console.error(error.message);
      });
  };
  
  const menuItems: MenuProps["items"] = [
    {
      label: (<div>Экспорт списка клиентов в excel</div>),
      key: "1",
      onClick: () => downloadReportFile(),
    },
  ];
  
  return hasAccess && (
    <Dropdown
      className="h-full p-2"
      menu={{items: menuItems}}
      disabled={isLoading}
    >
      <Button>
        <DropDownMenu />
      </Button>
    </Dropdown>
  );
};

export default ExportList;