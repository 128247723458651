import React, {
  FC,
  JSX,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { useDispatch } from "react-redux";
import { TypicalRequestToClientType, } from "app/types";
import { AppDispatch } from "../../../../../store/store";
import { sendTypicalRequestToClient } from "../../../../../actions/client.actions";
import { deleteUploadFile, uploadFiles } from "../../../../../api/document.api";
import { ReactComponent as AttachFileIcon } from "../../../../../assets/icons/attach_file_icon.svg";
import { ReactComponent as DeleteDocument } from "../../../../../assets/icons/delete_file.svg";
import { appealTopic, } from "../../ClientCardItem/Tasks/contactingToClient";
import { validFileFormat } from "../../../../../utils/ModalUploadItems/validFileFormat";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import { downloadFile, downloadUrl } from "../../../../../utils/downloadFile";
import DraggerForm from "../../../../../utils/ModalUploadItems/DraggerForm/DraggerForm";
import ZeroSearch from "../../../ZeroSearch/ZeroSearch";
import {
  Form,
  Input,
  Select,
  message,
  Skeleton,
  UploadFile,
  UploadProps,
  FormInstance,
} from "antd";
import { UploadChangeParam } from "antd/es/upload";
import css from "../ClientCardActionDrawer.module.css";

interface ICreateAppealProps {
  form: FormInstance;
  clientUuid: string;
  correctSelectWidth: number;
  setDrawerClientAction: Dispatch<SetStateAction<string>>;
}

interface IUploadedFilesProps {
  file_name: string;
  uuid: string;
}

enum FilesList {
  Max = 100,
  Empty = 0,
  MaxFileSize = 50,
}

const { TextArea } = Input;

const CreateAppeal: FC<ICreateAppealProps> = ({
  form,
  clientUuid,
  correctSelectWidth,
  setDrawerClientAction
}): JSX.Element => {
  const [fileCount, setFileCount] = useState<number>(0);
  const [uploadedFilesData, setUploadedFilesData] = useState<IUploadedFilesProps[] | null>(null);
  const [uploadFilesLoader, setUploadFilesLoader] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const values = Form.useWatch([], form);
  const { topic, textArea } = values || {};

  const textAreaLength: number = textArea?.length || 0;

  const showUpload: boolean = fileCount >= FilesList.Max;

  const uploadedFilesList: JSX.Element[] = uploadedFilesData?.map((file: IUploadedFilesProps, index: number) => (
    <div key={index} className={css.documentsUpload}>
      <div className="flex items-center pl-0.5">
        <AttachFileIcon className="flex-shrink-0" />
        <a
          className={css.fileName}
          onClick={() => downloadFile(file?.file_name, downloadUrl(file?.uuid))}
        >
          {file.file_name}
        </a>
      </div>
      <div
        className={css.deleteIcon}
        onClick={(e: React.MouseEvent<HTMLElement>) => handleRemove(e, file)}
      >
        <DeleteDocument className="flex-shrink-0" />
      </div>
    </div>
  )) ?? [];

  const uploadFilesList: JSX.Element[] | null = uploadedFilesData?.length
    ? uploadedFilesList
    : null;

  const onDrawerClose = (): void => {
    setDrawerClientAction("");
    setUploadedFilesData(null);
    setFileCount(0);
  };

  const uploadFile = async (fileList: File[]): Promise<void> => {
    const formData = new FormData();
    fileList.forEach((file: File) => formData.append("files", file));

    setUploadFilesLoader(true);

    try {
      const response = await uploadFiles(formData);

      setUploadedFilesData((prevData: IUploadedFilesProps[] | null = []): IUploadedFilesProps[] => {
        const safePrevData: IUploadedFilesProps[] = prevData || [];

        const uniqueFiles: IUploadedFilesProps[] = response.data.filter(
          (file: IUploadedFilesProps) => !safePrevData.some(({ uuid }: IUploadedFilesProps) => uuid === file.uuid)
        );

        const newData: IUploadedFilesProps[] = [...safePrevData, ...uniqueFiles].slice(0, FilesList.Max);

        setFileCount(newData.length);

        return newData;
      });
    } catch (error) {
      message.error("Ошибка в загрузке файлов!");
    } finally {
      setUploadFilesLoader(false);
    }
  };

  const handleUploadChange = (info: UploadChangeParam): boolean => {
    if (info?.fileList?.length > FilesList.Max) {
      info.fileList = info.fileList.slice(FilesList.Empty, FilesList.Max);
    }

    const allFilesUploading: boolean = info?.fileList?.every(({ status }: UploadFile) => status === "uploading");

    if (allFilesUploading) {
      const isFileSizeValid = (file: UploadFile): boolean => {
        if (!file || !file?.size) return false;

        return file?.size / 1024 / 1024 < FilesList.MaxFileSize;
      };

      const validFiles: UploadFile[] = info?.fileList?.filter(isFileSizeValid);

      if (validFiles?.length < info?.fileList?.length) {
        message.error("Объем файлов или файла не должен превышать 50мб.");
      }

      const filesToUpload = validFiles
        ?.map(({ originFileObj }: UploadFile) => originFileObj)
        ?.filter(Boolean) as File[];

      if (filesToUpload?.length > 0) {
        uploadFile(filesToUpload);
      }
    }

    info.fileList = [];

    return false;
  };

  const counterUpload = (fileCountClarification: number): boolean =>
    fileCountClarification >= FilesList.Max;

  const handleRemove = async (e: React.MouseEvent<HTMLElement>, fileRemove: IUploadedFilesProps): Promise<void> => {
    e.preventDefault();

    try {
      const params = new URLSearchParams({ file_uuid: fileRemove.uuid });

      const response = await deleteUploadFile(params.toString());
      message.success(response.data.message);

      setUploadedFilesData((prevFiles: IUploadedFilesProps[] | null = []): IUploadedFilesProps[] | null => {
        const updatedFiles: IUploadedFilesProps[] =
          prevFiles?.filter(({ uuid }: IUploadedFilesProps) => uuid !== fileRemove.uuid) ?? [];

        setFileCount(updatedFiles.length);

        return updatedFiles.length > 0 ? updatedFiles : null;
      });
    } catch (error) {
      console.error("Remove file error:", error);
      message.error("Ошибка при удалении файла!");
    }
  };

  const onCreateAppealFinish = (): void => {
    const uuids: string[] = uploadedFilesData?.map(({ uuid }) => uuid) ?? [];

    const appealRequest: TypicalRequestToClientType = {
      client: clientUuid,
      category_code: 302,
      task_content: textArea || null,
      task_topic: topic || null,
      files: uuids,
    };

    dispatch(sendTypicalRequestToClient(appealRequest, true));
    onDrawerClose();
  };

  const renderFilesListOrSkeleton: () => JSX.Element | JSX.Element[] | null = () => uploadFilesLoader ? (
    <Skeleton
      active
      title={false}
      paragraph={{
        rows: 1,
        width: "100%"
      }}
    />
  ) : uploadFilesList;

  const propsFile: UploadProps = {
    name: "file",
    multiple: true,
    customRequest: (): boolean => true,
    accept: validFileFormat,
    onChange: handleUploadChange,
    showUploadList: false
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onCreateAppealFinish}
    >
      <div className={css.modalText}>
        Задача по выбранной теме обращения будет назначена на ответственного бухгалтера.
      </div>
      <Form.Item
        label="Тема обращения"
        name="topic"
        className="mt-0 mb-4"
        rules={[
          {
            required: true,
            message: "Пожалуйста, выберите тему обращения"
          }
        ]}
        colon={false}
      >
        <Select
          placeholder="Выберите тему обращения"
          options={appealTopic}
          listHeight={196}
          showSearch
          allowClear
          size="large"
          style={{ width: `${correctSelectWidth}px` }}
          notFoundContent={<ZeroSearch dataFilter />}
        />
      </Form.Item>
      <div className={css.modalText}>
        Опишите вопрос или задачу от клиента для лучшего понимания бухгалтером.
        Вы так же можете прикрепить файлы к задаче, если это необходимо.
      </div>
      <Form.Item
        label="Комментарий"
        name="textArea"
        className="mt-0 mb-0"
        rules={[{
          required: true,
          message: "Пожалуйста, введите комментарий"
        }]}
        colon={false}
        layout="vertical"
      >
        <TextArea
          rows={7}
          maxLength={2000}
          className="text-base font-normal leading-5 text-gray-900"
          placeholder="Ваш комментарий клиенту"
        />
      </Form.Item>
      <div className="mb-4 text-xs font-normal leading-100 text-gray-700 text-right">{textAreaLength}/2000</div>
      {!showUpload && <DraggerForm props={{ ...propsFile }} fileCount={fileCount} counterUpload={counterUpload} />}
      <div className={`${css.fileListing} grid justify-items-start items-center`}>
        {renderFilesListOrSkeleton()}
      </div>
      <Form.Item className="mt-2 text-right">
        <ButtonCustom
          className="mr-2"
          size="large"
          type="default"
          ghost
          text="Отменить"
          onClick={onDrawerClose}
        />
        <ButtonCustom
          size="large"
          type="primary"
          text="Подтвердить"
          htmlType="submit"
          disabled={!textArea?.length || !topic}
        />
      </Form.Item>
    </Form>
  );
};

export default CreateAppeal;
