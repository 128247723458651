enum NameParts {
  FullName = 3,
}

export const reformatName = (fullName: string, hasActionHistory: boolean = true): string => {
  const name: string[] = fullName ? fullName.split(" ") : [];

  if (name.length === NameParts.FullName) {
    const correctFullName: string[] = [name[2], name[0], name[1]];

    return correctFullName.join(" ");
  } else {

    return hasActionHistory ? fullName : "Ответ от клиента";
  }
};
