export const parseStatusText = (text?: string): string => {
  const statusMap: { [key: string]: string } = {
    "NEW": "Новая",
    "SEARCH_RESPONSIBLE": "Поиск исполнителя",
    "BOOKED": "Назначена",
    "IN_PROGRESS": "В работе",
    "PAUSE": "Приостановлена",
    "FIN_TRUE": "Исполнена",
    "FIN_FALSE": "Не исполнена",
    "1C_SYNC": "Синхронизация с 1С",
    "1C_SUCCESS": "Отправлено в 1С",
    "1C_ERROR": "Ошибка 1С",
    "CLOSED_BY_FNS_SUCCESS": "Принято ФНС",
    "SENT_TO_FNS": "Отправлено в ФНС",
    "FLK_CHECK_SUCCESS": "Пройден ФЛК",
    "FLK_CHECK_ERROR": "Ошибка ФЛК",
    "CLIENT_CONFIRM_ERROR": "Отказ клиента",
    "FNS_CHECK_ERROR": "Отказ ФНС",
    "FNS_ACCEPTED_TO_CHECK_ERROR": "Отказ ФНС",
    "FNS_ACCEPT_TO_CHECK_ERROR": "Отказ ФНС",
};

  return text ? statusMap[text] || "" : "";
};
