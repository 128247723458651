import { FC, JSX, } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as ErrorIcon } from "../../../../../../assets/icons/status_error.svg";
import { ReactComponent as SuccessIcon } from "../../../../../../assets/icons/check_circle_icon_green.svg";

interface IFetchDeclarationFilesStatusesProps {
  isLoading?: boolean;
  isFetched?: boolean;
  hasFiles?: boolean;
}

const FetchDeclarationFilesStatuses: FC<IFetchDeclarationFilesStatusesProps> = ({
  isLoading,
  isFetched,
  hasFiles,
}): JSX.Element => {

  return (
    <span className="flex items-center mb-4 py-2 px-3 rounded-lg bg-[#F7F8F9]">
      {isLoading && (
        <>
          <Spin indicator={<LoadingOutlined spin />} className="mr-2" /> Пожалуйста, подождите, мы получаем файлы из 1С
        </>
      )}
      {isFetched && (
        hasFiles ? (
          <>
            <SuccessIcon className="w-6 h-6 mr-2" /> Все файлы успешно загружены из 1С
          </>
        ) : (
          <>
            <ErrorIcon className="w-6 h-6 mr-2" />
            Не удалось получить файлы из 1С, пожалуйста, повторите обработку или приложите документы вручную
          </>
        )
      )}
    </span>
  );
};

export default FetchDeclarationFilesStatuses;
