import React, {
  FC,
  JSX,
  Key,
  Dispatch,
  useEffect,
  SetStateAction,
} from "react";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import {
  Modal,
  notification,
} from "antd";
import { createAlphaDocStatus } from "../../../../utils/createAlphaDocStatus";
import * as DocumentAPI from "../../../../api/document.api";
import css from "../ProfileAbsenteesList.module.css";

interface IProfileAbsenteesRemoveModalProps {
  absenteesKey: Key | null;
  setAbsenteesKey: Dispatch<SetStateAction<Key | null>>;
  removeModalOpen: boolean;
  setRemoveModalOpen: Dispatch<SetStateAction<boolean>>;
  deleteAbsentStatus: number | null;
  setDeleteAbsentStatus: Dispatch<SetStateAction<number | null>>;
}

const DELETE_PARAMS = {
  strategy_type: "delete",
};

const ProfileAbsenteesRemoveModal: FC<IProfileAbsenteesRemoveModalProps> = ({
  absenteesKey,
  setAbsenteesKey,
  removeModalOpen,
  setRemoveModalOpen,
  deleteAbsentStatus,
  setDeleteAbsentStatus,
}): JSX.Element => {
  useEffect((): void => {
    createAlphaDocStatus(
      deleteAbsentStatus,
      deleteAbsentSuccess,
      deleteAbsentError,
    );
  }, [deleteAbsentStatus]);

  const deleteAbsentSuccess = (): void => {
    notification.success({
      message: "Запись отсутствия успешно удалена",
    });

    setAbsenteesKey(null);
    setDeleteAbsentStatus(0);
    closeRemoveAbsenteesModal();
  };

  const deleteAbsentError = (): void => {
    notification.error({
      message: "Ошибка удаления записи отсутствия",
    });

    setDeleteAbsentStatus(0);
  };

  const closeRemoveAbsenteesModal = (): void => {
    setRemoveModalOpen(false);
    setAbsenteesKey(null);
  };

  const removeAbsenteesModalOk = (): void => {
    if (absenteesKey) {
      DocumentAPI.deleteDocument("absence-substitution-schema", String(absenteesKey), DELETE_PARAMS)
      .then(({ status }) => setDeleteAbsentStatus(status))
      .catch((error) => {
        setDeleteAbsentStatus(error.response.status);

        console.error("delete absent error:", error.response);
      });
    }
  };

  const removeModalTitle: JSX.Element = (
    <div className={css.modalRemoveTitle}>
      Удаление записи об отсутствии
    </div>
  );

  const removeAbsenteesModalFooter: JSX.Element = (
    <>
      <ButtonCustom
        className={`${css.buttonBack} mt-4 mr-2`}
        key="cancel"
        size="large"
        type="default"
        text="Отменить"
        onClick={closeRemoveAbsenteesModal}
      />
      <ButtonCustom
        key="ok"
        size="large"
        text="Подтвердить"
        type="primary"
        onClick={removeAbsenteesModalOk}
      />
    </>
  );

  return (
    <Modal
      title={removeModalTitle}
      open={removeModalOpen}
      onOk={removeAbsenteesModalOk}
      onCancel={closeRemoveAbsenteesModal}
      footer={removeAbsenteesModalFooter}
    >
      <p className={css.modalRemoveText}>
        Вы уверены, что хотите удалить запись об отсутствии?
      </p>
    </Modal>
  );
};

export default ProfileAbsenteesRemoveModal;
