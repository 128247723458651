import React, { FC, JSX, useState } from "react";
import css from "./AboutClient.module.css";
import { DocumentClientType } from "app/types";
import LoadingCustom from "../../../../ui-kit/LoadingCustom/LoadingCustom";
import ClientModal from "./ClientModal/ClientModal";
import ChangeResponsibleModal from "../../../../../utils/ChangeResponsibleModal/ChangeResponsibleModal";
import DraftWarning from "../../../../../utils/DraftWarning/DraftWarning";
import OrganizationInfo from "./OrganizationInfo/OrganizationInfo";
import TariffInfo from "./TariffInfo/TariffInfo";
import WageRegulationsInfo from "./WageRegulationsInfo/WageRegulationsInfo";
import ClientContactInfo from "./ClientContactInfo/ClientContactInfo";
import ResponsibleInfo from "./ResponsibleInfo/ResponsibleInfo";

interface IAboutClientProps {
  clientInfo: DocumentClientType | null;
  isClientLoaded: boolean;
  showAction: boolean;
}

const AboutClient: FC<IAboutClientProps> = ({
  clientInfo,
  isClientLoaded,
  showAction,
}): JSX.Element => {
  const [isChangeResponsibleModalOpen, setChangeResponsibleModalOpen] = useState<boolean>(false);
  const [isTemporaryResponsible, setTemporaryResponsible] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalVariant, setIsModalVariant] = useState<string>("");
  
  const isDraftClient: boolean = clientInfo?.fields?.status_client === "DRAFT";

  const showModal = (title: string): void => {
    setIsModalVariant(title);
    setIsModalOpen(true);
  };
  
  const responsibleUuid: string[] = [
    clientInfo?.fields?.responsible_reference?.[0]?.uuid ?? "",
    clientInfo?.fields?.temporary_responsible_reference?.[0]?.uuid ?? ""
  ].filter(Boolean);

  return (
    isClientLoaded ? (
      <>
        <div className={`${css.cardsContent} mb-3`}>
          <DraftWarning />
          <OrganizationInfo />
          <TariffInfo />
          <div className={`flex ${showAction ? "flex-wrap" : ""}`}>
            <WageRegulationsInfo
              showModal={showModal}
              showAction={showAction}
            />
            <ClientContactInfo showModal={showModal} />
          </div>
          {!isDraftClient && (
            <ResponsibleInfo
              setChangeResponsibleModalOpen={setChangeResponsibleModalOpen}
              setTemporaryResponsible={setTemporaryResponsible}
            />
          )}
        </div>
        <ClientModal
          clientInfo={clientInfo}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          title={isModalVariant}
        />
        {isChangeResponsibleModalOpen && (
          <ChangeResponsibleModal
            docSchema="client"
            clientInfo={clientInfo}
            isModalOpen={isChangeResponsibleModalOpen}
            setIsModalOpen={setChangeResponsibleModalOpen}
            responsibleUuid={responsibleUuid}
            isTemporaryResponsible={isTemporaryResponsible}
            setTemporaryResponsible={setTemporaryResponsible}
          />
        )}
      </>
    ) : (
      <div className="text-center mt-96">
        <LoadingCustom/>
      </div>
    )
  );
};

export default AboutClient;
