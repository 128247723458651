import React, {
  FC,
  JSX,
  Dispatch,
  SetStateAction,
} from "react";
import { UploadedFilesPropsType } from "app/types";
import { uploadFiles } from "../../../../../../../api/document.api";
import { validFileFormat } from "../../../../../../../utils/ModalUploadItems/validFileFormat";
import DraggerForm from "../../../../../../../utils/ModalUploadItems/DraggerForm/DraggerForm";
import { ReactComponent as SuccessIcon } from "../../../../../../../assets/icons/warning_blue_icon.svg";
import { UploadChangeParam } from "antd/es/upload";
import { message, UploadFile, UploadProps } from "antd";
import css from "./DrawerDragAndDrop.module.css";

interface IDrawerDragAndDropProps {
  uploadedFilesList: JSX.Element[] | null;
  setDragAndDropFiles: Dispatch<SetStateAction<UploadedFilesPropsType[]>>;
  isSuccessBlockHidden?: boolean;
  dragAndDropFilesCount: number;
  setDragAndDropFilesCount: Dispatch<SetStateAction<number>>;
}

enum FileListSize {
  Zero = 0,
  Max = 100,
}

const maxSizeFile: number = 50;

const DrawerDragAndDrop: FC<IDrawerDragAndDropProps> = ({
  uploadedFilesList,
  setDragAndDropFiles,
  isSuccessBlockHidden,
  dragAndDropFilesCount,
  setDragAndDropFilesCount,
}): JSX.Element => {
  const dragAndDropFilesCounter = (filesCount: number): boolean => filesCount >= FileListSize.Max;

  const onUploadFileChange = (info: UploadChangeParam): boolean => {
    const fileList: UploadFile[] = info.fileList;

    if (fileList.length > FileListSize.Max) {
      fileList.splice(FileListSize.Max);
    }

    const allFilesUploading: boolean = fileList.every(({ status }) => status === "uploading");

    if (allFilesUploading) {
      const filteredFiles: File[] = [];

      for (const file of fileList) {
        const fileSize: number = (file && file.size && (file.size / 1024 / 1024)) ?? 0;

        if (fileSize < maxSizeFile) {
          filteredFiles.push(file.originFileObj as File);
        }
      }

      if (filteredFiles.length < fileList.length) {
        message.error("Объем файлов или файла не должен превышать 50мб.");
      }

      if (filteredFiles.length > FileListSize.Zero) {
        uploadDragAndDropFile(filteredFiles);
      }
    }

    fileList.length = FileListSize.Zero;
    return false;
  };

  const uploadDragAndDropFile = async (fileList: File[]): Promise<void> => {
    const formData = new FormData();
    fileList.forEach((file: File) => formData.append("files", file));

    try {
      const response = await uploadFiles(formData);

      setDragAndDropFiles((prevData: UploadedFilesPropsType[] = []) => {
        const newData: UploadedFilesPropsType[] = [...prevData];
        const newFileCount: number = new Set([
          ...prevData.map(({ uuid }) => uuid),
          ...response.data.map(({ uuid } : { uuid: string }) => uuid)
        ]).size;

        response.data.forEach((file: UploadedFilesPropsType) => {
          if (!newData.some(({ uuid }) => uuid === file.uuid)) {
            newData.push(file);
          }
        });

        if (newData.length > FileListSize.Max) {
          newData.length = FileListSize.Max;
        }

        setDragAndDropFilesCount(Math.min(newFileCount, FileListSize.Max));

        return newData;
      });

      if (response?.status === 200) {
        message.success("Файл загружен успешно!");
      }
    } catch (error) {
      message.error("Ошибка в загрузке файлов!");
    }
  };

  const uploadDragAndDropProps: UploadProps = {
    name: "file",
    multiple: true,
    customRequest: (): boolean => true,
    accept: validFileFormat,
    onChange: onUploadFileChange,
    showUploadList: false
  };

  const renderSuccessfulRequestBlock = (): JSX.Element => (
    <div className="flex items-center mb-4 mt-4">
      <SuccessIcon className="mr-2" />
      <div >Запрос обработан успешно. Пожалуйста, подтвердите обработку задачи</div>
    </div>
  );

  return (
    <>
      {!isSuccessBlockHidden && renderSuccessfulRequestBlock()}
      <div className="description-base search-color mb-4">Прикрепите файлы, если это необходимо</div>
      <DraggerForm
        props={{ ...uploadDragAndDropProps }}
        fileCount={dragAndDropFilesCount}
        counterUpload={dragAndDropFilesCounter}
      />
      <div className={`${css.clarificationFilesList} grid justify-items-start items-center`}>
        {uploadedFilesList}
      </div>
    </>
  );
};

export default DrawerDragAndDrop;
