import React, {
  FC,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import {
  Form,
  Input,
  Radio,
  Select,
  Divider,
  Tooltip,
  FormInstance,
  RadioChangeEvent,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import {
  getEmployeeDocumentData,
  getDocumentSchema,
} from "../../../../actions/document.actions";
import { AppStateType } from "../../../../reducers/mainReducer";
import { DocumentClientProfileType, UpdateDocumentFilesType } from "app/types";
import { getUnAgreedDocumentType } from "../../../../actions/clientProfile.actions";
import { isDocRequiredValuesFilled } from "../../../../utils/isDocRequriedValuesFiiled";
import { isFormItemRequiredRule } from "../../../../utils/isFormItemRequiredRule";
import { validateTriggersArr } from "../ClientProfileCardImageViewer";
import css from "./../ClientProfileCardImageViewer.module.css";
import { JWTPayload } from "jose";
import { roleResolver } from "../../../../utils/roleResolver";

interface IClientProfileCardImageViewerFormSnilsProps {
  snilsForm: FormInstance;
  closeForm: () => void;
  documentUuid: string;
  setCanSaveDoc: React.Dispatch<SetStateAction<boolean>>;
  onApproveChange: (e: RadioChangeEvent | string) => void;
  docApproveStatus: RadioChangeEvent | string;
  onFormValuesChange: () => void;
  setDocFilesLinks: React.Dispatch<SetStateAction<string[]>>;
  setTotalDocFiles: React.Dispatch<SetStateAction<number>>;
  isClientEmployee?: boolean;
  isValidationCompleted?: boolean;
  isNotAgreedDocs?: boolean;
  isEmployee?: boolean;
}

const ClientProfileCardImageViewerFormSnils: FC<IClientProfileCardImageViewerFormSnilsProps> = ({
  snilsForm,
  closeForm,
  documentUuid,
  setCanSaveDoc,
  onApproveChange,
  docApproveStatus,
  onFormValuesChange,
  setDocFilesLinks,
  setTotalDocFiles,
  isClientEmployee,
  isValidationCompleted,
  isNotAgreedDocs,
  isEmployee
}) => {
  const [isSubmitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(false);
  const [isDocApprovalDisabled, setDocApprovalDisabled] = useState<boolean>(false);
  const [isDocVerified, setDocVerified] = useState<boolean>(false);
  const [snilsBlur, setSnilsBlur] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const documentClientProfile: DocumentClientProfileType | null = useSelector(
    (state: AppStateType) => state.clientProfile.documentClientProfile
  );

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const {
    snilsDoc,
    snilsDocSchema
  } = useSelector((state: AppStateType) => state.document);

  useEffect(() => {
    setDocVerified((!!docApproveStatus && isDocApprovalDisabled) || isContactCenter);
  }, [docApproveStatus, isDocApprovalDisabled]);

  useEffect(() => {
    isDocRequiredValuesFilled(snilsDocSchema, snilsForm, setDocApprovalDisabled);
  }, [snilsForm, snilsDocSchema, onFormValuesChange]);

  useEffect(() => {
    if (docApproveStatus === "DECLINED") {
      const docMigrationDeclineReason: string = snilsDoc?.fields?.decline_reason ?? "";

      snilsForm.setFieldValue(
        "declineReason" as any,
        docMigrationDeclineReason || "Необходимо приложить верный документ (приложен не тот документ)"
      );
    }

    onFormValuesChange();
  }, [docApproveStatus, snilsDoc, snilsForm]);

  useEffect(() => {
    const documentStatus: string = snilsDoc?.fields?.document_status ?? "";
    const documentFiles: string[] = snilsDoc?.fields?.files?.map((file: UpdateDocumentFilesType) => file?.uuid) || [];

    onApproveChange(documentStatus);
    setDocFilesLinks(documentFiles);
    setTotalDocFiles(documentFiles?.length);
  }, [documentClientProfile, snilsDoc]);

  useEffect(() => {
    if (!isNotAgreedDocs && documentUuid) {
      dispatch(getDocumentSchema("snils-schema"));
      dispatch(getEmployeeDocumentData("snils-schema", documentUuid));
    }
  }, [documentUuid, isNotAgreedDocs]);

  useEffect(() => {
    const docSnilsNumber: string = snilsDoc?.fields?.snils_number ?? "";
    const docSnilsStatus: string = snilsDoc?.fields?.document_status ?? "";
    const docSnilsDeclineReason: string = snilsDoc?.fields?.decline_reason ?? "";
    const shouldUpdateDocField: boolean = !!docSnilsNumber || !!docSnilsStatus;

    if (shouldUpdateDocField) {
      handleSnilsChange(docSnilsNumber);
      onApproveChange(docSnilsStatus);

      snilsForm.setFieldValue("isApproved", docSnilsStatus);
    }

    if (docSnilsDeclineReason) {
      snilsForm.setFieldValue("declineReason", docSnilsDeclineReason);
    }
  }, [snilsDoc, snilsForm]);

  const handleSnilsChange = (event: React.ChangeEvent<HTMLInputElement> | string): void => {
    const inputValue: string = typeof event !== "string" 
      ? event.target.value.replace(/\D/g, "")
      : event;
    const matches: RegExpMatchArray | null = inputValue.match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
    const formattedSnils: string = matches
      ? matches.slice(1, 4).filter(Boolean).join("-") + (matches[4] ? ` ${matches[4]}` : "")
      : "";

    setSubmitBtnDisabled(!(formattedSnils?.length === 14 || formattedSnils?.length === 0));

    snilsForm.setFieldValue("snils_number", formattedSnils);
  };

  const isContactCenter: boolean = roleResolver(decodedToken).isContactCenter;
  const isSnilsFormDisabled: boolean | undefined = isContactCenter || isClientEmployee || isValidationCompleted;
  
  const handleValidateStatus: "error" | "" =
    snilsForm.getFieldValue("snils_number" as any)?.replace(/[^\d]/g, "")?.length === 11 ? "" : "error";

  const handleHelp: null | string = snilsForm.getFieldValue("snils_number" as any) ? null : "Только цифры";
  
  const correctStrategy = (value: string): number => value?.replace(/[^\d]/g, "")?.length ?? 0;
  
  const handleBlur = (): void => {
    setSnilsBlur(true);
  };
  
  const handleSave = (): void => {
    setCanSaveDoc(true);
    dispatch(getUnAgreedDocumentType(true));
  };

  return (
    <div>
      <div className={`${css.formStyle} w-[340px]`}>
        <h2 className={`${css.modalText} mb-2`}>
          {`СНИЛС ${isEmployee ? "сотрудника" : "руководителя"}`}
        </h2>
        {!isSnilsFormDisabled && (
          <p className="mb-5 gray-color w-full">
            После проверки правильности документа
            <br />
            заполните соответствующие данные
            <br />и подтвердите его согласованность.
          </p>
        )}
        <Form
          form={snilsForm}
          name="snilsForm"
          onChange={onFormValuesChange}
          onClick={onFormValuesChange}
          validateTrigger={validateTriggersArr}
          layout="vertical"
        >
          <Form.Item
            name="snils_number"
            label="СНИЛС"
            rules={[
              {
                max: 15,
                message: "Значение должно быть 11 цифр."
              },
              {
                min: 14,
                message: "Значение должно быть 11 цифр."
              },
              {
                required: isFormItemRequiredRule("snils_number", snilsDocSchema),
                message: ""
              }
            ]}
            validateTrigger={validateTriggersArr}
            validateStatus={snilsBlur ? handleValidateStatus : ""}
            help={snilsBlur ? handleHelp : null}
          >
            <Input
              size="large"
              showCount
              count={{
                max: 11,
                strategy: (value: string): number => correctStrategy(value),
              }}
              placeholder="СНИЛС"
              onChange={handleSnilsChange}
              readOnly={isSnilsFormDisabled}
              onBlur={handleBlur}
            />
          </Form.Item>
          {!isSnilsFormDisabled && (
            <>
              <Divider className="mt-3" />
              <div className={`mb-2 ${css.modalText}`}>Согласование</div>
              <p className="mb-5 gray-color w-fit">
                Подтвердите согласование документа. При
                <br />
                наличии ошибок или проблем с файлом
                <br />
                отклоните согласование, указав причину.
              </p>
              <div className="flex justify-between">
                <Tooltip
                  title={
                    isDocApprovalDisabled ? "Для согласования документа необходимо заполнить обязательные поля" : ""
                  }
                >
                  <Form.Item name="isApproved">
                    <Radio.Group
                      onChange={onApproveChange}
                      value={docApproveStatus}
                      disabled={isDocApprovalDisabled}
                      className="flex flex-col gap-2"
                    >
                      <Radio value="APPROVED">Согласовать</Radio>
                      <Radio value="DECLINED">Отклонить</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Tooltip>
              </div>
              <div>
                {docApproveStatus === "DECLINED" && (
                  <Form.Item
                    name="declineReason"
                    label="Причина"
                    initialValue="Необходимо приложить верный документ (приложен не тот документ)"
                  >
                    <Select
                      placeholder="Причина"
                      size="large"
                      options={[
                        {
                          value: "Необходимо приложить верный документ (приложен не тот документ)",
                          label: "Необходимо приложить верный документ (приложен не тот документ)"
                        },
                        {
                          value: "Плохое качество документа, нечитаемый скан",
                          label: "Плохое качество документа, нечитаемый скан"
                        }
                      ]}
                    />
                  </Form.Item>
                )}
              </div>
            </>
          )}
        </Form>
      </div>
      <Divider className="mt-0" />
      <div className="flex mx-6 my-4 gap-2">
        <ButtonCustom
          id="close"
          className={css.buttonBack}
          type="default"
          onClick={closeForm}
          text={isSnilsFormDisabled ? "Закрыть" : "Отменить"}
          size="large"
        />
        {!isSnilsFormDisabled && (
          <ButtonCustom
            id="save"
            key="submit"
            className={css.buttonOk}
            type="primary"
            onClick={handleSave}
            text="Сохранить"
            size="large"
            disabled={isDocVerified || isSubmitBtnDisabled}
          />
        )}
      </div>
    </div>
  );
};

export default ClientProfileCardImageViewerFormSnils;
