import React, {
  JSX,
  FC,
  Dispatch,
  SetStateAction,
  useEffect
} from "react";
import css from "./ClientCardActionDrawer.module.css";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close_icon.svg";
import { IContactingToClientProps, topicOfAppeal } from "../ClientCardItem/Tasks/contactingToClient";
import { Form } from "antd";
import InformationToClient from "./ClientCardDrawerActions/InformationToClient";
import PaidConsultation from "./ClientCardDrawerActions/PaidConsultation";
import TariffIncrease from "./ClientCardDrawerActions/TariffIncrease";
import TerminateService from "./ClientCardDrawerActions/TerminateService";
import { setPopoverTariffType } from "../../../../actions/client.actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import CreateAppeal from "./ClientCardDrawerActions/CreateAppeal";

interface IClientCardActionDrawerProps {
  clientUuid: string;
  drawerClientAction: string;
  setDrawerClientAction: Dispatch<SetStateAction<string>>;
  correctSelectWidth: number;
}

enum TopicKey {
  Lower200 = 200,
  Upper200 = 199,
  Lower300 = 300,
}

const ClientCardActionDrawer: FC<IClientCardActionDrawerProps> = ({
  clientUuid,
  drawerClientAction,
  setDrawerClientAction,
  correctSelectWidth
}): JSX.Element => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  
  const isDrawerActionValid: boolean = !!drawerClientAction && drawerClientAction !== "Списание платной консультации";

  useEffect(() => {
    form.resetFields();
    dispatch(setPopoverTariffType(false));
  }, [drawerClientAction]);

  const onDrawerClose = (): void => {
    setDrawerClientAction("");
  };

  const filteredTopic = (): IContactingToClientProps[] => {
    //TODO: Временное решение, потом выпилить строгую проверку на 112 код
    const filterTopicHundredth: IContactingToClientProps[] =
      topicOfAppeal?.filter(({ key }: { key: number }): boolean =>
        key < TopicKey.Lower200 && key !== 112);

    //TODO: Временное решение, потом выпилить строгую проверку на 112 код
    const filterTopicTwoHundredth: IContactingToClientProps[] =
      topicOfAppeal?.filter(({ key }: { key: number }): boolean =>
        key > TopicKey.Upper200 && key < TopicKey.Lower300 || key === 112);

    switch (drawerClientAction) {
      case "Запрос информации":
        return filterTopicHundredth;
      case "Предоставление информации":
        return filterTopicTwoHundredth;
      default:
        return [];
    }
  };

  const renderDrawerActions = (): JSX.Element => {
    switch (drawerClientAction) {
      case "Запрос информации":
      case "Предоставление информации":
        return (
          <InformationToClient
            form={form}
            filteredTopic={filteredTopic()}
            drawerClientAction={drawerClientAction}
            setDrawerClientAction={setDrawerClientAction}
            correctSelectWidth={correctSelectWidth}
          />
        );
      case "Заявка на повышение тарифа":
        return (
          <TariffIncrease
            form={form}
            onDrawerClose={onDrawerClose}
          />
        );
      case "Заявка на прекращение обслуживания":
        return (
          <TerminateService
            form={form}
            onDrawerClose={onDrawerClose}
          />
        );
      case "Создать обращение":
        return (
          <CreateAppeal
            form={form}
            clientUuid={clientUuid}
            setDrawerClientAction={setDrawerClientAction}
            correctSelectWidth={correctSelectWidth}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {isDrawerActionValid && (
        <div className={css.actionDrawerWrap}>
          <div className="flex items-center mb-4">
            <ButtonCustom
              className="mr-4 p-0 gap-0"
              type="link"
              text=""
              size="small"
              onClick={onDrawerClose}
            >
              <CloseIcon />
            </ButtonCustom>
            <h3 className={css.actionDrawerHeader}>{drawerClientAction}</h3>
          </div>
          {renderDrawerActions()}
        </div>
      )}
      <PaidConsultation
        drawerClientAction={drawerClientAction}
        setDrawerClientAction={setDrawerClientAction}
      />
    </>
  );
};

export default ClientCardActionDrawer;
