import React, {
  FC,
  JSX,
  useRef,
  Dispatch,
  useState,
  SetStateAction,
} from "react";
import { Card, Tooltip } from "antd";
import DeadLine from "../../../../utils/DeadLine";
import {
  ActionHistoryFileType,
  DocumentSchemaLabelMapType,
  DocumentTaskFieldsType,
} from "app/types";
import { downloadFile } from "../../../../utils/downloadFile";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import { ReactComponent as AttachFileIcon } from "../../../../assets/icons/attach_file_icon.svg";
import { ReactComponent as HelpIcon } from "../../../../assets/icons/help.svg";
import dayjs from "dayjs";
import { reformatName } from "../../../../utils/reformatName";
import { documentSchemaLabelsMapper } from "../../../../utils/documentSchemaLabelsMapper";
import { parseStatus } from "../../../../utils/parseStatus";
import TaskCardHistoryStepBlock from "./TaskCardHistoryStepBlock/TaskCardHistoryStepBlock";
import css from "./TaskCardInfo.module.css";
import {
  declarationTopic,
  ITaskTypeProps
} from "../../../ClientsList/ClientCard/ClientCardItem/Tasks/contactingToClient";
import { declarationLabel } from "./utils/declarationLabel";

interface ITaskCardInfoProps {
  currentStep: number | undefined;
  isAdministrator: boolean | undefined;
  setNavBarHidden: Dispatch<SetStateAction<boolean>>;
  isCheckAccess: boolean;
  drawerTaskAction: string;
  isConfirmModalOpen: boolean;
  isHistoryStepsBlockLoading: boolean;
}

const TaskCardInfo: FC<ITaskCardInfoProps> = ({
  currentStep,
  isAdministrator,
  setNavBarHidden,
  isCheckAccess,
  drawerTaskAction,
  isConfirmModalOpen,
  isHistoryStepsBlockLoading,
}): JSX.Element => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const refTaskInfo: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const refTaskContent: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const refClientInfo: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const {
    documentTask,
    schemaTaskData,
  } = useSelector((state: AppStateType) => state.tasks);
  
  const {
    files,
    main_files,
    task_type,
    client_profile_ooo,
    client_profile_ip,
    uuid,
    topic,
    task_content,
    client,
    inn,
    support_code,
    support_topic,
    deadline,
    status_task,
    responsible_reference,
    task_author,
    task_reason,
    priority,
    declaration_type
  } = documentTask?.fields || {};

  const {
    schemaClient,
    clientTariffData,
  } = useSelector((state: AppStateType) => state.client);

  const taskLabels: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(schemaTaskData);
  const clientLabel: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(schemaClient);

  const attachmentsFiles: boolean | undefined = files && files?.length > 0;
  const taskType: string | undefined = task_type?.[0]?.label;
  const isTaskTerminationService: boolean = taskType === "Прекращение обслуживания";

  const mainFiles: boolean | undefined = main_files && main_files?.length > 0;

  const cashTransactionsTask: boolean = task_type?.[0]?.label === "Наличные операции";

  //TODO: Временная константа для обратной совместимости модалок
  //TODO: Удалить как полностью перейдем на новую
  const isActionSelected: string | boolean = !drawerTaskAction || (drawerTaskAction && isConfirmModalOpen);

  const taskCardInfoWrap: string = isActionSelected ? css.cardsContent : "block w-full";
  const taskCardInfoBlock: string = !drawerTaskAction ? css.card : "";

  const contentTooltip: JSX.Element = (
    <div className={css.tooltip}>
      {!!clientTariffData?.name &&
        <div>{clientLabel?.["client_tariff"]?.label}:{" "}{clientTariffData?.name}</div>
      }
      {!!clientTariffData?.online_desk_qty &&
        <div>{clientLabel?.["online_desk_qty"]?.label}:{" "}{clientTariffData?.online_desk_qty}</div>
      }
      {!!clientTariffData?.agency_contracts_qty &&
        <div>{clientLabel?.["agency_agreements_qty"]?.label}:{" "}{clientTariffData?.agency_contracts_qty}</div>
      }
      {!!clientTariffData?.account_recovery_months &&
        <div>
          {clientLabel?.["accounting_restore_months"]?.label}:{" "}{clientTariffData?.account_recovery_months} мес
        </div>
      }
    </div>
  );

  const renderClientLabel: string | undefined = client_profile_ooo?.length
    ? client_profile_ooo?.[0]?.label
    : client_profile_ip?.[0]?.label;

  const isTaskContentHidden: boolean = !!topic ||
    !!mainFiles ||
    !!attachmentsFiles ||
    !!task_content;

  const actionHistoryHeight: number = (refTaskInfo?.current?.clientHeight ?? 0) +
    (isTaskContentHidden ? (refTaskContent?.current?.clientHeight ?? 0) : -12) +
    (refClientInfo?.current?.clientHeight ?? 0);

  const renderTaskContent = (label: string | undefined, text: string | undefined): JSX.Element => (
    <div className="flex">
      <div className={css.taskInfoLabel}>{label}</div>
      <div id={`id-${label}`} className={css.taskInfoValue}>
        {text ?? "-"}
      </div>
    </div>
  );
  
  const mouseEnter = (): void => {
    setTooltipOpen(true);
  };
  
  const mouseLeave = (): void => {
    setTooltipOpen(false);
  };
  
  const handleDownloadFile = (label: string | undefined, url: string | undefined): void => {
    if (isAdministrator) {
      downloadFile(label as string, url as string);
    }
  };
  
  const renderFiles = (
    files: ActionHistoryFileType[],
    title: string,
  ): JSX.Element | null => {
    if (!files.length) return null;
    
    return (
      <div className={title === "Актуальные:" ? "mb-2" : ""}>
        {!!title && <div className="mb-1">{title}</div>}
        <div>
          {files.map(({ label, url }, index: number): JSX.Element => (
            <a
              key={index}
              className="flex blue-color"
              onClick={() => handleDownloadFile(label, url)}
            >
              <AttachFileIcon className="flex-shrink-0 mr-1" />
              <div className={css.docFile}>{label}</div>
            </a>
          ))}
        </div>
      </div>
    );
  };
  
  const renderAttachmentsFiles = (files: ActionHistoryFileType[]): JSX.Element => {
    const statusVariant = (variant: string): ActionHistoryFileType[] =>
      files?.filter((file: ActionHistoryFileType): boolean => file?.status === variant) || [];
    const actualFiles: ActionHistoryFileType[] = statusVariant("success");
    const notActualFiles: ActionHistoryFileType[] = statusVariant("error");
    
    return (
      <>
        {renderFiles(actualFiles, "Актуальные:")}
        {renderFiles(notActualFiles, "Неактуальные:")}
        {!actualFiles.length && !notActualFiles.length && renderFiles(files, "")}
      </>
    );
  };
  
  const renderCeoFullName = (clientData: DocumentTaskFieldsType | undefined): string => {
    return (
      clientData?.client?.[0]?.ceo_fullname ??
      clientData?.client_profile_ooo?.[0]?.ceo_fullname ??
      clientData?.client_profile_ip?.[0]?.ceo_fullname ??
      "-"
    );
  };
  
  const renderTaskInfo = (
    label: string | undefined,
    value: string | JSX.Element,
    isAboutTask: boolean = false
  ): JSX.Element => (
    <div className={isAboutTask ? "flex min-w-full" : "flex"}>
      <div className={css.taskInfoLabel}>{label}</div>
      <div className={css.taskInfoValue}>{value}</div>
    </div>
  );
  
  const hasClient: boolean = client !== null;

  return (
    <div className={taskCardInfoWrap}>
      <div className={taskCardInfoBlock}>
        <Card className="mb-3">
          <div className={css.cardInfo} ref={refClientInfo}>
            <div>
              <span className={css.taskInfoHeader}>
                Клиент
              </span>
              <div>
                {isAdministrator || hasClient ? (
                  <div className="flex">
                    <div className={css.taskInfoLabel}>Организация</div>
                    <a
                      className={css.taskInfoValue}
                      href={`/task/${uuid}/clients/${client?.[0]?.uuid}`}
                    >
                      {client?.[0]?.label ?? "-"}
                    </a>
                  </div>
                  ) : (
                    renderTaskInfo("Организация", renderClientLabel ?? "-")
                )}
                {renderTaskInfo(clientLabel?.["ceo_fullname"]?.label, renderCeoFullName(documentTask?.fields))}
                {isAdministrator ? (
                  <div>
                    {renderTaskInfo(`${clientLabel?.["inn"]?.label} клиента`, inn ?? "-")}
                    <div className="flex">
                      <div
                        className={`${css.taskInfoLabel} flex items-center`}
                        onMouseEnter={mouseEnter}
                        onMouseLeave={mouseLeave}
                      >
                        Дата окончания обслуживания
                        <Tooltip
                          open={tooltipOpen}
                          placement="topLeft"
                          title={contentTooltip}
                          trigger="hover"
                          overlayInnerStyle={{ width: "270px" }}
                          arrow={{ pointAtCenter: true }}
                        >
                          <HelpIcon className="ml-1" />
                        </Tooltip>
                      </div>
                      <div className={css.taskInfoValue}>
                        {DeadLine(clientTariffData?.finished_at, documentTask) ?? "-"}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Card>
        {isTaskContentHidden && (
          <Card ref={refTaskContent}>
            <span className={css.taskInfoHeader}>
              Содержание задачи
            </span>
            <div>
              <div>
                {task_type?.[0]?.label === "Обращение" && (
                  <div>
                    {renderTaskContent(taskLabels?.["support_code"]?.label, support_code)}
                    {renderTaskContent(taskLabels?.["support_topic"]?.label, support_topic)}
                  </div>
                )}
                {topic && (
                  renderTaskContent(taskLabels?.["topic"]?.label, topic)
                )}
                {declaration_type && (
                  renderTaskContent("Тип декларации", declarationLabel(declaration_type, declarationTopic))
                )}
                <div>
                {isAdministrator && task_content &&
                  renderTaskContent(taskLabels?.["task_content"]?.label, task_content)
                }
                  {(!!mainFiles && cashTransactionsTask) && (
                    <div className="flex">
                      <div className={css.taskInfoLabel}>Основные документы</div>
                      <div className={css.taskInfoValue}>
                        {main_files?.map((
                          doc: ActionHistoryFileType,
                          index: number
                        ): JSX.Element => (
                          <a
                            key={index}
                            className="flex blue-color"
                            onClick={() => handleDownloadFile(doc?.label, doc?.url)}
                          >
                            <AttachFileIcon className="flex-shrink-0 mr-1" />
                            <div className={css.docFile}>
                              {doc?.label}
                            </div>
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
                  {!!attachmentsFiles && (
                    renderTaskInfo("Вложенные файлы", renderAttachmentsFiles(files as ActionHistoryFileType[]))
                  )}
                </div>
              </div>
            </div>
          </Card>
        )}
        <Card className="mt-3" ref={refTaskInfo} >
          <div className={css.cardInfo}>
            <div>
              <div>
                <div className={css.cardInfoBlock}>
                  <span className={css.taskInfoHeader}>
                    О задаче
                  </span>
                  <div className="flex min-w-full">
                    <div className={css.taskInfoLabel}>Дата создания</div>
                    <div className={`${css.taskInfoValue} whitespace-nowrap`}>
                      {`
                       ${dayjs(documentTask?.created_at)?.format("DD.MM.YY")}
                       ${dayjs(documentTask?.created_at)?.format("HH:mm")}
                      `}
                    </div>
                  </div>
                  {renderTaskInfo(
                    taskLabels?.["deadline"]?.label,
                    DeadLine(deadline, documentTask, false, true) ?? "-",
                    true
                  )}
                  {renderTaskInfo(
                    taskLabels?.["status_task"]?.label,
                    parseStatus(status_task) ?? "-",
                    true
                  )}
                  {renderTaskInfo(
                    taskLabels?.["responsible_reference"]?.label,
                    reformatName(responsible_reference?.[0]?.label ?? "-") ?? "-",
                    true
                  )}
                  {renderTaskInfo(
                      taskLabels?.["task_author"]?.label,
                      reformatName(task_author?.[0]?.label ?? "-") ?? "-",
                      true
                  )}
                  {renderTaskInfo(
                    taskLabels?.["task_type"]?.label,
                    task_type?.[0]?.label ?? "-",
                    true
                  )}
                  {renderTaskInfo(
                    taskLabels?.["priority"]?.label,
                    priority ?? "-",
                    true
                  )}
                  {isTaskTerminationService && (
                    renderTaskInfo(
                      taskLabels?.["task_reason"]?.label,
                      task_reason ?? "-",
                      true
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <TaskCardHistoryStepBlock
        taskUuid={uuid as string}
        currentStep={currentStep}
        isCheckAccess={isCheckAccess}
        refTaskContent={refTaskContent}
        isAdministrator={isAdministrator}
        setNavBarHidden={setNavBarHidden}
        clientProfileIp={client_profile_ip}
        clientProfileOoo={client_profile_ooo}
        drawerTaskAction={drawerTaskAction}
        isConfirmModalOpen={isConfirmModalOpen}
        actionHistoryHeight={actionHistoryHeight}
        isHistoryStepsBlockLoading={isHistoryStepsBlockLoading}
      />
    </div>
  );
};

export default TaskCardInfo;
