import React, {
  FC,
  JSX,
  useRef,
  useMemo,
  useState,
  useEffect,
  Dispatch,
  SetStateAction
} from "react";
import {
  useLocation,
  useParams,
  Location,
} from "react-router-dom";
import { useDispatch, useSelector, batch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { AppStateType } from "../../../reducers/mainReducer";
import { ReactComponent as ErrorIcon } from "../../../assets/icons/warning_circle_icon.svg";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import {
  getStatusTask,
  fetchSchemaTask,
  fetchDocumentTask,
  getTaskFile1CFailure,
  getTaskUuid1CFailure,
  getActionHistoryListing,
  getProcessAutomaticallyFileFailure,
  getActionHistoryFailure,
  getOperationMarkupFilesFailure,
  getSchemaTaskFailure,
  getDocumentTaskFailure,
  getStatusTaskFailure
} from "../../../actions/tasks.actions";
import type { TabsProps } from "antd";
import {
  Tabs,
  Form,
  notification,
} from "antd";
import css from "./TaskCards.module.css";
import {
  TaskNotificationStatusPropsType,
  ActionHistoryResultsType,
  ActionHistoryType,
  AbstractDocType,
} from "app/types";
import LoadingCustom from "../../ui-kit/LoadingCustom/LoadingCustom";
import { finalizeTaskFailure } from "../../../actions/document.actions";
import { parseStatusText } from "../../../utils/parseStatusText";
import TaskCardInfo from "./TaskCardInfo/TaskCardInfo";
import TaskCardTechInfo from "./TaskCardTechInfo/TaskCardTechInfo";
import { fetchSchemaClient, getClientTariffInfo } from "../../../actions/client.actions";
import * as jose from "jose";
import { roleResolver } from "../../../utils/roleResolver";
import ModalApplication from "./ModalApplication/ModalApplication";
import { updateTaskNotification } from "../../../api/tasks.api";
import { openNotificationsUpdateError } from "../Tasks";
import { getNotificationsNumber } from "../../../actions/notifications.actions";
import { notificationProcessing } from "../../../utils/Notifications/TaskCardNotifications/TaskCardNotifications";
import TaskCardReturnBtn from "./TaskCardReturnBtn/TaskCardReturnBtn";
import TaskCardActionBtn from "./TaskCardActionBtn/TaskCardActionBtn";
import TaskCardConfirmModal from "./TaskCardConfirmModal/TaskCardConfirmModal";
import TaskCardActionDrawer from "./TaskCardInfo/TaskCardActionDrawer/TaskCardActionDrawer";

interface ITaskCardsProps {
  setNavBarHidden: Dispatch<SetStateAction<boolean>>;
}

enum historyAction {
  onlyOne = 1,
}

const actionsRequiringConfirmation: string[] = ["increasePriority", "closeTask"];

const TaskCards: FC<ITaskCardsProps> = ({
  setNavBarHidden
}): JSX.Element => {
  const [form] = Form.useForm();

  const [currentStep, setCurrentStep] = useState<number | undefined>(-1);
  const [isAdministrator, setAdministrator] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [updateHistory, setUpdateHistory] = useState<boolean>(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [applicationModal, setApplicationModal] = useState<boolean>(false);
  const [isHistoryStepsBlockLoading, setHistoryStepsBlockLoading] = useState<boolean>(true);
  const [taskUpdate, setTaskUpdate] = useState<boolean>(true);
  const [activeKey, setActiveKey] = useState<string>("1");
  const [isClarificationStatus, setClarificationStatus] = useState<boolean>(false);
  const [drawerTaskAction, setDrawerTaskAction] = useState<string>("");
  const [confirmTaskAction, setConfirmTaskAction] = useState<string>("");
  
  //Блокировка кнопок при автоматической обработке задачи
  const [isAutomaticProcessingActive, setAutomaticProcessingActive] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const decodedToken: jose.JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  let timeoutId: NodeJS.Timeout | undefined;
  const adminRole: boolean = roleResolver(decodedToken).isAdmin;
  const accountantRole: boolean = roleResolver(decodedToken).isAccountant;
  const techSupportRole: boolean = roleResolver(decodedToken).isTechSupport;
  const contactCenterRole: boolean = roleResolver(decodedToken).isContactCenter;
  const isConfirmationActions = (taskAction: string): boolean => actionsRequiringConfirmation.includes(taskAction);
  const formWrapper: string | false = drawerTaskAction && !isConfirmationActions(drawerTaskAction) && "flex";

  useEffect(() => {
    return () => {
      notification.destroy();
      
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      
      batch(() => {
        dispatch(getDocumentTaskFailure());
        dispatch(getSchemaTaskFailure());
        dispatch(getProcessAutomaticallyFileFailure(0, null));
        dispatch(getOperationMarkupFilesFailure(0));
        dispatch(getTaskUuid1CFailure(0, null));
        dispatch(getTaskFile1CFailure(0, null));
        dispatch(getActionHistoryFailure());
        dispatch(getStatusTaskFailure(0));
      });
    };
  }, []);

  const paramsData: any = useParams();
  const path: string = useLocation().pathname;
  const params = useMemo(() => paramsData, [path]);
  const location: Location = useLocation();

  //получение схемы задачи
  const {
    documentTask,
    statusTask,
    schemaTaskData,
    actionHistory,
    getDocumentTaskStatus,
    actionHistoryStatus,
    statusTaskNumber,
    parsingResultStatus,
    markupTaskFileFrom1C,
    markupTaskStatusForm1C,
    file1CDownloadStatus,
    taskFile1CUuid,
    taskFile1CData,
    decisionTypeStatus,
  } = useSelector((state: AppStateType) => state.tasks);

  const {
    error,
    finalizeTaskStatus,
  } = useSelector((state: AppStateType) => state.document);

  useEffect(() => {
    if (actionHistoryStatus !== 0 || statusTaskNumber !== 0) setHistoryStepsBlockLoading(false);
  }, [actionHistoryStatus, statusTaskNumber]);

  useEffect(() => {
    if (schemaTaskData && documentTask) {
      setLoading(true);
    }
  }, [schemaTaskData, documentTask]);

  const {state} = useLocation();

  const clientInfo = state?.record;

  const ref: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement | null>(null);

  const {
    task_type,
    status_task,
    client_tariff,
    task_type_id,
    number,
    uuid,
    client,
    responsible_reference,
    temporary_responsible_reference,
    additional_number_of_consultations,
    additional_accounting_restore_months,
    additional_number_of_employees,
    additional_agency_agreements_qty,
    task_reason,
    send_clarification_result,
    topic,
    support_topic,
    inn,
    parsing_result,
    declaration_type,
    declaration_end_date,
    declaration_start_date,
  } = documentTask?.fields ?? {};

  const clarificationVariant = (status: boolean, value: string): void => {
    notification.destroy();
    setClarificationStatus(status);
    notificationProcessing(true, value);
  };

  const isTaskFinished: boolean = status_task === "FIN_FALSE"
   || status_task === "FIN_TRUE"
   || status_task === "1C_SYNC"
   || status_task === "CLOSED_BY_FNS_SUCCESS";

  useEffect(() => {
    const status: string | undefined = send_clarification_result?.[0]?.send_clarification_result_status;
    
    const handleClarificationResult = (): void => {
      switch (status) {
        case "IN_PROGRESS":
          clarificationVariant(true, "processing");
          timeoutId = setTimeout((): void => {
            dispatch(fetchDocumentTask(uuid));
          }, 30000);
          break;
        case "SUCCESS":
          clarificationVariant(false, "success");
          break;
        case "ERROR":
          clarificationVariant(false, "failure");
          break;
        default:
          break;
      }
    };
    
    if (status && !isTaskFinished) {
      setClarificationStatus(true);
      handleClarificationResult();
    }
  }, [send_clarification_result]);

  const taskType: string | undefined = task_type?.[0]?.label;

  const isTaskFinalised: boolean = status_task === "PAUSE"
    || status_task === "FIN_FALSE"
    || status_task === "FIN_TRUE"
    || status_task === "1C_SYNC"
    || status_task === "CLOSED_BY_FNS_SUCCESS"
    || status_task === "SENT_TO_FNS"
    || status_task === "FLK_CHECK_SUCCESS";
  const isFinishTaskDisabled: boolean = isTaskFinalised || status_task === "NEW";
  const isCompletedSuccessfully: boolean = status_task === "FIN_FALSE"
    || status_task === "FIN_TRUE"
    || status_task === "CLOSED_BY_FNS_SUCCESS";
  const is1CError: boolean = status_task === "1C_ERROR";
  const separateTaskType: boolean = taskType === "Разметка операций" || taskType === "Обращение";
  const isTaskTerminationService: boolean = taskType === "Прекращение обслуживания";
  const isTaskTariffIncrease: boolean = taskType === "Повышение тарифа";
  const isApplicationTask: boolean = isTaskTerminationService || isTaskTariffIncrease;
  const isAutomaticProcess: boolean = taskType !== "Онбординг" && status_task === "1C_SYNC";

  const fetchTaskInfo = (location: Location): void => {
    //Получаем данные Task по uuid документа, uuid получаем из параметров
    const getInfo = (currentTaskUuid: string): void => {
      dispatch(fetchSchemaTask());
      dispatch(fetchDocumentTask(currentTaskUuid));
      checkStepResult();
    };

    let currentTaskUuid: string;
    const isPathHasClients: boolean = path.includes("clients");

    if (!documentTask) {
      currentTaskUuid = location?.pathname?.split("/")?.[isPathHasClients ? 4 : 2];
      getInfo(currentTaskUuid);
      return;
    }
  };

  useEffect(() => {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const navigator: string | null = urlParams.get("navigator");

    if (isAutomaticProcess || navigator === "auto") {
      setDrawerTaskAction("Обработать задачу автоматически");
    }
  }, [isAutomaticProcess]);

  useEffect(() => {
    if (path.includes("task")) {
      setNavBarHidden(false);
    }

    if (!documentTask) {
      fetchTaskInfo(location);
    }
  }, [
    ref,
    path,
    params,
    location,
    clientInfo,
    updateHistory,
    finalizeTaskStatus,
    documentTask,
  ]);

  useEffect(() => {
    checkStepResult();
  }, [statusTask]);

  useEffect(() => {
    if (!documentTask) return;
    dispatch(fetchSchemaClient());

    if (documentTask) {
      if (client_tariff?.[0]?.uuid) {
        dispatch(getClientTariffInfo(client_tariff?.[0]?.uuid));
      }

      if (task_type_id) {
        dispatch(getActionHistoryListing({"task.uuid": params.id}));
        setAdministrator(true);
      } else {
        setAdministrator(false);
        dispatch(getStatusTask(params.id));
      }
    }
  }, [documentTask, updateHistory]);

  useEffect(() => {
    const isResponsibleForTask: boolean = documentTask?.fields?.responsible_reference?.[0]?.uuid === decodedToken?.sub;
    const uuidTask: string | undefined = documentTask?.fields?.uuid;

    const fetchNotification = async (): Promise<void> => {
      try {
        await updateTaskNotification({task_uuid: uuidTask, read: true});
        dispatch(getNotificationsNumber());
      } catch (error) {
        openNotificationsUpdateError();
      } finally {
        setTaskUpdate(false);
      }
    };

    if (uuidTask && taskUpdate && isResponsibleForTask) {
      fetchNotification();
    }

  }, [documentTask]);

  const isUserResponsible = (references: AbstractDocType[] | undefined): boolean => {
    const userUuid: string = decodedToken?.sub ?? "";

    if (!references || references.length === 0) return false;

    return references[0]?.uuid === userUuid;
  };

  const isAccessToClientProfile = (): boolean => {
    const isClientResponsible: boolean = client?.[0]?.temporary_responsible_reference !== null
      ? isUserResponsible(client?.[0]?.temporary_responsible_reference)
      : isUserResponsible(client?.[0]?.responsible_reference);

    const isTaskResponsible: boolean = responsible_reference !== null
      ? isUserResponsible(responsible_reference)
      : isUserResponsible(temporary_responsible_reference);

    const isResponsibleForTask: boolean = isClientResponsible || isTaskResponsible;

    return (adminRole || techSupportRole || isResponsibleForTask || contactCenterRole);
  };

  const isCheckAccess = (): boolean => {
    const isTaskResponsible: boolean = responsible_reference !== null
      ? isUserResponsible(responsible_reference)
      : isUserResponsible(temporary_responsible_reference);

    return (adminRole || techSupportRole || isTaskResponsible);
  };

  //данные верхнего меню страницы
  const itemsTabs: TabsProps["items"] = [
    {
      key: "1",
      label: "Общая информация",
      children: (
        <TaskCardInfo
          currentStep={currentStep}
          isAdministrator={isAdministrator}
          setNavBarHidden={setNavBarHidden}
          isCheckAccess={isAccessToClientProfile()}
          drawerTaskAction={drawerTaskAction}
          isConfirmModalOpen={isConfirmModalOpen}
          isHistoryStepsBlockLoading={isHistoryStepsBlockLoading}
        />
      ),
      destroyInactiveTabPane: true,
    },
    {
      key: "2",
      label: techSupportRole ? "Служебная информация" : null,
      children: (
        techSupportRole ? <TaskCardTechInfo /> : null
      ),
      destroyInactiveTabPane: true,
    },
  ];

  const checkStepResult = (): void => {
    if (statusTask) {
      if (statusTask?.statuses?.[0].status === "in_progress") {
        setCurrentStep(0);
      } else {
        const lastIndex: number = statusTask.statuses.reduceRight(
          (lastIndex: number,
           currentStep: TaskNotificationStatusPropsType,
           currentIndex: number): number => {
            return lastIndex === -1 && currentStep.status === "passed"
              ? currentIndex
              : lastIndex;
          },
          -1
        );
        setCurrentStep(lastIndex >= 0 ? lastIndex + 1 : -1);
      }
    }
  };

  const openNotification = (): void => {
    const isFinalStepSuccess: boolean = statusTask?.statuses?.[7]?.status === "passed";
    const isSuccess: boolean = finalizeTaskStatus === 200
      && !!documentTask
      && isCompletedSuccessfully
      && isFinalStepSuccess;
    const isError: boolean = finalizeTaskStatus === 200 && !!documentTask && is1CError;

    if (isSuccess) {
      api.success({
        message: task_type_id
          ? `Задача №${number} завершена`
          : `Валидация Анкеты по задаче  №${number} завершена`,
        description: !task_type_id
          ? `Анкета переведена в статус: ${parseStatusText(status_task)}`
          : null,
        duration: 0,
        style: {
          width: 500,
        },
      });
    }

    if (isError) {
      api.error({
        icon: <ErrorIcon className="flex-shrink-0 w-6 h-6"/>,
        message: <div className={css.error1C}>Произошла ошибка при автоматическом создании карточки в 1С</div>,
        description: <div className={css.error1CText}>Необходимо ввести данные вручную</div>,
        duration: 5,
        style: {
          width: 500,
        },
      });
    }
  };

  const openErrorNotification = (error: string): void => {
    api.error({
      message: task_type_id ? "Ошибка завершения задачи" : "Ошибка валидация задачи",
      description: task_type_id ? error : "Требуется валидировать все поля",
      duration: 0
    });
  };

  useEffect(() => {
    const isTaskSuccessful: boolean = finalizeTaskStatus === 200 && getDocumentTaskStatus === 200 && !!documentTask;

    if (error) {
      openErrorNotification(error);
      dispatch(finalizeTaskFailure());
    }

    if (isTaskSuccessful) {
      setTimeout(() => openNotification(), 1100);

      dispatch(finalizeTaskFailure());
    }
  }, [error, finalizeTaskStatus, getDocumentTaskStatus, documentTask]);

  const showApplicationModal = (): void => {
    setApplicationModal(true);
  };

  useEffect(() => {
    if (confirmTaskAction) {
      setConfirmModalOpen(!!confirmTaskAction);
    }
  }, [confirmTaskAction]);

  const isCheckFirstHistory = (actionHistory: ActionHistoryType | null): boolean => {
    if (Array.isArray(actionHistory?.results) && actionHistory?.results?.length === historyAction.onlyOne) {
      const item: ActionHistoryResultsType = actionHistory?.results?.[0];

      const systemMessage: boolean = item?.fields
        && item?.fields?.decision_maker === null
        && item?.fields?.task_decision_type === "system_message";

      if (systemMessage) {
        return true;
      }
    }

    return false;
  };

  const accessTaskAction = (): boolean => {
    if (isTaskFinalised || !isCheckAccess()) {
      return true;
    }

    const taskTypeId: number = Number(task_type_id);
    const isHundredSeriesTask: boolean = !isNaN(taskTypeId) && taskTypeId >= 100 && taskTypeId <= 199;
    const hasDecisionMakerMessage: boolean | undefined =
      actionHistory?.results?.some((result: ActionHistoryResultsType) => !result?.fields.decision_maker);

    switch (taskType) {
      case "Списание":
        return true;
      case "Наличные операции":
        return !(adminRole || accountantRole);
      case "Исходящая задача":
        return !!(!isHundredSeriesTask && hasDecisionMakerMessage && !isCheckFirstHistory(actionHistory));
      default:
        return false;
    }
  };

  useEffect(() => {
    setActiveKey("1");
  }, [path]);

  const handleTabChange = (key: string): void => {
    setActiveKey(key);
  };

  useEffect(() => {
    if (isTaskTariffIncrease) {
      form.setFieldsValue({
        paidConsultation: additional_number_of_consultations ?? 0,
        accountingRestoration: additional_accounting_restore_months ?? 0,
        addingEmployees: additional_number_of_employees ?? 0,
        addAgencyAgreements: additional_agency_agreements_qty ?? 0,
        textTariff: "",
      });
    }

    if (isTaskTerminationService) {
      form.setFieldsValue({
        terminateReason: task_reason ?? "",
      });
    }
  }, [form, documentTask]);

  const isAccessReviewApplication: boolean = !isTaskFinalised && isCheckAccess();
  const canTakeAction: boolean = isFinishTaskDisabled || !isCheckAccess() || separateTaskType;
  const isHistoryOrClarificationLoading: boolean = isHistoryStepsBlockLoading || isClarificationStatus;
  const isCompleteTaskActionDisabled: boolean = canTakeAction || isHistoryOrClarificationLoading;
  const isActionBtnDisabled: boolean = accessTaskAction()
    || isHistoryOrClarificationLoading
    || isAutomaticProcessingActive
    || status_task === "NEW" && !adminRole;

  // Стилизация
  const taskCardBlockContainer: string = drawerTaskAction ? css.containerCollapsed : css.container;
  const taskCardHeaderStyle: string = isAdministrator ? css.cardsHeaderAdmin : css.cardsHeader;

  const renderCardHeader = (): JSX.Element => isAdministrator ? (
    <div className="flex items-center">
      <div>
        <TaskCardReturnBtn
          taskNumber={number as number}
          taskType={taskType as string}
          taskTopic={support_topic as string ?? topic as string}
          drawerTaskAction={drawerTaskAction}
        />
      </div>
      <div className="flex mt-1">
        {renderActionBtn()}
      </div>
    </div>
  ) : (
    <div>
      <TaskCardReturnBtn
        taskNumber={number as number}
        taskType={taskType as string}
        taskTopic={support_topic as string ?? topic as string}
        drawerTaskAction={drawerTaskAction}
      />
    </div>
  );

  const renderActionBtn = (): JSX.Element => !isApplicationTask ? (
    <TaskCardActionBtn
      isSelectActive={isModalOpen}
      isActionBtnDisabled={isActionBtnDisabled}
      setDrawerTaskAction={setDrawerTaskAction}
      setConfirmTaskAction={setConfirmTaskAction}
      isConfirmationActions={isConfirmationActions}
      isCompleteTaskActionDisabled={isCompleteTaskActionDisabled}
    />
  ) : (
    <ButtonCustom
      className={css.button}
      type="primary"
      text="Рассмотреть заявку"
      htmlType="button"
      onClick={showApplicationModal}
      disabled={!isAccessReviewApplication || isHistoryStepsBlockLoading}
    />
  );

  const renderActionDrawer = (): false | JSX.Element => !!drawerTaskAction && (
    <TaskCardActionDrawer
      taskInn={inn as string}
      taskUuid={uuid as string}
      taskType={taskType as string}
      taskFile1CUuid={taskFile1CUuid}
      taskFile1CData={taskFile1CData}
      drawerTaskAction={drawerTaskAction}
      parsingResultUuid={parsing_result?.[0]?.uuid as string}
      decisionTypeStatus={decisionTypeStatus}
      isAutomaticProcess={isAutomaticProcess}
      setDrawerTaskAction={setDrawerTaskAction}
      parsingResultStatus={parsingResultStatus}
      file1CDownloadStatus={file1CDownloadStatus}
      markupTaskFileFrom1C={markupTaskFileFrom1C}
      markupTaskStatusForm1C={markupTaskStatusForm1C}
      documentSchemaVersion={documentTask?.schema_version as number}
      setAutomaticProcessingActive={setAutomaticProcessingActive}
      isActionDisabled={isActionBtnDisabled}
      declarationType={declaration_type as string}
      declarationEndDate={declaration_end_date as string}
      declarationStartDate={declaration_start_date as string}
      isAutomaticProcessingActive={isAutomaticProcessingActive}
    />
  );

  return isLoading ? (
    <div className={formWrapper as string}>
      <Form
        form={form}
        className={taskCardBlockContainer}
      >
        {contextHolder}
        <div className={taskCardHeaderStyle}>
          {renderCardHeader()}
          <Tabs
            className="mb-[18px]"
            defaultActiveKey="1"
            activeKey={activeKey}
            items={itemsTabs}
            onChange={handleTabChange}
          />
        </div>
        <TaskCardConfirmModal
          taskUuid={uuid as string}
          confirmTaskAction={confirmTaskAction}
          isConfirmModalOpen={isConfirmModalOpen}
          setConfirmModalOpen={setConfirmModalOpen}
          setDrawerTaskAction={setDrawerTaskAction}
          setConfirmTaskAction={setConfirmTaskAction}
          setAutomaticProcessingActive={setAutomaticProcessingActive}
        />
        <ModalApplication
          isModalOpen={applicationModal}
          setIsModalOpen={setApplicationModal}
          formApplication={form}
        />
      </Form>
      {renderActionDrawer()}
    </div>
  ) : (
    <div className="text-center mt-96">
      <LoadingCustom />
    </div>
  );
};

export default TaskCards;
