import React, { FC, JSX } from "react";
import {
  Form,
  FormInstance,
  Input,
  InputNumber,
  Popover,
  Tooltip
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../../reducers/mainReducer";
import { DocumentSchemaLabelMapType, TypicalRequestToClientType } from "app/types";
import { documentSchemaLabelsMapper } from "../../../../../utils/documentSchemaLabelsMapper";
import { AppDispatch } from "../../../../../store/store";
import css from "../ClientCardActionDrawer.module.css";
import { getConsultationsNumber } from "../../../../../utils/clientConsultations";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import {
  sendTariffIncreaseRequestToClient,
  setPopoverTariffType
} from "../../../../../actions/client.actions";
import * as jose from "jose";

interface ITariffIncreaseProps {
  form: FormInstance;
  onDrawerClose: () => void;
}

const TariffIncrease: FC<ITariffIncreaseProps> = ({
  form,
  onDrawerClose
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const { TextArea } = Input;
  const values = Form.useWatch([], form);
  
  const {
    textTariff,
    paidConsultation,
    accountingRestoration,
    addAgencyAgreements,
    addingEmployees
  } = values || {};

  const {
    clientTariffData,
    schemaClient,
    showClientTariff,
    documentClient
  } = useSelector((state: AppStateType) => state.client);
  const decodedToken: jose.JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);
  const tariffClientLabel: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(schemaClient);
  const commentCount: number = textTariff?.length || 0;

  const tariffContentData = (label: string, value: string | number | undefined): JSX.Element => {
    return (
      <li className="flex items-center gap-1">
        <span>{label}:</span>
        <span>{value ?? "-"}</span>
      </li>
    );
  };
  
  const openTariffContent = (): void => {
    dispatch(setPopoverTariffType(true));
  };
  
  const closeTariffContent = (): void => {
    dispatch(setPopoverTariffType(false));
  };

  const tariffContent: JSX.Element = (
    <div>
      <ul className={css.tariffContent}>
        {tariffContentData(
          tariffClientLabel["number_of_consultations"].label,
          getConsultationsNumber(clientTariffData)
        )}
        {tariffContentData(
          tariffClientLabel["accounting_restore_months"].label,
          `${clientTariffData?.account_recovery_months ?? "-"} мес.`
        )}
        {tariffContentData(tariffClientLabel["number_of_employees"].label, clientTariffData?.number_of_employees)}
        {tariffContentData(tariffClientLabel["agency_agreements_qty"].label, clientTariffData?.agency_contracts_qty)}
        {tariffContentData(tariffClientLabel["opf"].label, documentClient?.fields?.opf)}
        {tariffContentData(tariffClientLabel["tax_system"].label, documentClient?.fields?.tax_system)}
      </ul>
      <div className="flex items-end flex-col">
        <ButtonCustom
          type="primary"
          text="Закрыть"
          className="flex mt-1"
          onClick={closeTariffContent}
        />
      </div>
    </div>
  );

  const checkField = (fieldsName: string): boolean =>
    form.getFieldValue(fieldsName as any) > 0;
  
  const formField = (name: string, label: string): JSX.Element => {
    const defaultFieldValue = (): void =>
      form.setFieldValue(name as any, 0);
    
    return (
      <div>
        <div className="flex w-full justify-between items-center">
          <div>{label}</div>
          {checkField(name) &&
            <ButtonCustom
              type="link"
              text="Сбросить"
              onClick={defaultFieldValue}
            />
          }
        </div>
        <Form.Item
          className="mb-4"
          name={name as any}
          id={name}
        >
          <InputNumber
            id={name}
            defaultValue={0}
            className="w-full"
            size="large"
            min={0}
            changeOnWheel
          />
        </Form.Item>
      </div>
    );
  };

  const formFieldsRender = (): JSX.Element => (
    <>
      {formField("paidConsultation", "Платная консультация")}
      {formField("accountingRestoration", "Восстановление бухгалтерского учета")}
      {formField("addingEmployees", "Добавление сотрудников")}
      {formField("addAgencyAgreements", "Добавление агентских договоров")}
    </>
  );
  
  const checkFormValues = (values: { [key: string]: any } | undefined | null): boolean => {
    if (!values) return true;
    
    return !Object.values(values).some((value) => typeof value === "number" && value > 0);
  };
  
  const renderDefaultButton = (isDisabled: boolean): JSX.Element => (
    <ButtonCustom
      id="buttonOk"
      key="ok"
      size="large"
      text="Подтвердить"
      type="primary"
      htmlType="submit"
      className={css.buttonOk}
      disabled={isDisabled}
    />
  );
  
  const renderConfirmButton: JSX.Element = (
    checkFormValues(values) ? (
      <Tooltip
        title="Для формирования заявки необходимо внести изменения в состав опций"
        placement="topRight"
        trigger="hover"
        overlayStyle={{ width: "263px" }}
        overlayInnerStyle={{ textAlign: "center" }}
      >
        <span>
          {renderDefaultButton(checkFormValues(values))}
        </span>
      </Tooltip>
    ) : (
      renderDefaultButton(checkFormValues(values))
    )
  );
  
  const onClientActionFinish = (): void => {
    const clientData: string | undefined = documentClient?.fields?.uuid;
    const authorTask: string | null = decodedToken?.sub ? decodedToken?.sub : null;
    
    //TODO поменять данные, как только будет точный код категории
    const tariffIncreaseRequest: TypicalRequestToClientType = {
      client: clientData,
      category_code: 998,
      task_content: textTariff ?? "",
      task_author: authorTask,
      additional_number_of_employees: addingEmployees ?? 0,
      additional_number_of_consultations: paidConsultation ?? 0,
      additional_agency_agreements_qty: addAgencyAgreements ?? 0,
      additional_accounting_restore_months: accountingRestoration ?? 0,
      files: [],
    };
    
    dispatch(sendTariffIncreaseRequestToClient(tariffIncreaseRequest));
    onDrawerClose();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onClientActionFinish}
    >
      <div className={css.tariffText}>
        Укажите количество добавочных платных опций, если это применимо.
        В противном случае оставьте нулевое значение опции.
      </div>
      <div className="flex items-center mb-4">
        <div className={css.tariffTitle}>Текущий тариф клиента:</div>
        <Popover
          content={tariffContent}
          title={clientTariffData?.name ?? "Тариф"}
          trigger="click"
          open={showClientTariff}
        >
          <ButtonCustom
            type="default"
            text="Показать"
            className={css.tariffButton}
            onClick={openTariffContent}
          />
        </Popover>
      </div>
      <div>
        <div>{formFieldsRender()}</div>
        <div className={css.tariffText}>
          Вы также можете добавить служебный комментарий. Этот комментарий не будет виден клиенту.
        </div>
        <Form.Item name="textTariff" noStyle>
          <TextArea
            id="textTariff"
            rows={7}
            maxLength={2000}
            className="text-base font-normal leading-5 text-gray-900"
            placeholder="Ваш комментарий"
          />
        </Form.Item>
        <div className="mb-4 text-xs font-normal leading-100 text-gray-400 text-right">
          {commentCount}/2000
        </div>
      </div>
      <Form.Item className="mt-2 text-right">
        <ButtonCustom
          className="mr-2"
          size="large"
          type="default"
          ghost
          text="Отменить"
          onClick={onDrawerClose}
        />
        {renderConfirmButton}
      </Form.Item>
    </Form>
  );
};

export default TariffIncrease;