import React, {
  Dispatch,
  FC,
  SetStateAction,
  useState
} from "react";
import css from "../TasksListing.module.css";
import { Tag } from "antd";
import { CatalogTaskStatusType, taskFastFilterStatus } from "../../../../utils/catalog";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import { FilterTasksType } from "app/types";
import { getTaskPageNumber, setTaskListingParams } from "../../../../actions/tasks.actions";
import { AppDispatch } from "../../../../store/store";
import dayjs from "dayjs";
import { filterParamsToArray, deleteNoneFilter } from "../../../../utils/filterParamsToArray";

interface ITaskListingFastFiltersProps {
  filter: boolean;
  setParamsData: Dispatch<SetStateAction<FilterTasksType | null>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setFilterStatusLabel: Dispatch<SetStateAction<string[]>>;
  setSelectedTaskStatuses: Dispatch<SetStateAction<string[]>>;
  activeStatus: string[];
  setActiveStatus: Dispatch<SetStateAction<string[]>>;
}

interface IActiveFilterProps {
  deadline_gte?: string,
  priority?: string,
}

const TaskListingFastFilters: FC<ITaskListingFastFiltersProps> = ({
  filter,
  setParamsData,
  setCurrentPage,
  setFilterStatusLabel,
  activeStatus,
  setActiveStatus,
  setSelectedTaskStatuses
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const tasksParams: FilterTasksType | null = useSelector((state: AppStateType) => state.tasks.tasksParams);
  
  const statusTaskData: string[] = tasksParams?.status_task
    ? deleteNoneFilter(filterParamsToArray(tasksParams?.status_task as string))
    : [];
  const firstPage: number = 1;
  const filterVariant: IActiveFilterProps = {
    deadline_gte: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    priority: "Высокий",
  };
  const taskFilters: CatalogTaskStatusType[] = [
    { status: "deadline_gte", label: "Срочные" },
    { status: "priority", label: "Высокий приоритет" },
  ];
  const tomorrowDate: string = dayjs().add(1, "day").format("YYYY-MM-DD HH:mm:ss");
  
  const initializeActiveFilter = (): string[] => {
    if (!tasksParams) return [];
    
    const priorities: string[] = tasksParams.priority ? ["priority"] : [];
    const deadlines: string[] = tasksParams.deadline_gte ? ["deadline_gte"] : [];
    
    return [...priorities, ...deadlines];
  };
  
  const [activeFilter, setActiveFilter] = useState<string[]>(initializeActiveFilter);
  
  const handleTagClick = (status: keyof IActiveFilterProps): void => {
    const newStatuses: boolean = activeFilter.includes(status);
    const isDeadLineFilter: boolean = status === "deadline_gte";
    
    if (!newStatuses) {
      setCurrentPage(firstPage);
      dispatch(getTaskPageNumber(firstPage));
      
      if (isDeadLineFilter) {
        setActiveStatus((prev: string[]): string[] => {
          const updatePrevStatus: string[] = prev.filter(
            (status: string) => status !== "FIN_TRUE" && status !== "FIN_FALSE"
          );
          
          const filterStatusTaskData: string[] = statusTaskData.filter(
            (status: string) => status !== "FIN_TRUE" && status !== "FIN_FALSE"
          );
          const newFilterStatus: string[] = [...new Set([...updatePrevStatus, ...filterStatusTaskData])];
          
          setActiveFilter((prev: string[]) => {
            const newFilters: string[] = [...prev, status];
            
            setParamsData((prev: FilterTasksType | null): FilterTasksType | null => {
              const hasExpired: boolean = prev?.is_expired != null;
              const newStatuses: string = newFilterStatus?.length ? `[${newFilterStatus}]` : "![FIN_TRUE, FIN_FALSE]";
              const updatedParams: FilterTasksType | null = prev
                ? {
                  ...prev,
                  ...(newFilters.length ? { [status]: filterVariant[status] } : {}),
                  ...(isDeadLineFilter ? { status_task: newStatuses, deadline_lte: tomorrowDate } : {}),
                  ...(isDeadLineFilter && hasExpired ? { or_filters: "[deadline, is_expired]" } : {}),
                  page: firstPage
                }
                : null;
              dispatch(setTaskListingParams(updatedParams));
              setSelectedTaskStatuses(filterStatusTaskData);
              
              return updatedParams;
            });
            
            return newFilters;
          });
          
          return updatePrevStatus;
        });
      } else {
        setActiveFilter((prev: string[]) => {
          const newFilters: string[] = [...prev, status];
          
          setParamsData((prev: FilterTasksType | null): FilterTasksType | null => {
            
            const updatedParams: FilterTasksType | null = prev
              ? {
                ...prev,
                ...(newFilters.length ? { [status]: filterVariant[status] } : {}),
                page: firstPage
              }
              : null;
            dispatch(setTaskListingParams(updatedParams));
            
            return updatedParams;
          });
          
          return newFilters;
        });
      }
    }
  };
  
  const handleTagClose = (status: keyof IActiveFilterProps): void => {
    setCurrentPage(firstPage);
    dispatch(getTaskPageNumber(firstPage));
    const isDeadLineFilter: boolean = status === "deadline_gte";
    
    setActiveFilter((prev: string[]) => prev.filter((active: string): boolean => active !== status));
    
    if (isDeadLineFilter) {
      setParamsData((prev: FilterTasksType | null): FilterTasksType | null => {
        if (prev && prev[status] !== null) {
          const newFilterStatus: string[] = [...new Set([...statusTaskData, ...activeStatus])];
          
          const updatedParams: FilterTasksType | null = prev
            ? {
                ...prev,
                [status]: null,
                deadline_lte: null,
                or_filters: null,
                status_task: newFilterStatus?.length ? `[${newFilterStatus}]` : null,
                page: firstPage,
              }
            : null;
            
          dispatch(setTaskListingParams(updatedParams));
          setSelectedTaskStatuses(activeStatus);

          return updatedParams;
        } else {
          return prev;
        }
      });
    } else {
      setParamsData((prev: FilterTasksType | null): FilterTasksType | null => {
        if (prev && prev[status] !== null) {
          const updatedParams: FilterTasksType | null = prev
            ? {
              ...prev,
              [status]: null,
              page: firstPage,
            }
            : null;
          
          dispatch(setTaskListingParams(updatedParams));
          
          return updatedParams;
        } else {
          return prev;
        }
      });
    }
  };
  
  const handleTagStatusClick = (status: string, label: string): void => {
    const newStatuses: boolean = activeStatus.includes(status);
    const hasFinishStatus: boolean = ["FIN_TRUE", "FIN_FALSE"].includes(status);
    const hasDeadline: boolean = activeFilter.includes("deadline_gte");

    if (!newStatuses) {
      setCurrentPage(firstPage);
      dispatch(getTaskPageNumber(firstPage));
      
      if (hasDeadline) {
        if (hasFinishStatus) {
          setActiveFilter((prev: string[]) => prev.filter((active: string): boolean => active !== "deadline_gte"));
        }

        setActiveStatus((prev: string[]) => {
          const newFilters: string[] = [...prev, status];

          setParamsData((prev: FilterTasksType | null): FilterTasksType | null => {
            if (prev === null) return null;
            
            const { deadline_gte, deadline_lte, ...rest } = prev;
            
            const restFilterData: Omit<FilterTasksType, "deadline_gte" | "deadline_lte"> | FilterTasksType =
              hasFinishStatus
                ? rest
                : prev;
            const updatedParams: FilterTasksType | null = prev
              ? {
                  ...restFilterData,
                  ...(newFilters.length ? { status_task: `[${newFilters}]` } : {}),
                  or_filters: null,
                  page: firstPage
                }
              : null;
            
            dispatch(setTaskListingParams(updatedParams));
            setSelectedTaskStatuses(newFilters);

            return updatedParams;
          });

          return newFilters;
        });
      } else {
        setActiveStatus((prev: string[]) => {
          const newFilters: string[] = [...prev, status];
          
          setParamsData((prev: FilterTasksType | null): FilterTasksType | null => {
            const paramsStatusFilter: string[] = prev?.status_task
              ? filterParamsToArray(prev?.status_task as string)
              : [];
            const removeFastFilters: string[] =
              paramsStatusFilter.filter((item: string) => !activeStatus.includes(item));
            const newFilterStatus: string[] = [...newFilters, ...removeFastFilters];
            const updatedParams: FilterTasksType | null = prev
              ? { ...prev, ...(newFilters.length ? { status_task: `[${newFilterStatus}]` } : {}), page: firstPage }
              : null;
            
            dispatch(setTaskListingParams(updatedParams));
            setSelectedTaskStatuses(newFilterStatus);
            
            return updatedParams;
          });
          
          return newFilters;
        });
      }
      
      setFilterStatusLabel((prev: string[]) => [...prev, label]);
    }
  };
  
  const handleStatusClose = (status: string, label: string): void => {
    setCurrentPage(firstPage);
    dispatch(getTaskPageNumber(firstPage));
    
    setActiveStatus((prev: string[]) => {
      const newFilters: string[] = prev.filter((active: string): boolean => active !== status);
      
      setParamsData((prev: FilterTasksType | null): FilterTasksType | null => {
        const statusTaskFilters: string[] = prev?.status_task
          ? filterParamsToArray(prev.status_task as string)
          : [];
        const newStatusFilters: string[] = statusTaskFilters.filter((active: string): boolean => active !== status);
        
        if (newStatusFilters.length) {
          const updatedParams: FilterTasksType | null = prev
             ? { ...prev, status_task: `[${newStatusFilters}]`, page: firstPage }
             : null;
          
          dispatch(setTaskListingParams(updatedParams));
          setSelectedTaskStatuses(newStatusFilters);
          
          return updatedParams;
        } else {
          const hasDeadline: boolean = activeFilter.includes("deadline_gte");
          const { status_task, ...rest } = prev as FilterTasksType;
          const updatedParams: FilterTasksType | null = prev
            ? {
              ...rest,
              page: firstPage,
              ...(hasDeadline ? { status_task: "![FIN_TRUE, FIN_FALSE]" } : {}),
            }
            : null;

          dispatch(setTaskListingParams(updatedParams));
          setSelectedTaskStatuses([]);
          
          return updatedParams;
        }
      });
      
      return newFilters;
    });
    setFilterStatusLabel((prev: string[]) => prev.filter((item: string): boolean => item !== label));
  };
  
  return (
    <div className={`flex ${filter ? "" : "mb-3"}`}>
      {taskFilters.map(({ status, label }) => (
        <Tag
          key={status}
          className={`${css.commonTagStyle} ${activeFilter.includes(status) ? css.activeFilters : css.quickFilters}`}
          closable={activeFilter.includes(status)}
          onClick={() => handleTagClick(status as keyof IActiveFilterProps)}
          onClose={() => handleTagClose(status as keyof IActiveFilterProps)}
        >
          <div className={activeFilter.includes(status) ? "mr-1" : ""}>{label}</div>
        </Tag>
      ))}
      {taskFastFilterStatus.map(({ status, label }) => (
        <Tag
          key={status}
          className={`${css.commonTagStyle} ${activeStatus.includes(status) ? css.activeFilters : css.quickFilters}`}
          closable={activeStatus.includes(status)}
          onClick={() => handleTagStatusClick(status, label)}
          onClose={() => handleStatusClose(status, label)}
        >
          <div className={activeStatus.includes(status) ? "mr-1" : ""}>{label}</div>
        </Tag>
      ))}
    </div>
  );
};

export default TaskListingFastFilters;