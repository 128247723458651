export interface IClientActionsProps {
  key?: number,
  value: string,
  label: string,
  disabled?: boolean,
}

export const clientActions: IClientActionsProps[] = [
  {
    value: "Запрос информации",
    label: "Запрос информации",
  },
  {
    value: "Предоставление информации",
    label: "Предоставление информации",
  },
  {
    value: "Списание платной консультации",
    label: "Списание платной консультации",
  },
  {
    value: "Заявка на повышение тарифа",
    label: "Заявка на повышение тарифа",
  },
  {
    value: "Заявка на прекращение обслуживания",
    label: "Заявка на прекращение обслуживания",
  },
  {
    value: "Создать обращение",
    label: "Создать обращение",
  },
];
