import React, { FC, JSX, useEffect, useState } from "react";
import { Checkbox, Table } from "antd";
import css from "./Documents.module.css";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import {
  DocType,
  SchemaType,
  DocumentClientType,
  UpdateDocumentType,
  DocumentClientFieldType,
  UpdateDocumentFieldType,
} from "app/types";
import ClientProfileCardImageViewer
  from "../../../../ClientProfileCard/ClientProfileCardImageViewer/ClientProfileCardImageViewer";
import { getEmployeeDocumentData, updateEmployeeDocumentData } from "../../../../../actions/document.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { AppStateType } from "../../../../../reducers/mainReducer";
import dayjs from "dayjs";
import { PaginationCustom } from "../../../../ui-kit/PaginationCustom/PaginationCustom";
import { ColumnsType } from "antd/es/table";
import ZeroSearch from "../../../ZeroSearch/ZeroSearch";
import LoadingCustom from "../../../../ui-kit/LoadingCustom/LoadingCustom";
import DraftWarning from "../../../../../utils/DraftWarning/DraftWarning";
import { documentLabels } from "./utils/documentLabels";

const documentSchemas: Array<{ field: keyof DocumentClientFieldType; schema: string }> = [
  { field: "snils_document", schema: "snils-schema" },
  { field: "passport_document", schema: "passport-schema" },
  { field: "usn_document", schema: "usn-schema" },
  { field: "bank_document", schema: "bank-schema" },
  { field: "patent", schema: "patent-schema" },
  { field: "founders_decision", schema: "mdm_founders-decision-schema" }
];

interface IDocumentsProps {
  clientInfo: DocumentClientType | null;
  schemaClient: SchemaType | null;
}

enum PaginationDefault {
  defaultPage = 1,
  defaultSize = 10
}

const Documents: FC<IDocumentsProps> = ({
  clientInfo,
}): JSX.Element => {
  const [isImageViewerOpen, setImageViewerOpen] = useState<boolean>(false);
  const [documentUuid, setDocumentUuid] = useState<string>("false");
  const [documentType, setDocumentType] = useState<string>("false");

  const dispatch = useDispatch<AppDispatch>();

  const {
    usnDoc,
    bankDoc,
    snilsDoc,
    decisionDoc,
    passportDoc,
    migrationDoc,
  } = useSelector((state: AppStateType) => state.document);

  useEffect(() => {
    documentSchemas.forEach(({ field, schema }) => {
      const document: DocType[] | undefined = clientInfo?.fields?.[field];

      if (document?.length) {
        dispatch(getEmployeeDocumentData(schema, document[0]?.uuid));
      }
    });
  }, [clientInfo, dispatch]);

  const showImageViewer = (docType: string, uuid: string): void => {
    setDocumentUuid(uuid);
    setDocumentType(docType);
    setImageViewerOpen(true);
  };

  const saveFormData = (docUuid: string, data: UpdateDocumentFieldType | null): void => {
    dispatch(updateEmployeeDocumentData(documentType, docUuid, data));

    setImageViewerOpen(false);
  };

  const closeForm = (): void => {
    setImageViewerOpen(false);
  };

  const [documentsData, setDocumentsData] = useState<UpdateDocumentType[] | null>(null);
  const [documentVariant, setDocumentVariant] = useState<string[]>([]);
  const [documentLoading, setDocumentLoading] = useState<boolean>(false);

  const processDocuments = (
    passportDoc: UpdateDocumentType | null,
    usnDoc: UpdateDocumentType | null,
    snilsDoc: UpdateDocumentType | null,
    migrationDoc: UpdateDocumentType | null,
    bankDoc: UpdateDocumentType | null,
    decisionDoc: UpdateDocumentType | null
  ): UpdateDocumentType[] => {
    const documentsData: UpdateDocumentType[] = [];

    const addDocs = (docs: UpdateDocumentType | null): void => {
      if (docs !== null && docs !== undefined) {
        if (Array.isArray(docs)) {
          documentsData.push(...docs);
        } else {
          documentsData.push(docs);
        }
      }
    };

    [passportDoc, usnDoc, snilsDoc, migrationDoc, bankDoc, decisionDoc].forEach(addDocs);

    setDocumentsData(documentsData);
    return documentsData;
  };

  useEffect(() => {
    if (clientInfo?.fields) {
      processDocuments(passportDoc, usnDoc, snilsDoc, migrationDoc, bankDoc, decisionDoc);
      setDocumentLoading(true);
    }
  }, [passportDoc, usnDoc, snilsDoc, migrationDoc, bankDoc]);

  const tableTitle = (text: string): JSX.Element => {
    return (
      <div className="table-title">
        {text}
      </div>
    );
  };

  const documentLabel = (text: string): JSX.Element | null => {
    const label: string = documentLabels[text];

    return label ? <div className={css.documentLabel}>{label}</div> : null;
  };

  const changeParams = (): void => {
    const defaultData: UpdateDocumentType[] =
      processDocuments(passportDoc, usnDoc, snilsDoc, migrationDoc, bankDoc, decisionDoc);

    if (documentVariant.length) {
      const filterDocuments: UpdateDocumentType[] =
        defaultData.filter((doc: UpdateDocumentType) => documentVariant.includes(doc.schema_name));

      setDocumentsData(filterDocuments);
    }

    setCurrentPage(PaginationDefault.defaultPage);
  };

  const selectDocument = (text: string): void => {
    setDocumentVariant((currentVariant: string[]): string[] => {
      const textExists: boolean = currentVariant.includes(text);

      if (textExists) {
        return currentVariant.filter((item: string): boolean => item !== text);
      } else {
        return [...currentVariant, text];
      }
    });
  };

  const filterDocuments = (confirm: () => void): JSX.Element => {
    return (
      <div className="table-filters-wrap">
        <div className="table-filters">
          <Checkbox
            checked={documentVariant.includes("passport-schema")}
            className="table-checkbox"
            onClick={() => selectDocument("passport-schema")}
          >
            Паспорт
          </Checkbox>
          <Checkbox
            checked={documentVariant.includes("usn-schema")}
            className="table-checkbox"
            onClick={() => selectDocument("usn-schema")}
          >
            Подтверждение о переходе на УСН
          </Checkbox>
          <Checkbox
            checked={documentVariant.includes("snils-schema")}
            className="table-checkbox"
            onClick={() => selectDocument("snils-schema")}
          >
            СНИЛС
          </Checkbox>
          <Checkbox
            checked={documentVariant.includes("patent-schema")}
            className="table-checkbox"
            onClick={() => selectDocument("patent-schema")}
          >
            Патент
          </Checkbox>
          <Checkbox
            checked={documentVariant.includes("bank-schema")}
            className="table-checkbox"
            onClick={() => selectDocument("bank-schema")}
          >
            Банковские реквизиты
          </Checkbox>
        </div>
        <div className="table-btns">
          <ButtonCustom
            type="link"
            text="Сбросить"
            onClick={() => setDocumentVariant([])}
          />
          <ButtonCustom
            type="primary"
            text="Ок"
            onClick={(): void => {
              changeParams();
              confirm();
            }
            }
          />
        </div>
      </div>
    );
  };

  //Логика сортировки таблицы
  const [sortOrder, setSortOrder] = useState<"ascend" | "descend" | null>(null);

  const handleSorterDocuments = (e: React.MouseEvent<HTMLElement>, text: string): void => {
    e.preventDefault();
    const defaultValue: "ascend" | null = sortOrder === "descend" ? null : "ascend";
    const newSortOrder: "ascend" | "descend" | null =
      sortOrder === "ascend" ? "descend" : defaultValue;

    const procDocuments: UpdateDocumentType[] =
      processDocuments(passportDoc, usnDoc, snilsDoc, migrationDoc, bankDoc, decisionDoc);

    const sortedDocuments: UpdateDocumentType[] = [...(documentsData ? documentsData : [])].sort((
      a: UpdateDocumentType,
      b: UpdateDocumentType
    ): number => {
      let result;

      switch (text) {
        case "schema_name":
          result = a.schema_name.localeCompare(b.schema_name);
          break;
        case "created_at":
          result = dayjs(a.created_at).diff(dayjs(b.created_at));
          break;
        default:
          result = 0;
      }

      return newSortOrder === "ascend" ? result : -result;
    });

    if (newSortOrder === null) {
      setDocumentsData(procDocuments);
    } else {
      setDocumentsData(sortedDocuments);
    }

    setSortOrder(newSortOrder);
  };

  const columns: ColumnsType<UpdateDocumentType> = [
    {
      title: tableTitle("Документ"),
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: null,
      dataIndex: ["schema_name"],
      width: "43.6%",
      key: "document",
      align: "center",
      className: css.tableHeader,
      filtered: !!documentVariant.length,
      filterDropdown: ({ confirm }) => filterDocuments(confirm),
      onFilterDropdownOpenChange: (visible: boolean): void => {
        if (!visible) {
          changeParams();
        }
      },
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorterDocuments(e, "schema_name")
      }),
      render: (_: string, record: UpdateDocumentType) => documentLabel(record?.schema_name)
    },
    {
      title: tableTitle("Дата загрузки"),
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: null,
      dataIndex: ["created_at"],
      width: "43.6%",
      key: "created",
      className: css.tableHeader,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorterDocuments(e, "created_at")
      }),
      render: (_: string, record: UpdateDocumentType) => (
        <div className={css.documentCreated}>{dayjs(record?.created_at)?.format("DD.MM.YYYY")}</div>
      )
    },
    {
      title: false,
      dataIndex: ["schema_name"],
      sorter: true,
      showSorterTooltip: false,
      defaultSortOrder: null,
      sortIcon: (): null => null,
      width: "12.8%",
      key: "button",
      className: css.tableHeader,
      render: (_: string, record: UpdateDocumentType) => (
        <div className="flex items-center">
          <ButtonCustom
            className={`${css.button} mr-2`}
            type="primary"
            ghost
            text="Проверить файлы"
            onClick={() => showImageViewer(
              record?.schema_name === "founders-decision-schema" ? "mdm_founders-decision-schema" : record?.schema_name,
              record?.fields?.uuid)
            }
          />
        </div>
      ),
    },
  ];

  // логика пагинации страницы
  const [currentPage, setCurrentPage] = useState<number>(PaginationDefault.defaultPage);
  const [pageSize, setPageSize] = useState<number>(PaginationDefault.defaultSize);

  const startIndex: number = (currentPage - 1) * pageSize;
  const endIndex: number = startIndex + pageSize;

  const filterDocumentsData: UpdateDocumentType[] = documentsData
    ? documentsData?.slice(startIndex, endIndex)
    : [];

  const handlePageChange = (newPage: number): void => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number): void => {
    setPageSize(newPageSize);
    setCurrentPage(PaginationDefault.defaultPage);
  };

  const emptyDescription = (): JSX.Element => {
    return <ZeroSearch/>;
  };

  return (documentLoading ? (
      <div>
        <DraftWarning />
        <div className={`${css.container} p-5 mb-3 bg-white border-bottom-gray`}>
          <h1 className="header-text mb-[20px]">
            Документы клиента
          </h1>
          <div>
            <Table
              className={css.table}
              columns={columns}
              dataSource={filterDocumentsData}
              pagination={false}
              locale={{emptyText: emptyDescription()}}
            />
            <PaginationCustom
              className={css.paginationShowTotal}
              total={documentsData?.length ? documentsData.length : 0}
              showTotal={(total: number, range: number[]): string =>
                `${range[0]}-${range[1]} из ${total}`
              }
              currentPage={currentPage}
              defaultPageSize={PaginationDefault.defaultSize}
              pageSize={pageSize}
              defaultCurrent={PaginationDefault.defaultPage}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
            />
          </div>
        </div>
        {isImageViewerOpen && (
          <ClientProfileCardImageViewer
            closeForm={closeForm}
            saveFormData={saveFormData}
            documentType={documentType}
            documentUuid={documentUuid}
            isClientEmployee={true}
            isImageViewerOpen={isImageViewerOpen}
          />
        )}
      </div>
    ) : (
      <div className="text-center mt-96">
        <LoadingCustom/>
      </div>
    )
  );
};

export default Documents;
