import React, {
  Dispatch,
  JSX,
  FC,
  SetStateAction,
  useEffect,
  useState
} from "react";
import css from "../ClientCardActionDrawer.module.css";
import { Modal } from "antd";
import { TypicalRequestToClientType } from "app/types";
import { sendPaidConsultationsRequestToClient } from "../../../../../actions/client.actions";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { AppStateType } from "../../../../../reducers/mainReducer";

interface IPaidConsultationProps {
  drawerClientAction: string;
  setDrawerClientAction: Dispatch<SetStateAction<string>>;
}

const PaidConsultation: FC<IPaidConsultationProps> = ({
  drawerClientAction,
  setDrawerClientAction
}): JSX.Element => {
  const [isModalPaidOpen, setModalPaidOpen] = useState<boolean>(false);
  
  const dispatch = useDispatch<AppDispatch>();

  const { documentClient } = useSelector((state: AppStateType) => state.client);

  useEffect(() => {
    if (drawerClientAction === "Списание платной консультации") {
      setModalPaidOpen(true);
    }
  }, [drawerClientAction]);

  const modalCancel = (): void => {
    setModalPaidOpen(false);
    setDrawerClientAction("");
  };

  const modalOk = (): void => {
    const clientData: string | undefined = documentClient?.fields?.uuid;
    const paidConsultationRequest: TypicalRequestToClientType = {
      client: clientData,
      dynamic_tariff_key: "number_of_consultations",
      category_code: 403,
      files: []
    };

    dispatch(sendPaidConsultationsRequestToClient(paidConsultationRequest));
    setModalPaidOpen(false);
  };

  const modalHeader = (text: string): JSX.Element => (
    <div className={css.modalHeader}>
      {text}
    </div>
  );

  const modalFooter: JSX.Element = (
    <div className="mt-5">
      <ButtonCustom
        className={`${css.buttonBack} mr-2`}
        key="cancel"
        size="large"
        type="default"
        text="Отменить"
        onClick={modalCancel}
      />
      <ButtonCustom
        key="ok"
        size="large"
        text="Подтвердить"
        type="primary"
        className={css.buttonOk}
        onClick={modalOk}
      />
    </div>
  );

  return (
    <Modal
      width={580}
      title={modalHeader("Списание платной консультации")}
      open={isModalPaidOpen}
      footer={modalFooter}
      closable={false}
      centered
    >
      <div className={css.modalText}>
        Пожалуйста, подтвердите списание платной консультации с клиента
      </div>
    </Modal>
  );
};

export default PaidConsultation;